import React from "react";
import styled from "styled-components";
import Spinner from "./Spinner";

interface Props {
  size?: "slim" | "normal" | "large";
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

function Button({
  children,
  size = "normal",
  loading = false,
  ...otherProps
}: React.PropsWithChildren<Props>) {
  return (
    <StyledButton size={size} isLoading={loading} {...otherProps}>
      {loading ? <Spinner small /> : children}
    </StyledButton>
  );
}

const StyledButton = styled.button<{
  size: "slim" | "normal" | "large";
  isLoading: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isLoading }) =>
    isLoading ? "#806724" : theme.colors.main};
  color: white;
  outline: inherit;
  height: ${(props: Props) =>
    ({
      slim: "40px",
      normal: "50px",
      large: "50px"
    }[props.size || "normal"])};
  font-size: 14px;
  letter-spacing: 0.16em;
  font-weight: 600;
  width: 100%;
  max-width: ${(props: Props) =>
    ({
      slim: "220px",
      normal: "220px",
      large: "100%"
    }[props.size || "normal"])};
  border: none;
  text-transform: uppercase;
  font-family: soleil, sans-serif;
  transition: 0.3s all;
  &:hover {
    cursor: pointer;
    background-color: #806724;
  }
  &[disabled] {
    background-color: #a3acaf;
    opacity: 0.65;
  }
`;

export default styled(Button)``;
