import ArrowRight from "components/icons/ArrowRight";
import Link from "components/Link";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { MouseEvent } from "react";
import styled from "styled-components";
import { computePath } from "utils/common/navigation";

interface Props {
  locale: string;
  defaultLocale: string;
  link: any;
  onClick?: (link: any, e: MouseEvent) => void;
}

const SideMenuLink: React.SFC<Props> = ({
  locale,
  defaultLocale,
  link,
  onClick = (_link: any, _e: MouseEvent) => {},
}) => {
  const path = computePath(
    locale,
    defaultLocale,
    link.linkedResource && link.linkedResource.__typename,
    link.linkedResource && link.linkedResource._allHandleLocales,
    link._allUrlLocales
  );

  const featuredLabel = link._allFeaturedLabelLocales.find(
    (ul: { locale: string; value?: string }) => ul.locale === locale
  );
  const labelLocale = link._allLabelLocales.find(
    (l: any) => l.locale === locale
  );
  const label = labelLocale && labelLocale.value;

  if (!label) {
    return null;
  }

  return (
    <NavLink
      to={path}
      onClick={(e: MouseEvent) => onClick(link, e)}
      featured={link.featured}
      isRoot={!link.treeParent}
    >
      {!!link.image && link.featured && (
        <LinkImgContainer>
          <LinkImg
            image={link.image.gatsbyImageData}
            alt={link.image.alt || ""}
          />
        </LinkImgContainer>
      )}
      <LinkLabel isRoot={!link.treeParent}>
        {label}
        {link.featured && featuredLabel.value && (
          <Featured featured={link.featured} isRoot={!link.treeParent}>
            {featuredLabel && featuredLabel.value}
          </Featured>
        )}
      </LinkLabel>
      {!!link.treeChildren && !!link.treeChildren.length && (
        <div>
          <ArrowRight />
        </div>
      )}
    </NavLink>
  );
};

const NavLink = styled(Link)<{ featured: boolean; isRoot: boolean }>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  font-size: 14px;
  flex: 1;
  align-items: center;
  position: relative;
  border-radius: 2px;
  position: relative;
`;

const LinkImg = styled(GatsbyImage)`
  height: 100%;
`;

const LinkImgContainer = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 10px;
`;

const LinkLabel = styled.div<{ isRoot: boolean }>`
  margin-right: auto;
  position: ${({ isRoot }) => (isRoot ? "relative" : "auto")};
  display: flex;
  align-items: flex-start;
`;

const Featured = styled.div<{ featured: boolean; isRoot: boolean }>`
  display: ${({ featured }) => (featured ? "block" : "none")};
  position: relative;
  margin-top: -2px;
  font-size: 10px;
  background-color: ${({ theme }) => theme.colors.main};
  color: #fff;
  border-radius: 100px;
  padding: 0px 4px 1px 4px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.06em;
  white-space: nowrap;
`;

export default SideMenuLink;
