import React, { useContext } from "react";
import {
  addLocaleData,
  InjectedIntl,
  injectIntl,
  IntlProvider
} from "react-intl";
import { useLocale } from "hooks/useLocale";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import it from "react-intl/locale-data/it";
import enMessages from "translations/locales/en.json";
import esMessages from "translations/locales/es.json";
import itMessages from "translations/locales/it.json";

addLocaleData([...it, ...en, ...es]);

const translations: { [locale: string]: any } = {
  it: itMessages,
  en: enMessages,
  es: esMessages
};

const TranslationContext = React.createContext<InjectedIntl | null>(null);

interface TranslationProviderProps {}

export function TranslationProvider({
  children
}: React.PropsWithChildren<TranslationProviderProps>) {
  const { locale } = useLocale();
  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <InjectIntlContext>{children}</InjectIntlContext>
    </IntlProvider>
  );
}

const InjectIntlContext = injectIntl(({ intl, children }) => {
  return (
    <TranslationContext.Provider value={intl}>
      {children}
    </TranslationContext.Provider>
  );
});

export function useTranslation() {
  const value = useContext(TranslationContext);
  if (!value) {
    throw new Error(`useTranslation must be used inside a TranslationProvider`);
  }
  return value;
}
