import React from "react";

interface Props {
  color?: string;
  width?: string;
  height?: string;
  onClick: () => void;
}

const Menu = (props: Props) => {
  const { color, width, height, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "28"}
      height={height || "24"}
      viewBox="0 0 28 24"
      fill="none"
      stroke={color || "currentColor"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <line x1="2" y1="5.27" x2="10.68" y2="5.27" />
      <line x1="2" y1="12.22" x2="10.68" y2="12.22" />
      <line x1="2" y1="19.16" x2="19.36" y2="19.16" />
      <circle cx="19.02" cy="9.28" r="4.44" />
      <line x1="26" y1="16.26" x2="22.2" y2="12.45" />
    </svg>
  );
};

export default Menu;
