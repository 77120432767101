import styled from "styled-components";

interface Props {
  size?: "tiny" | "small" | "normal";
}

const Wrapper = styled.div`
  width: ${(props: Props) =>
    ({
      tiny: "480px",
      small: "960px",
      normal: "1200px"
    }[props.size || "normal"])};
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 1240px) {
    width: ${(props: Props) =>
      ({
        tiny: "480px",
        small: "960px",
        normal: "100%"
      }[props.size || "normal"])};
    padding: ${(props: Props) =>
      ({
        tiny: "0px",
        small: "0px",
        normal: "0 20px"
      }[props.size || "normal"])};
  }
  @media (max-width: 1000px) {
    width: ${(props: Props) =>
      ({
        tiny: "480px",
        small: "100%",
        normal: "100%"
      }[props.size || "normal"])};
    padding: ${(props: Props) =>
      ({
        tiny: "0",
        small: "0 20px",
        normal: "0 20px"
      }[props.size || "normal"])};
  }
  @media (max-width: 520px) {
    width: ${(props: Props) =>
      ({
        tiny: "100%",
        small: "100%",
        normal: "100%"
      }[props.size || "normal"])};
    padding: ${(props: Props) =>
      ({
        tiny: "0 20px",
        small: "0 20px",
        normal: "0 20px"
      }[props.size || "normal"])};
  }
`;

export default Wrapper;
