export interface FieldLocale<T = any> {
  locale: string;
  value: T;
}

export const getLocalizedField = <T extends {}>(
  fieldLocales: FieldLocale<T>[],
  locale: string
) => {
  return fieldLocales.find(fieldLocale => fieldLocale.locale === locale);
};

export const getLocalizedValue = <T>(
  allValueLocales: FieldLocale<T>[],
  locale: string
) => {
  const valueLocale = allValueLocales.find(
    fieldLocale => fieldLocale.locale === locale
  );
  return valueLocale ? valueLocale.value : null;
};
