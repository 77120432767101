import ArrowLeft from "components/icons/ArrowLeft";
import ArrowRight from "components/icons/ArrowRight";
import Cross from "components/icons/Cross";
import Search from "components/icons/Search";
import Logo from "components/Logo";
import SideBarPanel from "components/SideBarPanel";
import Spinner from "components/Spinner";
import Fuse from "fuse.js";
import { Link } from "gatsby";
import { useCart } from "hooks/useCart";
import useDebounce from "hooks/useDebounce";
import { useLocale } from "hooks/useLocale";
import { useTranslation } from "hooks/useTranslation";
import { useUser } from "hooks/useUser";
import React, { MouseEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { FieldLocale } from "utils/common/locale";
import { computePathFromLocalizedData } from "utils/common/navigation";
import { getFirstMediaImage } from "utils/image";
import SideMenuLink from "./SideMenuLink";

interface Props {
  allPathLocales: FieldLocale<string>[];
  navigation: any[];
  isOpen: boolean;
  handleClose: () => void;
}

const SideMenu: React.SFC<Props> = ({
  allPathLocales,
  navigation,
  isOpen,
  handleClose,
}) => {
  const { locale, defaultLocale, setLocale } = useLocale();
  const { formatMessage } = useTranslation();

  // State variables
  const [selectedLink, setSelectedLink] = useState(undefined);
  const [showRoot, setShowRoot] = useState(true);
  const [searchFocused, setSearchFocused] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState();
  const [fuse, setFuse] = useState();
  const [elementsToLoad, setElementsToLoad] = useState(10);
  const debouncedSearchString = useDebounce(searchString, 300);

  // Context
  const { cart } = useCart();
  const { user } = useUser();

  useEffect(() => {
    if (searchFocused && !fuse) {
      setIsSearching(true);
      fetch(`/search-${locale}.json`)
        .then((response) => response.json())
        .then((json) => {
          setProducts(json);
          const options = {
            shouldSort: true,
            tokenize: true,
            threshold: 0.4,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 2,
            keys: ["dato.title", "dato.description"],
          };
          setFuse(new Fuse(json, options));
          setIsSearching(false);
        });
    }
  }, [searchFocused]);

  useEffect(() => {
    setElementsToLoad(10);
    if (debouncedSearchString && debouncedSearchString.length === 0) {
      setSearchResults(undefined);
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    if (
      debouncedSearchString &&
      debouncedSearchString.length > 0 &&
      products &&
      fuse
    ) {
      setIsSearching(true);
      const searchResults = fuse.search(debouncedSearchString);
      setSearchResults(searchResults);
      setIsSearching(false);
      if (searchResults.length > 0) {
        (window as any).dataLayer.push({
          event: "gaEvent",
          eventCategory: "Search",
          eventAction: "WithResults",
          label: debouncedSearchString,
        });
      } else {
        (window as any).dataLayer.push({
          event: "gaEvent",
          eventCategory: "Search",
          eventAction: "ZeroResults",
          label: debouncedSearchString,
        });
      }
    }
  }, [debouncedSearchString, products, fuse, elementsToLoad]);

  /**
   * Root link elements
   */
  const rootLinks = navigation.filter((link: any) => !link.treeParent);

  const allPathLocalesFormatted = allPathLocales.map((pathLocale) => ({
    id: pathLocale.locale,
    treeParent: { id: "1" },
    label: pathLocale.locale,
  }));

  const languageRootLink = {
    id: "1",
    label: <FormattedMessage id="sidebar.changeLanguage" />,
    treeParent: {},
    treeChildren: allPathLocalesFormatted,
  };

  const allLinks = [...navigation, ...allPathLocalesFormatted];

  const handleClickRootLink = (link: any, e: MouseEvent) => {
    if (link.treeChildren.length) {
      e.preventDefault();
      setShowRoot(false);
      setSelectedLink(link);
    } else {
      handleClose();
      setSelectedLink(undefined);
      setShowRoot(true);
    }
  };

  const computeLanguageSelectorLink = (linkLocale: string) => {
    const pathLocale = allPathLocales.find(
      (path) => path.locale === linkLocale
    );
    const suffix = location.pathname.includes("account")
      ? location.pathname.replace(
          `${allPathLocales.find((path) => path.locale === locale)!.value}`,
          ""
        )
      : "";
    return `${pathLocale!.value}${suffix}`;
  };

  return (
    <SideBarPanel handleClose={handleClose} position="left" isOpen={isOpen}>
      <Container>
        <Content>
          <Header>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            <SearchBarContainer>
              <SearchBox
                value={searchString}
                placeholder={formatMessage({ id: "search.placeholder" })}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
                onChange={(e) => setSearchString(e.target.value)}
              />
              <SearchIcon />
              {searchString && (
                <CrossIcon onClick={() => setSearchString("")} />
              )}
            </SearchBarContainer>
          </Header>
          {searchString && (
            <SearchResultsContainer>
              {isSearching ||
              (!searchResults && debouncedSearchString !== undefined) ? (
                <Spinner />
              ) : (
                <>
                  {searchResults && searchResults.length ? (
                    <SearchResults>
                      {searchResults
                        .slice(0, elementsToLoad)
                        .map((res: any, index: number) => (
                          <SearchResult
                            to={computePathFromLocalizedData(
                              locale,
                              defaultLocale,
                              undefined,
                              res.dato.handle,
                              res.dato.category.handle
                            )}
                            onClick={handleClose}
                            key={index}
                          >
                            <SearchResultImage
                              src={
                                getFirstMediaImage(res.shopify.media)
                                  ?.originalSrc
                              }
                            />
                            <SearchResultTitle>
                              {res.dato.title}
                            </SearchResultTitle>
                          </SearchResult>
                        ))}
                    </SearchResults>
                  ) : (
                    searchResults &&
                    searchResults.length === 0 && (
                      <NoResults>
                        {formatMessage({ id: "search.noResults" })}
                      </NoResults>
                    )
                  )}
                </>
              )}
              {searchResults &&
                elementsToLoad &&
                elementsToLoad < searchResults.length && (
                  <LoadMore
                    onClick={() => setElementsToLoad(elementsToLoad + 10)}
                  >
                    {formatMessage({ id: "search.loadMore" })}
                  </LoadMore>
                )}
            </SearchResultsContainer>
          )}
          {!searchString && (
            <LinksContainer>
              <CSSTransition
                in={showRoot}
                timeout={300}
                classNames="slide-root"
              >
                <div>
                  {showRoot && (
                    <>
                      {rootLinks.map((link) => (
                        <SideNavLinkContainer key={link.id}>
                          <SideMenuLink
                            link={link}
                            locale={locale}
                            onClick={handleClickRootLink}
                            defaultLocale={defaultLocale}
                          />
                        </SideNavLinkContainer>
                      ))}

                      <SideNavLinkContainer style={{ marginTop: "30px" }}>
                        {user ? (
                          <NavLink to="/account/" onClick={handleClose}>
                            <LinkLabel>Account</LinkLabel>
                          </NavLink>
                        ) : (
                          <NavLink to="/login/" onClick={handleClose}>
                            <LinkLabel>Login</LinkLabel>
                          </NavLink>
                        )}
                      </SideNavLinkContainer>
                      <SideNavLinkContainer>
                        <NavLink to="/cart/" onClick={handleClose}>
                          <LinkLabel>
                            Cart ({cart ? cart.lineItems.length : 0})
                          </LinkLabel>
                        </NavLink>
                      </SideNavLinkContainer>
                      <SideNavLinkContainer>
                        <NavLink
                          to="/"
                          onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            setSelectedLink(languageRootLink);
                            setShowRoot(false);
                          }}
                        >
                          <LinkLabel>
                            <FormattedMessage id="sidebar.changeLanguage" />
                            :&nbsp;{locale}
                          </LinkLabel>
                          <span>
                            <ArrowRight />
                          </span>
                        </NavLink>
                      </SideNavLinkContainer>
                    </>
                  )}
                </div>
              </CSSTransition>
              <CSSTransition
                in={!showRoot}
                timeout={300}
                classNames="slide"
                onExited={() => setSelectedLink(undefined)}
              >
                <div>
                  {!!selectedLink && (
                    <>
                      <Header>
                        <GoBack>
                          <ArrowLeft onClick={() => setShowRoot(true)} />
                          <GoBackLabel>
                            {(selectedLink._allLabelLocales &&
                              selectedLink._allLabelLocales.find(
                                (labelLocale: FieldLocale<string>) =>
                                  labelLocale.locale === locale
                              ).value) ||
                              selectedLink.label}
                          </GoBackLabel>
                        </GoBack>
                      </Header>
                      {allLinks
                        .filter(
                          (l) =>
                            l.treeParent &&
                            l.featured &&
                            l.treeParent.id === selectedLink.id
                        )
                        .map((link) =>
                          !link.treeParent || !(link.treeParent.id === "1") ? (
                            <SideNavLinkContainer key={link.id}>
                              <SideMenuLink
                                link={link}
                                locale={locale}
                                defaultLocale={defaultLocale}
                                onClick={() => {
                                  setSelectedLink(undefined);
                                  setShowRoot(true);
                                  handleClose();
                                }}
                              />
                            </SideNavLinkContainer>
                          ) : (
                            <SideNavLinkContainer key={link.id}>
                              <NavLink
                                to={computeLanguageSelectorLink(link.id)}
                                onClick={() => {
                                  setLocale(link.id);
                                  setShowRoot(true);
                                  setSelectedLink(undefined);
                                  handleClose();
                                }}
                              >
                                {link.label}
                              </NavLink>
                            </SideNavLinkContainer>
                          )
                        )}
                      {allLinks
                        .filter(
                          (l) =>
                            l.treeParent &&
                            !l.featured &&
                            l.treeParent.id === selectedLink.id
                        )
                        .map((link) =>
                          !link.treeParent || !(link.treeParent.id === "1") ? (
                            <SideNavLinkContainer key={link.id}>
                              <SideMenuLink
                                link={link}
                                locale={locale}
                                defaultLocale={defaultLocale}
                                onClick={() => {
                                  setSelectedLink(undefined);
                                  setShowRoot(true);
                                  handleClose();
                                }}
                              />
                            </SideNavLinkContainer>
                          ) : (
                            <SideNavLinkContainer key={link.id}>
                              <NavLink
                                to={computeLanguageSelectorLink(link.id)}
                                onClick={() => {
                                  setLocale(link.id);
                                  setShowRoot(true);
                                  setSelectedLink(undefined);
                                  handleClose();
                                }}
                              >
                                {link.label}
                              </NavLink>
                            </SideNavLinkContainer>
                          )
                        )}
                    </>
                  )}
                </div>
              </CSSTransition>
            </LinksContainer>
          )}
        </Content>
      </Container>
    </SideBarPanel>
  );
};

const Container = styled.div`
  padding: 20px 20px;
  width: 100%;
`;

const Header = styled.div`
  padding: 10px 0 20px 0;
`;

const LogoContainer = styled.div`
  width: 100%;
  padding-bottom: 20px;
  ${Logo} {
    margin: 0 auto;
    width: 140px;
  }
`;
const LinksContainer = styled.ul`
  margin: 0;
  padding: 0;
  padding-bottom: 100px;
  .slide-enter {
    opacity: 0;
    transform: translateX(-100%);
  }
  .slide-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .slide-exit {
    opacity: 1;
  }
  .slide-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }

  .slide-root-enter {
    position: absolute;
    width: calc(100% - 60px);
    opacity: 0;
    transform: translateX(100%);
  }
  .slide-root-enter-active {
    opacity: 1;
    position: absolute;
    width: calc(100% - 60px);
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .slide-root-exit {
    opacity: 1;
  }
  .slide-root-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 300ms, transform 300ms;
  }
`;

const SideNavLinkContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  box-sizing: content-box;
  /* height: 24px; */
  letter-spacing: 0.04em;
  &:hover {
    cursor: pointer;
  }
  svg {
    display: block;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const GoBack = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 24px;
  letter-spacing: 0.1em;
  svg {
    display: block;
    stroke: #333;
  }
`;

const GoBackLabel = styled.div`
  font-size: 14px;
  height: 24px;
  letter-spacing: 0.04em;
  color: #333;
  padding-top: 1px;
  padding-left: 10px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  font-size: 14px;
  flex: 1;
  align-items: center;
`;

const LinkLabel = styled.div`
  margin-right: auto;
`;

const SearchIcon = styled(Search)`
  position: absolute;
  top: 8px;
  left: 14px;
  color: ${({ theme }) => theme.colors.main};
`;

const CrossIcon = styled(Cross)`
  position: absolute;
  top: 8px;
  right: 14px;
  color: #7b7b7b;
`;

const SearchBarContainer = styled.div`
  position: relative;
`;

const SearchBox = styled.input`
  cursor: text;
  width: 100%;
  border: none;
  display: inline-block;
  border-radius: 40px;
  font-size: 0.9rem;
  line-height: 40px;
  padding: 0 46px 0 46px;
  outline: none;
  transition: all 0.2s ease;
  background: #eee;
  display: flex;
  align-items: center;
  height: 40px;
  transition: border-color 0.3s ease;
  border: 1px solid transparent;
  font-family: soleil, sans-serif;
  letter-spacing: 0.04em;
  font-size: 14px;
  &:focus {
    transition: border-color 0.3s ease;
    border: 1px solid ${({ theme }) => theme.colors.main};
  }
`;

const SearchResultsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0 0 0;
  margin-bottom: -20px;
`;

const NoResults = styled.div`
  color: ${({ theme }) => theme.colors.main};
  padding: 30px 0;
  width: 100;
  text-align: center;
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchResult = styled(Link)`
  display: flex;
  padding: 6px 0;
  align-items: center;
  text-decoration: none;
`;

const SearchResultImage = styled.img`
  width: 80px;
  height: 80px;
  flex: 0 0 80px;
  display: block;
  position: relative;
  border: 5px solid ${({ theme }) => theme.colors.background2};
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    border: 5px solid ${({ theme }) => theme.colors.main};
  }
`;
const SearchResultTitle = styled.div`
  letter-spacing: 0.08em;
  color: #706f6f;
  font-size: 13px;
  padding-left: 10px;
`;

const LoadMore = styled.div`
  color: ${({ theme }) => theme.colors.main};
  padding: 20px 0 50px 0;
  width: 100;
  text-align: center;
  text-decoration: underline;
  font-size: 13px;
`;

export default SideMenu;
