const moneyFormat = "${{amount}}";

export default function formatMoney(cents: number, format: string) {
  if (typeof cents === "string") {
    console.warn("Passed value is a string, not a number");
    cents = cents.replace(".", "");
  }
  let value = "";
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  const formatString = format || moneyFormat;

  function formatWithDelimiters(
    number: number,
    precision = 2,
    thousands = ",",
    decimal = "."
  ): string {
    if (isNaN(number) || number == null) {
      return "0";
    }

    const numberString = (number / 100.0).toFixed(precision);

    const parts = numberString.split(".");
    const dollarsAmount = parts[0].replace(
      /(\d)(?=(\d\d\d)+(?!\d))/g,
      `$1${thousands}`
    );
    const centsAmount = parts[1] ? decimal + parts[1] : "";

    return dollarsAmount + centsAmount;
  }

  const match = formatString.match(placeholderRegex);
  if (!match || !match[1]) {
    console.warn("Invalid money format");
  } else {
    switch (match[1]) {
      case "amount":
        value = formatWithDelimiters(cents, 2);
        break;
      case "amount_no_decimals":
        value = formatWithDelimiters(cents, 0);
        break;
      case "amount_with_comma_separator":
        value = formatWithDelimiters(cents, 2, ".", ",");
        break;
      case "amount_no_decimals_with_comma_separator":
        value = formatWithDelimiters(cents, 0, ".", ",");
        break;
    }
  }

  return formatString.replace(placeholderRegex, value);
}
