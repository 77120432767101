import React from "react";
import styled from "styled-components";

interface Props {
  source?: string;
  alt?: string;
  className?: string;
}

export default function Thumbnail({ className, source, alt }: Props) {
  return (
    <Container className={className}>
      <Image src={source} alt={alt} />
    </Container>
  );
}

const Container = styled.div`
  width: 120px;
  @media (max-width: 500px) {
    width: 80px;
  }
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  display: block;
  border-radius: 4px;
`;
