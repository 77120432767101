import { DefaultTheme } from "styled-components";
import colors from "./colors";
import dimensions from "./dimensions";
import breakpoints from "./breakpoints";

export { default as GlobalStyle } from "./global";
const theme: DefaultTheme = {
  colors,
  dimensions,
  breakpoints,
  boxShadow: "0 6px 15px 0 rgba(36, 37, 38, 0.08);"
};
export default theme;
