import React from "react";
import styled from "styled-components";
import Cart from "components/icons/Cart";
import { useCart } from "hooks/useCart";

const CartIcon = () => {
  const { cart } = useCart();

  return (
    <CartIconContainer>
      <Icon />
      {cart && cart.lineItems.length > 0 && (
        <ItemsCountBadge>
          {cart.lineItems.reduce((acc, item) => acc + item.quantity, 0)}
        </ItemsCountBadge>
      )}
    </CartIconContainer>
  );
};

const Icon = styled(Cart)``;
const CartIconContainer = styled.div`
  position: relative;
  svg {
    display: block;

    stroke: ${({ theme }) => theme.colors.main};
  }
`;

const ItemsCountBadge = styled.div`
  position: absolute;
  top: 8px;
  right: 4px;
  height: 14px;
  width: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${({ theme }) => theme.colors.main};
  color: ${({ theme }) => theme.colors.textLight};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  box-sizing: border-box;
  padding-bottom: 1px;
  padding-left: 1px;
`;

export default CartIcon;
