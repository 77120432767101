import styled, { css, keyframes } from "styled-components";

const rotate = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
`;

interface Props {
  small?: boolean;
}

const Spinner = styled.div<Props>`
  margin: 0.2rem;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
  border-left: 2px solid transparent;
  transform: translate3d(0, 0, 0);
  animation: ${rotate} 1s infinite linear;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  &:after {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
  }
  ${({ small }) =>
    small &&
    css`
      width: 1.5rem;
      height: 1.5rem;
    `};
`;

export default Spinner;
