import { partition, groupBy, differenceBy, sortBy } from "lodash";
import { LineItem } from "hooks/useCart";

export interface DefaultLineItem {
  type: "default";
  item: LineItem;
}

export interface ComposableLineItem {
  type: "composable";
  base: LineItem;
  instants: LineItem[];
}

export type CartLineItem = DefaultLineItem | ComposableLineItem;

export function mergeBundleLineItems(
  items: Readonly<LineItem[]>
): CartLineItem[] {
  const cartLineItems: CartLineItem[] = [];
  let remainingItems = items.slice();
  const itemsByBundleId = groupBy(remainingItems, getBundleId);

  while (remainingItems.length > 0) {
    const bundleId = getBundleId(remainingItems[0]);
    if (bundleId) {
      const bundleItems = itemsByBundleId[bundleId];
      const [base, instants] = partition(bundleItems, isBundleBase);
      cartLineItems.push({
        type: "composable",
        base: base[0],
        instants: sortBy(instants, getInstantPosition),
      });
      remainingItems = differenceBy(remainingItems, bundleItems, "id");
    } else {
      cartLineItems.push({
        type: "default",
        item: remainingItems.shift()!,
      });
    }
  }

  return cartLineItems;
}

export function getBundleId(item: LineItem): string | undefined {
  const bundleAttribute = item.customAttributes.find(
    (attribute) => attribute.key === "_bundleId"
  );
  return bundleAttribute ? bundleAttribute.value : undefined;
}

function isBundleBase(item: LineItem): boolean {
  const position = getInstantPosition(item);
  return position === undefined;
}

function getInstantPosition(item: LineItem): number | undefined {
  const bundleAttribute = item.customAttributes.find(
    (attribute) => attribute.key === "_posizione"
  );
  return bundleAttribute ? parseInt(bundleAttribute.value) : undefined;
}

export function getPrice(item: ComposableLineItem): number {
  return item.instants
    .concat(item.base)
    .reduce(
      (price, item) => price + parseFloat(item.variant!.price) * item.quantity,
      0
    );
}

export function getCompareAtPrice(item: ComposableLineItem): number {
  return item.instants
    .concat(item.base)
    .reduce(
      (price, item) =>
        price +
        (item.variant!.compareAtPrice
          ? parseFloat(item.variant!.compareAtPrice) * item.quantity
          : parseFloat(item.variant!.price) * item.quantity),
      0
    );
}

export function getDiscount(item: ComposableLineItem): number {
  return item.instants
    .concat(item.base)
    .reduce(
      (discount, item) =>
        discount +
        (item.discountAllocations && item.discountAllocations.length > 0
          ? item.discountAllocations.reduce(
              (acc, d) => acc + Number(d.allocatedAmount.amount),
              0
            )
          : 0),
      0
    );
}
