import { DatoCmsProduct } from "graphql-typings";
import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Cross from "./icons/Cross";
import ScalapayLogo from "components/icons/Scalapay";
import { FormattedMessage } from "react-intl";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ScalapayModal = ({ isOpen, onClose }: Props) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  return isOpen ? (
    <ModalMask onClick={handleClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <Content>
          <CloseIcon onClick={handleClose} />
          <ScalapayLogoContainer>
            <ScalapayLogo />
          </ScalapayLogoContainer>
          <Title>
            <FormattedMessage id="scalapay.modal.title"></FormattedMessage>
          </Title>
          <ScalapayPinkLogoContainer>
            <ScalapayLogo />
          </ScalapayPinkLogoContainer>
          <Subtitle>
            <FormattedMessage id="scalapay.modal.subtitle1"></FormattedMessage>
          </Subtitle>
          <Text>
            <FormattedMessage id="scalapay.modal.text1"></FormattedMessage>
          </Text>
          <Subtitle>
            <FormattedMessage id="scalapay.modal.subtitle2"></FormattedMessage>
          </Subtitle>
          <Text>
            <FormattedMessage id="scalapay.modal.text2"></FormattedMessage>
          </Text>
          <Subtitle>
            <FormattedMessage id="scalapay.modal.subtitle3"></FormattedMessage>
          </Subtitle>{" "}
          <Text>
            <FormattedMessage id="scalapay.modal.text3"></FormattedMessage>
          </Text>
          <Note>
            <FormattedMessage
              id="scalapay.modal.note"
              values={{
                link: (
                  <a href="https://scalapay.com" target="new">
                    www.scalapay.com
                  </a>
                ),
              }}
            />
          </Note>
        </Content>
      </ModalContainer>
    </ModalMask>
  ) : null;
};

const arriveModal = keyframes`
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
`;

const ModalContainer = styled.div`
  position: absolute;
  z-index: 1001;
  max-width: 460px;
  padding: 20px;
  color: $baseTextColor;
  box-sizing: border-box;
  transition: all 0.3s ease;
  animation: ${arriveModal} 400ms ease-in-out 0s forwards;
  top: 5%;
`;

const ModalMask = styled.div`
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  overflow: scroll;
`;

const CloseIcon = styled(Cross)`
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    cursor: pointer;
    color: #bdbdbd;
  }
`;

const Content = styled.div`
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px 40px;
  background-color: #fff;
  @media (max-width: 750px) {
    padding: 30px 30px;
  }
`;

const ScalapayLogoContainer = styled.div`
  height: 30px;
  width: 150px;
  display: flex;
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const ScalapayPinkLogoContainer = styled.div`
  height: 24px;
  width: 120px;
  display: flex;
  background-color: #f3cae3;
  border-radius: 100px;
  padding: 8px 8px;
  border-bottom-right-radius: 0px;
  box-sizing: content-box;
  margin-bottom: 20px;
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
  padding: 20px 0 30px 0; ;
`;

const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 4px;
`;

const Text = styled.div`
  font-size: 13px;
  padding-bottom: 30px;
`;

const Note = styled.div`
  font-size: 10px;
  padding-bottom: 12px;
  text-align: center;
  a {
    color: #000;
    font-weight: 700;
  }
`;

export default ScalapayModal;
