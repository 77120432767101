import React from "react";
import PropTypes from "prop-types";

const CircleArrowRight = props => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <circle cx="10" cy="10" r="9" />
      <path d="M13,15l3-3L13,9" transform="translate(-2 -2)" />
      <line x1="6" y1="10" x2="14" y2="10" />
    </svg>
  );
};

CircleArrowRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CircleArrowRight.defaultProps = {
  color: "currentColor",
  size: "20"
};

export default CircleArrowRight;
