import Button from "components/Button";
import ButtonLink from "components/ButtonLink";
import Close from "components/icons/Cross";
import InputCheckbox from "components/InputCheckbox";
import InputTextArea from "components/InputTextArea";
import Link from "components/Link";
import ScalapayModal from "components/ScalapayModal";
import Select from "components/Select";
import SideBarPanel from "components/SideBarPanel";
import { graphql, useStaticQuery } from "gatsby";
import { useCart } from "hooks/useCart";
import { FieldLocale, useLocale } from "hooks/useLocale";
import useProductOptions from "hooks/useProductOptions";
import { useTranslation } from "hooks/useTranslation";
import { MailingAddressInput } from "hooks/useUser";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled, { css } from "styled-components";
import formatMoney from "utils/common/formatMoney";
import { routes } from "utils/routes";
import CartIcon from "./CartIcon";
import LineItems from "./LineItems";
import SelectProductToShip from "./SelectProductToShip";
import SplitShippingAddress from "./SplitShippingAddress";
import SplitShippingEmail from "./SplitShippingEmail";
import SplitShippingTextArea from "./SplitShippingTextArea";

import ScalapayLogo from "components/icons/Scalapay";
import { convertStorefrontIdToShopifyId } from "utils/product";

interface Props {}

interface StaticQuery {
  datoCmsGeneral: {
    linkPrivacy: {
      _allHandleLocales: FieldLocale<string>[];
    };
  };
  datoCmsGiftPackage?: {
    _allCheckboxTextLocales: {
      locale: string;
      value: string;
    }[];
    _allPlaceholderTextareaLocales: {
      locale: string;
      value: string;
    }[];
  } | null;
  datoCmsSplitShippingPromoAtCheckout: {
    shopifyHandle: string;
    bannerImage: {
      url: string;
    };
    _allAvailablePromoTextLocales: {
      locale: string;
      valueNode: {
        childMarkdownRemark: {
          html: string;
        };
      };
    }[];
    _allObtainedPromoTextLocales: {
      locale: string;
      valueNode: {
        childMarkdownRemark: {
          html: string;
        };
      };
    }[];
  };
}

export default function Cart({}: Props) {
  const { locale, defaultLocale, getLocalizedValue } = useLocale();
  const { formatMessage } = useTranslation();
  const productOptions = useProductOptions();
  const {
    datoCmsSplitShippingPromoAtCheckout: splitShippingPromo,
    datoCmsGeneral,
    datoCmsGiftPackage,
  } = useStaticQuery<StaticQuery>(staticQuery);
  const {
    cart,
    addNote,
    sideCartVisible,
    hideSideCart,
    removeLineItem,
    changeQuantity,
    giftAtCheckout,
    selectedGiftVariantId,
    totalPriceForGiftAttribution = 0,
    changeGiftVariant,
    splitShippingIds,
  } = useCart();

  const selectedGiftVariant =
    giftAtCheckout &&
    selectedGiftVariantId &&
    giftAtCheckout.variants.find((v) => v.shopifyId === selectedGiftVariantId);

  const [showShipping, setShowShipping] = useState(false);
  const [shipOnlyToMe, setShipOnlyToMe] = useState(false);
  const [productToShip, setProductToShip] = useState<string>();
  const [shippingEmail, setShippingEmail] = useState<string>();
  const [shippingEmailError, setShippingEmailError] = useState<boolean>(false);
  const [shippingAddress, setShippingAddress] = useState<MailingAddressInput>({
    country: "IT",
  });
  const [shippingFieldsWithError, setShippingFieldsWithError] = useState<
    string[]
  >([]);
  const [message, setMessage] = useState<string>();
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [giftPackage, setGiftPackage] = useState(false);
  const [giftPackageNote, setGiftPackageNote] = useState("");
  const [isOpenScalapayInfo, setOpenScalapayInfo] = useState(false);

  const lineItems = cart
    ? cart.lineItems.filter((item) => item.state !== "removing")
    : [];
  const totalPrice = lineItems.reduce(
    (sum, item) => sum + parseFloat(item.variant!.price) * item.quantity,
    0
  );
  const totalCompareAtPrice = lineItems.reduce(
    (sum, item) =>
      sum +
      parseFloat(item.variant!.compareAtPrice || item.variant!.price) *
        item.quantity,
    0
  );
  const totalDiscount = lineItems.reduce(
    (discount, item) =>
      discount +
      (item.discountAllocations && item.discountAllocations.length > 0
        ? item.discountAllocations.reduce(
            (acc, d) => acc + Number(d.allocatedAmount.amount),
            0
          )
        : 0),
    0
  );
  const isDiscounted =
    (!!totalCompareAtPrice &&
      totalCompareAtPrice > 0 &&
      totalCompareAtPrice !== totalPrice) ||
    totalDiscount > 0;
  const linksPrivacy = datoCmsGeneral.linkPrivacy._allHandleLocales;
  const localizedUrl = linksPrivacy.find(
    (p: FieldLocale) => p.locale === locale
  )!.value;
  const privacyLinkLocalized =
    locale === defaultLocale
      ? `/${localizedUrl}/`
      : `/${locale}/${localizedUrl}`;

  let checkoutUrl: string | null = null;
  if (cart && cart.webUrl) {
    checkoutUrl = `${cart.webUrl}${
      cart.webUrl.includes("?") ? "&" : "?"
    }locale=${locale}`;
  }

  useEffect(() => {
    setShowShipping(false);
  }, [cart && cart.lineItems]);

  let enableSplitShippingPromo =
    lineItems.reduce((acc: number, item) => {
      return splitShippingIds.includes(item.variant!.id)
        ? acc + item.quantity
        : acc;
    }, 0) > 1;

  const handleDefaultSubmit = async () => {
    setIsLoading(true);
    if (giftPackage) {
      await addNote(`Opzione regalo\n\nTesto:\n${giftPackageNote}`);
    } else {
      await addNote("");
    }
    setIsLoading(false);
    sendCheckoutEvent();
    location.href = checkoutUrl!;
  };

  const handleSplitShippingSubmit = async () => {
    setIsLoading(true);
    if (!shipOnlyToMe) {
      const emailError =
        !shippingEmail ||
        !shippingEmail.match(
          /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/
        );
      const addressErrors = checkAddressValidity(shippingAddress);
      setShippingEmailError(emailError);
      setShippingFieldsWithError(addressErrors);
      setPrivacyError(!checkedPrivacy);
      if (emailError || addressErrors.length > 0 || !checkedPrivacy) {
        setIsLoading(false);
        return;
      }
      const noteString = `PROMOZIONE\n\nRiferimento:\n${productToShip}\n\nEmail:\n${shippingEmail}\n\nIndirizzo:\nNome: ${
        shippingAddress.firstName
      }\nCognome: ${shippingAddress.lastName}\nRagione sociale: ${
        shippingAddress.company || ""
      }\nIndirizzo 1: ${shippingAddress.address1}\nIndirizzo 2: ${
        shippingAddress.address2 || ""
      }\nStato: ${shippingAddress.country}\nCittà: ${
        shippingAddress.city
      }\nProvincia: ${shippingAddress.province}\nCap: ${
        shippingAddress.zip || ""
      }\nTelefono: ${shippingAddress.phone}\n\nMessaggio regalo:\n${message}`;
      await addNote(noteString);
      setIsLoading(false);
    } else {
      await addNote("PROMOZIONE");
      setIsLoading(false);
    }
    sendCheckoutEvent();
    location.href = checkoutUrl!;
  };

  const sendCheckoutEvent = () => {
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: "checkout",
      eventCategory: "Ecommerce",
      eventAction: "Initiate Checkout",
      ecommerce: {
        checkout: {
          products: cart?.lineItems.map((lineItem) => {
            const productId = lineItem.variant?.product.id || "";
            const variantId = lineItem.variant?.id || "";
            return {
              id: lineItem.variant?.sku,
              name:
                (lineItem.variant?.product.variants.edges?.length || 0) > 1
                  ? `${lineItem.variant?.product.title} - ${lineItem.variant?.title}`
                  : lineItem.variant?.product.title,
              variant: lineItem.variant?.title,
              price: lineItem.variant?.price,
              quantity: lineItem.quantity,
              product_id: productId,
              variant_id: variantId,
            };
          }),
        },
      },
    });
  };

  const renderGiftBanner = () =>
    giftAtCheckout && giftAtCheckout.availableForSale ? (
      <GiftContainer>
        <GiftDetail>
          <GiftImage
            src={
              (selectedGiftVariant &&
                selectedGiftVariant.image &&
                selectedGiftVariant.image.originalSrc) ||
              giftAtCheckout.imageSrc
            }
          />
          <GiftDescriptionWrapper>
            <GiftDescription
              dangerouslySetInnerHTML={{
                __html:
                  totalPriceForGiftAttribution -
                    giftAtCheckout.minimumAmountSpent <
                  0
                    ? getLocalizedValue(
                        giftAtCheckout.allGiftNotEarnedTextNodeLocale
                      )!.childMarkdownRemark.html.replace(
                        "{price}",
                        `${
                          giftAtCheckout.minimumAmountSpent -
                          totalPriceForGiftAttribution
                        }`
                      )
                    : getLocalizedValue(
                        giftAtCheckout.allGiftEarnedTextNodeLocale
                      )!.childMarkdownRemark.html,
              }}
            />
            {totalPriceForGiftAttribution - giftAtCheckout.minimumAmountSpent >=
              0 &&
              giftAtCheckout.variants.length > 1 && (
                <GiftVariantSelect
                  size="small"
                  placeholder=""
                  value={selectedGiftVariantId}
                  useSystemSelect
                  options={giftAtCheckout.variants.filter(
                    (v) => v.availableForSale
                  )}
                  display={(value) =>
                    value.selectedOptions.reduce(
                      (
                        acc: string,
                        option: { name: string; value: string }
                      ) => {
                        const localizedOption = productOptions[option.name];
                        const localizedValue = localizedOption.values.find(
                          (value) => value.shopifyValue === option.value
                        );
                        return acc
                          ? `acc / ${
                              (localizedValue && localizedValue.value) ||
                              option.value
                            }`
                          : (localizedValue && localizedValue.value) ||
                              option.value;
                      },
                      ""
                    )
                  }
                  valueChange={(value) => changeGiftVariant(value.shopifyId)}
                  compare={(value, option) => value === option.shopifyId}
                />
              )}
          </GiftDescriptionWrapper>
        </GiftDetail>
        {!giftAtCheckout.hideBar &&
          totalPriceForGiftAttribution < giftAtCheckout.minimumAmountSpent && (
            <LoadingMaskContainer>
              <LoadingMask>
                <LoadingBar
                  percentage={
                    (100 * totalPriceForGiftAttribution) /
                    giftAtCheckout.minimumAmountSpent
                  }
                />
              </LoadingMask>
              <TargetPrice>€{giftAtCheckout.minimumAmountSpent}</TargetPrice>
            </LoadingMaskContainer>
          )}
      </GiftContainer>
    ) : null;

  const renderSplitShippingBanner = () =>
    // TODO: force remove split shipping promo
    false && splitShippingPromo ? (
      <SplitShippingContainer>
        <SplitShippingDetail>
          <SplitShippingImage src={splitShippingPromo.bannerImage.url} />
          <SplitShippingDescription
            dangerouslySetInnerHTML={{
              __html: !enableSplitShippingPromo
                ? splitShippingPromo._allAvailablePromoTextLocales.find(
                    (o) => o.locale === locale
                  )!.valueNode.childMarkdownRemark.html
                : splitShippingPromo._allObtainedPromoTextLocales.find(
                    (o) => o.locale === locale
                  )!.valueNode.childMarkdownRemark.html,
            }}
          />
        </SplitShippingDetail>
      </SplitShippingContainer>
    ) : null;

  return (
    <SideBarPanel
      position="right"
      isOpen={sideCartVisible}
      handleClose={hideSideCart}
    >
      <CartContainer>
        <Header>
          <CartIcon />
          <Title>
            {formatMessage({
              id: "cart.title",
              defaultMessage: "Shopping bag",
            })}
          </Title>
          <CloseButton onClick={hideSideCart}>
            <CloseIcon />
          </CloseButton>
        </Header>
        {/* 
        {locale === "it" && (
          <InfoMessage>
            <div>
              <b>-15%</b> se acquisti 2 gioielli in argento
            </div>
            <div>
              <b>-20%</b> se acquisti 3 o più gioielli in argento
            </div>
          </InfoMessage>
        )}
        {locale === "en" && (
          <InfoMessage>
            <div>
              <b>-15%</b> if you buy 2 jewels in silver
            </div>
            <div>
              <b>-20%</b> if you buy 3 or more jewels in silver
            </div>
          </InfoMessage>
        )}
        {locale === "es" && (
          <InfoMessage>
            <div>
              <b>-15%</b> si compras 2 joyas de plata
            </div>
            <div>
              <b>-20%</b> si compras 3 o más joyas de plata
            </div>
          </InfoMessage>
        )} */}

        {lineItems.length > 0 ? (
          !showShipping ? (
            <>
              <LineItems
                items={lineItems}
                onRemove={removeLineItem}
                onChangeQuantity={changeQuantity}
              />
              <Summary>
                {renderSplitShippingBanner()}
                {renderGiftBanner()}
                <SummaryWrapper>
                  {totalPrice > 0 && (
                    <TotalLine>
                      <TotalLabel>
                        {formatMessage({ id: "cart.total" })}
                      </TotalLabel>
                      <TotalPriceContainer>
                        {isDiscounted && (
                          <TotalCompareAtPrice>
                            {formatMoney(
                              totalCompareAtPrice * 100,
                              "€{{amount}}"
                            )}
                          </TotalCompareAtPrice>
                        )}
                        <TotalPrice isDiscounted={isDiscounted}>
                          {formatMoney(
                            (totalPrice - totalDiscount) * 100,
                            "€{{amount}}"
                          )}
                        </TotalPrice>
                      </TotalPriceContainer>
                    </TotalLine>
                  )}
                  <Scalapay>
                    <ScalapayText>
                      <FormattedMessage
                        id="scalapay.cta"
                        values={{
                          price: (
                            <ScalapayPrice>
                              {formatMoney(
                                ((totalPrice - totalDiscount) / 3) * 100,
                                "€{{amount}}"
                              )}
                            </ScalapayPrice>
                          ),
                        }}
                      />
                    </ScalapayText>
                    <ScalapayInfo onClick={() => setOpenScalapayInfo(true)}>
                      <ScalapayLogoContainer>
                        <ScalapayLogo />
                      </ScalapayLogoContainer>
                      ⓘ
                    </ScalapayInfo>
                    <ScalapayModal
                      isOpen={isOpenScalapayInfo}
                      onClose={() => setOpenScalapayInfo(false)}
                    ></ScalapayModal>
                  </Scalapay>
                  {!enableSplitShippingPromo && checkoutUrl && (
                    <>
                      {datoCmsGiftPackage && (
                        <>
                          <StyledInputCheckbox
                            id="gift-package-side-cart"
                            isChecked={giftPackage}
                            onToggle={() => setGiftPackage((gp) => !gp)}
                            hasError={false}
                          >
                            <span>
                              {getLocalizedValue(
                                datoCmsGiftPackage._allCheckboxTextLocales
                              )}
                            </span>
                          </StyledInputCheckbox>
                          {giftPackage && (
                            <StyledInputTextArea
                              as="textarea"
                              placeholder={getLocalizedValue(
                                datoCmsGiftPackage._allPlaceholderTextareaLocales
                              )}
                              value={giftPackageNote}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setGiftPackageNote(e.target.value)
                              }
                            />
                          )}
                        </>
                      )}
                      <StyledButton
                        size="large"
                        loading={isLoading}
                        onClick={handleDefaultSubmit}
                      >
                        checkout
                      </StyledButton>
                    </>
                  )}
                  {enableSplitShippingPromo && (
                    <StyledButton
                      size="large"
                      onClick={() => setShowShipping(true)}
                    >
                      <FormattedMessage
                        id="cart.continue"
                        defaultMessage="Continue"
                      />
                    </StyledButton>
                  )}
                  <GoToShoppingBag to={routes.cart} onClick={hideSideCart}>
                    <FormattedMessage
                      id="cart.goToShoppingBag"
                      defaultMessage="Go to shopping bag"
                    />
                  </GoToShoppingBag>
                </SummaryWrapper>
              </Summary>
            </>
          ) : (
            <>
              <InnerTitleWrapper>
                <Title style={{ padding: 0 }}>
                  <FormattedMessage id="splitShipping.title" />
                </Title>
              </InnerTitleWrapper>
              <CheckboxWrapper>
                <InputCheckbox
                  id="ship-only-to-me"
                  isChecked={shipOnlyToMe}
                  onToggle={() => setShipOnlyToMe(!shipOnlyToMe)}
                  hasError={false}
                >
                  <FormattedMessage id="splitShipping.abort" />
                </InputCheckbox>
              </CheckboxWrapper>
              {!shipOnlyToMe && (
                <>
                  <SelectProductToShip onChange={setProductToShip} />
                  <SplitShippingEmail
                    onChange={setShippingEmail}
                    error={shippingEmailError}
                  />
                  <SplitShippingAddress
                    address={shippingAddress}
                    fieldsWithError={shippingFieldsWithError}
                    onChange={setShippingAddress}
                  />
                  <SplitShippingTextArea onChange={setMessage} />
                  <CheckboxWrapper>
                    <Checkbox hasError={privacyError}>
                      <input
                        id="privacy-side-cart"
                        type="checkbox"
                        checked={checkedPrivacy}
                        onChange={() => setCheckedPrivacy(!checkedPrivacy)}
                      />
                      <label htmlFor="privacy-side-cart">
                        <FormattedMessage
                          id="cart.privacy"
                          values={{
                            privacyPolicy: (
                              <Link to={privacyLinkLocalized}>
                                <FormattedMessage id="label.cart.privacyLinkLabel" />
                              </Link>
                            ),
                          }}
                        />
                      </label>
                    </Checkbox>
                  </CheckboxWrapper>
                </>
              )}
              <Summary>
                <SummaryWrapper>
                  {totalPrice > 0 && (
                    <TotalLine>
                      <TotalLabel>
                        {formatMessage({ id: "cart.total" })}
                      </TotalLabel>
                      <TotalPriceContainer>
                        {isDiscounted && (
                          <TotalCompareAtPrice>
                            {formatMoney(
                              totalCompareAtPrice * 100,
                              "€{{amount}}"
                            )}
                          </TotalCompareAtPrice>
                        )}
                        <TotalPrice isDiscounted={isDiscounted}>
                          {formatMoney(
                            (totalPrice - totalDiscount) * 100,
                            "€{{amount}}"
                          )}
                        </TotalPrice>
                      </TotalPriceContainer>
                    </TotalLine>
                  )}
                  {checkoutUrl && (
                    <StyledButton
                      size="large"
                      loading={isLoading}
                      onClick={handleSplitShippingSubmit}
                    >
                      checkout
                    </StyledButton>
                  )}
                  <GoToShoppingBag to={routes.cart} onClick={hideSideCart}>
                    <FormattedMessage
                      id="cart.goToShoppingBag"
                      defaultMessage="Go to shopping bag"
                    />
                  </GoToShoppingBag>
                  <Back onClick={() => setShowShipping(false)}>
                    <FormattedMessage id="cart.back" defaultMessage="Back" />
                  </Back>
                </SummaryWrapper>
              </Summary>
            </>
          )
        ) : (
          <>
            <EmptyCartMessage>
              <FormattedMessage
                id="cart.emptyCart"
                defaultMessage="Empty cart"
              />
            </EmptyCartMessage>
            {renderSplitShippingBanner()}
            {renderGiftBanner()}
          </>
        )}
      </CartContainer>
    </SideBarPanel>
  );
}

const checkAddressValidity = (address: MailingAddressInput): string[] => {
  const fieldsWithError = [];
  if (!address.company) {
    if (!address.firstName || !address.lastName) {
      fieldsWithError.push("firstName", "lastName", "company");
    }
  }
  if (!address.address1) {
    fieldsWithError.push("address1");
  }
  if (!address.city) {
    fieldsWithError.push("city");
  }
  if (!address.country) {
    fieldsWithError.push("country");
  }
  if (!address.phone) {
    fieldsWithError.push("phone");
  }
  return fieldsWithError;
};

const staticQuery = graphql`
  {
    datoCmsGeneral {
      linkPrivacy {
        _allHandleLocales {
          locale
          value
        }
      }
    }
    datoCmsGiftPackage {
      _allCheckboxTextLocales {
        locale
        value
      }
      _allPlaceholderTextareaLocales {
        locale
        value
      }
    }
    datoCmsSplitShippingPromoAtCheckout(locale: { eq: "it" }) {
      shopifyHandle
      bannerImage {
        url
      }
      _allAvailablePromoTextLocales {
        locale
        valueNode {
          childMarkdownRemark {
            html
          }
        }
      }
      _allObtainedPromoTextLocales {
        locale
        valueNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const CartContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 500px) {
    padding: 0 15px;
  }
`;

const EmptyCartMessage = styled.p`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.06em;
  padding: 50px 0;
  color: #333;
`;

const Header = styled.div`
  height: 70px;
  flex: 0 0 70px;
  position: relative;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 0 20px;
  @media (max-width: 500px) {
    height: 60px;
    flex: 0 0 60px;
  }
`;

const InfoMessage = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.background2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.brand500};
  & > a {
    color: ${({ theme }) => theme.colors.brand500};
    text-decoration: underline;
  }
`;

const Title = styled.div`
  font-weight: 600;
  letter-spacing: 0.12em;
  font-size: 14px;
  text-transform: uppercase;
  flex: 1;
  text-align: left;
  padding-left: 10px;
  color: #333;
`;

const CloseIcon = styled(Close)`
  height: 24px;
  width: 24px;
  color: ${({ theme }) => theme.colors.text};
`;

const Summary = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  flex: 0 0 auto;
  padding-bottom: 20px;
  flex-direction: column;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  z-index: 1;
  @media (max-width: 500px) {
    height: auto;
    flex: 0 0 auto;
    padding-bottom: 15px;
  }
`;

const SummaryWrapper = styled.div`
  padding: 0 20px 0 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  ${ButtonLink} {
    margin: 20px 0;
    @media (max-width: 500px) {
      margin: 10px 0;
    }
  }
`;

const StyledInputCheckbox = styled(InputCheckbox)`
  padding-top: 10px;
  padding-bottom: 0;
  label {
    span {
      font-size: 13px;
    }
  }
`;

const StyledInputTextArea = styled(InputTextArea)`
  margin-bottom: 0;
  margin-top: 10px;
`;

const GoToShoppingBag = styled(Link)`
  text-align: center;
  text-decoration: underline;
  font-weight: 600;
  letter-spacing: 0.08em;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main};
  &:hover {
    cursor: pointer;
  }
`;

const InnerTitleWrapper = styled.div`
  padding: 20px 0px 10px 0px;
`;

const CheckboxWrapper = styled.div`
  padding: 0 0 10px 0;
`;

const Checkbox = styled.div<{ hasError: boolean }>`
  input {
    opacity: 0;
    position: absolute;
    &:checked + label:before {
      background: #333;
      box-shadow: inset 0 0 0 5px #fff;
    }
  }

  label {
    display: flex;
    cursor: pointer;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid #c6c6c6;
      box-sizing: border-box;
      display: block;
      ${({ theme, hasError }) =>
        hasError &&
        css`
          border: 2px solid ${theme.colors.error};
        `}
    }
    & > span {
      width: calc(100% - 20px);
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 11px;
      letter-spacing: 0.04em;
      color: #333;
    }
    a {
      span {
        color: ${({ theme }) => theme.colors.main};
        text-decoration: none;
        &:hover {
          cursor: pointer;
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  flex: 0 0 50px;
  margin: 20px 0;
  @media (max-width: 500px) {
    margin: 15px 0;
  }
`;

const Back = styled.div`
  text-align: center;
  text-decoration: underline;
  font-weight: 600;
  letter-spacing: 0.08em;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main};
  &:hover {
    cursor: pointer;
  }
  margin-top: 10px;
`;

const GiftContainer = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.background2};
  border-top: 1px solid ${({ theme }) => theme.colors.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.main};
  width: 100%;
  margin-top: -1px;
  padding: 18px 20px 15px 20px;
  box-shadow: 0px 0px 10px 0px rgba(151, 123, 43, 0.2);
`;

const GiftDetail = styled.div`
  display: flex;
  align-items: flex-start;
  white-space: pre-line;
`;

const GiftImage = styled.img`
  margin-top: 5px;
  object-fit: cover;
  width: 70px;
  height: 70px;
`;

const GiftDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GiftDescription = styled.div`
  font-size: 13px;
  margin-left: 15px;
  color: #333;
  p {
    margin: 0;
    padding-bottom: 4px;
  }
`;

const GiftVariantSelect = styled(Select)`
  margin-top: 5px;
  margin-left: 15px;
  margin-bottom: 0;
  width: calc(100% - 15px);
`;

const LoadingMaskContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 20px;
`;

const LoadingMask = styled.div`
  flex: 1;
  height: 7px;
  border-radius: 4px;
  background-color: rgba(151, 123, 43, 0.2);
`;

const LoadingBar = styled.div<{ percentage: number }>`
  width: ${({ percentage }) => percentage}%;
  max-width: 100%;
  height: 7px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.main};
  box-shadow: 0px 0px 10px 0px rgba(151, 123, 43, 1);
`;

const TargetPrice = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.main};
  padding-left: 20px;
`;

const TotalLabel = styled.span`
  font-weight: 600;
  letter-spacing: 0.12em;
  font-size: 14px;
  text-transform: uppercase;
  flex: 1;
  text-align: left;
  color: #333;
`;

const TotalPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const TotalCompareAtPrice = styled.span`
  text-decoration: line-through;
  font-size: 14px;
  color: #333;
  letter-spacing: 0.12em;
  margin-right: 5px;
`;

const TotalPrice = styled.span<{ isDiscounted?: boolean }>`
  font-size: 24px;
  font-weight: 600;
  color: #d24017;
  ${({ isDiscounted }) => !isDiscounted && `color: #333;`};
  letter-spacing: 0.12em;
  @media (max-width: 500px) {
    font-size: 22px;
  }
`;

const TotalLine = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  @media (max-width: 500px) {
    padding-top: 10px;
  }
`;

const Scalapay = styled.div`
  align-items: center;
  font-size: 14px;
  width: 100%;
  align-items: center;
`;

const ScalapayText = styled.span`
  white-space: pre;
  display: inline-block;
`;

const ScalapayPrice = styled.span`
  font-weight: 700;
`;

const ScalapayLogoContainer = styled.div`
  height: 16px;
  width: 80px;
  display: flex;
  padding-right: 4px;
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const ScalapayInfo = styled.span`
  font-weight: 700;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding-left: 4px;
`;

const SplitShippingContainer = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.background2};
  border-top: 1px solid ${({ theme }) => theme.colors.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.main};
  width: calc(100% + 40px);
  transform: translateX(-20px);
  margin-top: -1px;
  padding: 18px 20px 15px 20px;
  box-shadow: 0px 0px 10px 0px rgba(151, 123, 43, 0.2);
`;

const SplitShippingDetail = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-line;
`;

const SplitShippingImage = styled.img`
  object-fit: cover;
  width: 70px;
  height: 70px;
`;

const SplitShippingDescription = styled.div`
  font-size: 13px;
  margin-left: 15px;
  color: #333;
  p {
    margin: 0;
    padding-bottom: 4px;
    a {
      color: #333;
      &:hover {
        color: ${({ theme }) => theme.colors.main};
      }
    }
  }
`;
