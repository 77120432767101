import React, { useRef } from "react";
import styled from "styled-components";
import useComponentSize from "hooks/useComponentSize";
import { useLocalizedValue } from "hooks/useLocale";
import { FieldLocale } from "utils/common/locale";
import Menu from "./Menu";
import { graphql, useStaticQuery } from "gatsby";

interface Props {
  allPathLocales: FieldLocale<string>[];
}

const Header: React.SFC<Props> = ({ allPathLocales }) => {
  const { datoCmsGeneral } = useStaticQuery(staticQuery);

  const announcementText = useLocalizedValue(
    datoCmsGeneral._allAnnouncementTextLocales || []
  ) as string;

  return (
    <div>
      <HeaderContainer>
        {datoCmsGeneral && datoCmsGeneral.announcementVisibility && (
          <TopPanelContainer>{announcementText}</TopPanelContainer>
        )}
        <Menu allPathLocales={allPathLocales} />
      </HeaderContainer>
      <TopPanelContainer>{announcementText}</TopPanelContainer>
      <Placeholder />
    </div>
  );
};

const staticQuery = graphql`
  {
    datoCmsGeneral {
      announcementVisibility
      _allAnnouncementTextLocales {
        locale
        value
      }
    }
  }
`;

const Placeholder = styled.div`
  height: ${({ theme }) => theme.dimensions.navBarHeight};
  @media (max-width: ${({ theme }) => theme.breakpoints.sideNav}) {
    height: 60px;
  }
`;

const TopPanelContainer = styled.div`
  background-color: #f9f9f9;
  width: 100%;
  padding: 10px;
  text-align: center;
  color: #706f6f;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-size: 12px;
  line-height: 1em;
`;

const HeaderContainer = styled.header`
  position: fixed;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
  top: 0;
  width: 100%;
  left: 0;
  z-index: 5;
`;

export default Header;
