import React from "react";
import { LineItem } from "hooks/useCart";
import useProductOptions from "hooks/useProductOptions";
import { useLocalizedValue } from "hooks/useLocale";
import Cross from "components/icons/Cross";
import Link from "components/Link";
import { FormattedMessage } from "react-intl";
import styled, { keyframes } from "styled-components";
import formatMoney from "utils/common/formatMoney";
import QuantityInput from "./QuantityInput";

interface Props {
  item: LineItem;
  onRemove: () => void;
  onChangeQuantity: (newQuantity: number) => void;
}

function DefaultLineItem({ item, onRemove, onChangeQuantity }: Props) {
  const productOptions = useProductOptions();
  const title = useLocalizedValue(item.allLocaleValues.titles);
  const path = useLocalizedValue(item.allLocaleValues.paths) || "#";

  const compareAtPrice = item.variant!.compareAtPrice
    ? parseFloat(item.variant!.compareAtPrice) * 100
    : null;
  const price = parseFloat(item.variant!.price) * 100;
  const automaticDiscount =
    (!!item.discountAllocations &&
      Number(
        item.discountAllocations.reduce(
          (acc, discount) => acc + Number(discount.allocatedAmount.amount),
          0
        )
      )) ||
    0;
  const isDiscounted =
    (!!compareAtPrice && compareAtPrice > 0 && compareAtPrice !== price) ||
    automaticDiscount > 0;
  const selectedOptions = item.variant!.selectedOptions
    ? item.variant!.selectedOptions.filter(
        (option) => option.name !== "Title" && option.value !== "Default Title"
      )
    : [];

  return (
    <ItemWrapper>
      <ItemContainer>
        <ImageContainer>
          <ImageLink to={path}>
            <Image
              src={item.variant!.image ? item.variant!.image.originalSrc : ""}
              alt={
                (item.variant!.image ? item.variant!.image.altText : title) ||
                ""
              }
            />
          </ImageLink>
        </ImageContainer>
        <Details>
          <ItemLink to={path}>
            <Title>{title}</Title>
          </ItemLink>
          {selectedOptions.map((option) => {
            const localizedOption = productOptions[option.name];
            const localizedValue = localizedOption.values.find(
              (value) => value.shopifyValue === option.value
            );
            return (
              <OptionContainer key={`${option.name}/${option.value}`}>
                <OptionName>{localizedOption.optionName}:</OptionName>
                <OptionValue>
                  {localizedValue ? localizedValue.value : option.value}
                </OptionValue>
              </OptionContainer>
            );
          })}

          {item.customAttributes &&
            item.customAttributes.map((elem: any, index: number) => {
              return (
                <OptionContainer key={index}>
                  <OptionName>
                    {elem.key === "engraving_text" && (
                      <FormattedMessage id="engravingSelector.text" />
                    )}
                    {elem.key === "engraving_font" && (
                      <FormattedMessage id="engravingSelector.font" />
                    )}
                    {elem.key.startsWith("text_") && (
                      <>
                        <FormattedMessage id="label.text" />
                        {item.customAttributes.length > 1
                          ? ` ${index + 1}`
                          : ``}
                      </>
                    )}
                    :
                  </OptionName>
                  <OptionValue>{elem.value}</OptionValue>
                </OptionContainer>
              );
            })}
          <QuantityContainer>
            <QuantityInput
              quantity={item.quantity}
              isChangingQuantity={item.state === "changing_quantity"}
              onChangeQuantity={onChangeQuantity}
            />
            <Remove onClick={onRemove}>
              <Cross size="18px" />
            </Remove>
          </QuantityContainer>
          <TotalContainer>
            <Price>
              {isDiscounted && (
                <OriginalPrice>
                  {formatMoney(
                    (compareAtPrice || price) * item.quantity,
                    "€{{amount}}"
                  )}
                </OriginalPrice>
              )}
              <Total isDiscounted={isDiscounted}>
                {formatMoney(
                  price * item.quantity - automaticDiscount * 100,
                  "€{{amount}}"
                )}
              </Total>
            </Price>
          </TotalContainer>
        </Details>
      </ItemContainer>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  overflow: hidden;
  position: relative;
  margin: 15px 20px;
  margin-bottom: 0;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  &:last-of-type {
    border: none;
  }
  @media (max-width: 500px) {
    margin: 8px 0;
    padding-bottom: 10px;
  }
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
`;

const Remove = styled.div`
  position: absolute;
  top: -4px;
  right: 0;
  color: ${({ theme }) => theme.colors.text};
  padding: 4px;
  opacity: 0.6;
  transition: 0.3s all;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const TotalContainer = styled.div`
  text-align: right;
  padding-top: 15px;
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 0.08em;
`;

const OriginalPrice = styled.div`
  text-decoration: line-through;
  padding-right: 5px;
  font-size: 14px;
  color: #333;
  text-align: right;
`;

interface TotalProps {
  isDiscounted: boolean;
}

const Total = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #d24017;
  ${({ isDiscounted }: TotalProps) => !isDiscounted && `color: #333;`};
  font-weight: 700;
`;

const ImageContainer = styled.div`
  width: 120px;
  @media (max-width: 500px) {
    width: 80px;
  }
`;

const ImageLink = styled(Link)`
  display: block;
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
  display: block;
`;

const ItemLink = styled(Link)`
  font-weight: 500;
  letter-spacing: 0.04em;
  padding-bottom: 5px;
  text-decoration: none;
  color: #333;
  display: block;
  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.div`
  font-weight: 600;
  letter-spacing: 0.06em;
  font-size: 14px;
  line-height: 1.3em;
  padding-right: 35px;
  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 120px);
  min-width: calc(100% - 120px);
  padding: 15px 0 0 15px;
  padding-right: 0;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 500px) {
    width: calc(100% - 80px);
    padding: 5px 0 0 10px;
    min-width: calc(100% - 80px);
  }
`;
const OptionName = styled.span`
  padding-right: 5px;
`;

const OptionValue = styled.span``;

const OptionContainer = styled.div`
  display: flex;
  align-items: start;
  font-weight: 600;

  color: #706f6f;
  font-size: 12px;
  letter-spacing: 0.06em;
`;

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 10;
  background-color: #fff;
  font-size: 14px;
`;

export default DefaultLineItem;
