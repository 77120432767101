import React, { useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Location } from "@reach/router";
import ChevronDown from "components/icons/ChevronDown";
import Link from "components/Link";
import { useLocale, getLocalizedValue, FieldLocale } from "hooks/useLocale";

interface Props {
  allPathLocales: FieldLocale<string>[];
}

const LanguageSelector: React.SFC<Props> = ({ allPathLocales }) => {
  const { locale, setLocale } = useLocale();
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);

  return (
    <LanguageSelectorContainer>
      <Activator>
        <CurrentLanguage>{locale}</CurrentLanguage>
        <ChevronDown />
      </Activator>
      <LanguageListContainer>
        <LanguageList>
          {allPathLocales.map(pathLocale => (
            <Language key={pathLocale.locale}>
              <Location>
                {({ location }) => {
                  const suffix = location.pathname.includes("account")
                    ? location.pathname.replace(
                        `${getLocalizedValue(
                          allPathLocales,
                          pathLocale.locale
                        )}`,
                        ""
                      )
                    : "";
                  return (
                    <Link
                      to={`${pathLocale.value}${suffix}`}
                      onClick={() => {
                        setLocale(pathLocale.locale);
                      }}
                    >
                      <FormattedMessage
                        id={pathLocale.locale}
                        defaultMessage={pathLocale.locale}
                      />
                    </Link>
                  );
                }}
              </Location>
            </Language>
          ))}
        </LanguageList>
      </LanguageListContainer>
      {isSelectorOpen && <Mask onClick={() => setIsSelectorOpen(false)} />}
    </LanguageSelectorContainer>
  );
};

const Activator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${ChevronDown} {
    width: 20px;
    height: 20px;
    padding-top: 3px;
  }
`;

const LanguageListContainer = styled.div`
  position: absolute;
  top: 70px;
  left: -20px;
  overflow: hidden;

  transition: 0.5s all;
  background-color: #fff;
  max-height: 0;
`;

const LanguageList = styled.ul`
  padding: 20px;
  list-style: none;
  margin: 0;
`;

const LanguageSelectorContainer = styled.div`
  position: relative;
  font-size: 13px;
  letter-spacing: 0.12em;
  color: ${({ theme }) => theme.colors.main};
  display: flex;
  font-weight: 600;
  padding: 0 10px;
  transition: 0.5s all;
  &:hover {
    ${Activator} {
      cursor: pointer;
    }
    ${LanguageListContainer} {
      box-shadow: ${({ theme }) => theme.boxShadow};
      max-height: 400px;
    }
  }
`;

const Language = styled.li`
  text-transform: uppercase;
  margin: 0 10px;
  z-index: 200;
  font-size: 13px;
  letter-spacing: 0.12em;
  color: #333;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  padding: 10px 0;
  display: inline-block;
  &::after {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    width: 0;
    background-color: ${({ theme }) => theme.colors.main};
    bottom: 8px;
    transition: 0.5s all;
    left: 0;
  }

  &:hover {
    cursor: pointer;
    font-weight: 600;
    &::after {
      width: 100%;
    }
  }
  a {
    color: #333;
    text-decoration: none;
  }
`;

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  background-color: transparent;
`;

const CurrentLanguage = styled.div`
  text-transform: uppercase;
`;

export default LanguageSelector;
