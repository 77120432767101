import InputTextArea from "components/InputTextArea";
import { useTranslation } from "hooks/useTranslation";
import React, { ChangeEvent } from "react";
import styled from "styled-components";

interface Props {
  onChange: (message: string) => void;
}

const SplitShippingTextArea: React.SFC<Props> = ({ onChange }) => {
  const { formatMessage } = useTranslation();

  return (
    <Container>
      <Label>{formatMessage({ id: "cart.message" })}</Label>
      <InputTextArea
        as="textarea"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`;

const Label = styled.div`
  font-size: 14px;
`;

export default SplitShippingTextArea;
