import { navigate } from "@reach/router";
import CartIcon from "components/cart/CartIcon";
import MenuIcon from "components/icons/Menu";
import SearchIcon from "components/icons/Search";
import UserIcon from "components/icons/User";
import Link from "components/Link";
import Logo from "components/Logo";
import Wrapper from "components/Wrapper";
import { graphql, useStaticQuery } from "gatsby";
import { useCart } from "hooks/useCart";
import React, { useState } from "react";
import styled from "styled-components";
import { FieldLocale } from "utils/common/locale";
import { routes } from "utils/routes";
import LanguageSelector from "./LanguageSelector";
import MenuLink from "./MenuLink";
import SideMenu from "./SideMenu";
import Search from "./Search";
import { useLocale } from "hooks/useLocale";

interface Props {
  allPathLocales: FieldLocale<string>[];
}

export default function Menu({ allPathLocales }: Props) {
  // Static data
  const navigationQuery = useStaticQuery(staticQuery);
  const navigation = navigationQuery.allDatoCmsNavigation.nodes;

  // State variables
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isHeaderPanelOpen, setIsHeaderPanelOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const { sideCartVisible, showSideCart } = useCart();

  const { locale, defaultLocale } = useLocale();

  /**
   * Root link elements
   */
  const rootLinks = navigation.filter((link: any) => !link.treeParent);

  /**
   * Extract child link elements
   */
  const getChildren = (parentId: string) => {
    return navigation.filter(
      (l: any) => l.treeParent && l.treeParent.id === parentId
    );
  };

  const renderDesktopMenu = () => (
    <DesktopContainer>
      <Wrapper>
        <Link to={`${locale === defaultLocale ? "/" : `/${locale}`}`}>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </Link>
        <CentralSection>
          {rootLinks.map((link: any) => {
            const allItems = getChildren(link.id);
            const featuredItems = allItems.filter((item: any) => item.featured);
            const items = allItems.filter((item: any) => !item.featured);
            return (
              <ExpandableLinkContainer key={link.id}>
                <MenuLink link={link} style={{ textTransform: "uppercase" }} />
                {link.treeChildren.length > 0 && (
                  <HeaderPanel>
                    <Wrapper>
                      <ItemsContainer>
                        <FeaturedItems>
                          {featuredItems.map((link: any) => (
                            <Container key={link.id} featured={link.featured}>
                              <MenuLink link={link} />
                            </Container>
                          ))}
                        </FeaturedItems>
                        <Items>
                          <ItemsGrid>
                            {items.map((link: any) => (
                              <Container key={link.id} featured={link.featured}>
                                <MenuLink link={link} />
                              </Container>
                            ))}
                          </ItemsGrid>
                        </Items>
                      </ItemsContainer>
                    </Wrapper>
                  </HeaderPanel>
                )}
              </ExpandableLinkContainer>
            );
          })}
        </CentralSection>
        <IconsContainer>
          <LanguageSelector allPathLocales={allPathLocales} />
          <NavIconContainer>
            <NavSearchIcon onClick={() => setIsSearching(true)} />
            {isSearching && <Search onClose={() => setIsSearching(false)} />}
          </NavIconContainer>
          <NavIconContainer onClick={() => navigate(routes.account)}>
            <NavUserIcon />
          </NavIconContainer>
          <NavIconContainer onClick={showSideCart}>
            <CartIcon />
          </NavIconContainer>
        </IconsContainer>
        {isHeaderPanelOpen && (
          <Mask onClick={() => setIsHeaderPanelOpen(false)} />
        )}
      </Wrapper>
    </DesktopContainer>
  );

  const renderMobileMenu = () => (
    <MobileContainer>
      <MenuIconContainer>
        <MenuIcon onClick={() => setIsSideBarOpen(true)} />
      </MenuIconContainer>
      <CentralSection>
        <Link to={`${locale === defaultLocale ? "/" : `/${locale}`}`}>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </Link>
      </CentralSection>
      <CartIconContainer onClick={showSideCart}>
        <CartIcon />
      </CartIconContainer>
      <SideMenu
        allPathLocales={allPathLocales}
        navigation={navigation}
        isOpen={isSideBarOpen}
        handleClose={() => setIsSideBarOpen(false)}
      />
    </MobileContainer>
  );

  return (
    <NavBarContainer>
      {renderDesktopMenu()}
      {renderMobileMenu()}
    </NavBarContainer>
  );
}

const staticQuery = graphql`
  {
    allDatoCmsNavigation(
      filter: { locale: { eq: "it" } }
      sort: { fields: [position] }
    ) {
      nodes {
        id
        _allLabelLocales {
          locale
          value
        }
        image {
          alt
          gatsbyImageData(layout: CONSTRAINED)
        }
        linkedResource {
          __typename
          ... on DatoCmsPage {
            _allHandleLocales {
              locale
              value
            }
          }
          ... on DatoCmsCollection {
            _allHandleLocales {
              locale
              value
            }
          }
          ... on DatoCmsCategory {
            _allHandleLocales {
              locale
              value
            }
          }
        }
        _allUrlLocales {
          locale
          value
        }
        treeParent {
          id
        }
        treeChildren {
          id
        }
        featured
        _allFeaturedLabelLocales {
          locale
          value
        }
      }
    }
  }
`;

const NavBarContainer = styled.nav`
  position: relative;
  height: ${({ theme }) => theme.dimensions.navBarHeight};
  background-color: #fff;
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.sideNav}) {
    height: 60px;
  }
`;

const DesktopContainer = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.sideNav}) {
    display: none;
  }
  ${Wrapper} {
    display: flex;
    align-items: center;
  }
`;

const LogoContainer = styled.div`
  padding-top: 4px;
  width: 165px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sideNav}) {
    width: 140px;
  }
  svg {
    width: 100%;
  }
`;

const CentralSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const HeaderPanel = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.dimensions.navBarHeight};
  left: 0;
  overflow: hidden;
  display: none;
  transition: 0.5s;
  background-color: #fff;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  &:hover {
    cursor: default;
  }
`;

const ExpandableLinkContainer = styled.div`
  height: ${({ theme }) => theme.dimensions.navBarHeight};
  display: flex;
  align-items: center;
  ${HeaderPanel} {
    opacity: 0;
    transition: 0.5s;
    display: block;
    pointer-events: none;
  }
  &:hover {
    cursor: pointer;
    ${HeaderPanel} {
      opacity: 1;
      pointer-events: visible;
    }
  }
`;

const IconsContainer = styled.div`
  display: flex;
  height: 100%;
  height: 70px;
`;

const NavIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  svg {
    transition: 0.5s all;
    display: block;
    stroke: ${({ theme }) => theme.colors.main};
    stroke-width: 1.5;
  }
  &:hover {
    cursor: pointer;
    svg {
      stroke-width: 2;
    }
  }
`;

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0.2;
  z-index: 99;
`;

const MobileContainer = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.sideNav}) {
    display: flex;
    width: 100%;
    align-items: center;
  }
`;

const MenuIconContainer = styled.div`
  padding: 10px 9px;
  margin-left: 10px;
  svg {
    width: 28px;
    stroke: ${({ theme }) => theme.colors.main};
    display: block;
  }
`;

const CartIconContainer = styled.div`
  padding: 10px;
  margin-right: 10px;
  svg {
    stroke: ${({ theme }) => theme.colors.main};
    display: block;
  }
`;

const NavSearchIcon = styled(SearchIcon)``;

const NavUserIcon = styled(UserIcon)``;

const ItemsContainer = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
`;

const ItemsGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  column-gap: 20px;
  width: 100%;
`;

const Items = styled.div`
  padding: 0 20px;
`;
const FeaturedItems = styled.div``;

const Container = styled.div<{ featured: boolean }>`
  display: flex;
  padding: 8px 6px;
  min-width: 200px;
`;
