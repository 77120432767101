import { globalHistory } from "@reach/router";
import Cart from "components/cart";
import Footer from "components/Footer";
import Header from "components/header";
import NewsletterModal from "components/NewsletterModal";
import SEO from "components/SEO";
import { UrqlProvider } from "contexts/UrqlContext";
import { graphql, useStaticQuery } from "gatsby";
import { CartProvider, CartUpsell, GiftAtCheckout } from "hooks/useCart";
import { GraphQLProvider } from "hooks/useGraphQL";
import { LocaleProvider, useLocale } from "hooks/useLocale";
import { TranslationProvider } from "hooks/useTranslation";
import { UserProvider } from "hooks/useUser";
import React, { PropsWithChildren, useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import theme, { GlobalStyle } from "theme";
import { QueryParamProvider } from "use-query-params";
import { getPersistedLocale } from "utils/persistence/locale";

interface Props {
  pageContext: {
    locale?: string;
    defaultLocale?: string;
    allPathLocales: any[];
    giftAtCheckout?: GiftAtCheckout;
    splitShippingIds: Array<string>;
    cartUpsell: CartUpsell;
  };
  location: Location;
}

function Layout({
  pageContext,
  children,
  location,
  ...other
}: PropsWithChildren<Props>) {
  const {
    locale,
    defaultLocale = "it",
    allPathLocales = [],
    giftAtCheckout,
    splitShippingIds,
    cartUpsell,
  } = pageContext;

  // This logic is due to some templates for which localization is done client side (account, cart page, etc)
  // We should refactor this and generate all locale pages for those templates too, in order to avoid having a blank screen
  const [fallbackLocale, setFallbackLocale] = useState<string | undefined>();
  async function loadCachedLocale() {
    const cachedLocale = await getPersistedLocale();
    setFallbackLocale(cachedLocale || defaultLocale);
  }
  useEffect(() => {
    if (!locale) {
      loadCachedLocale();
    }
  }, []);

  const layoutLocale = locale || fallbackLocale || defaultLocale;

  return layoutLocale ? (
    <QueryParamProvider reachHistory={globalHistory}>
      <LocaleProvider
        initialLocale={layoutLocale}
        defaultLocale={defaultLocale}
      >
        <TranslationProvider>
          <ThemeProvider theme={theme}>
            <UrqlProvider>
              <GraphQLProvider
                url="/shopify/"
                headers={{
                  "X-Shopify-Storefront-Access-Token":
                    process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN!,
                }}
              >
                <UserProvider>
                  <CartProvider
                    giftAtCheckout={
                      giftAtCheckout?.visibility ? giftAtCheckout : undefined
                    }
                    splitShippingIds={splitShippingIds}
                    cartUpsell={cartUpsell?.visibility ? cartUpsell : undefined}
                  >
                    <ContextEnabledLayout
                      location={location}
                      allPathLocales={allPathLocales}
                    >
                      {children}
                    </ContextEnabledLayout>
                  </CartProvider>
                </UserProvider>
              </GraphQLProvider>
            </UrqlProvider>
          </ThemeProvider>
        </TranslationProvider>
      </LocaleProvider>
    </QueryParamProvider>
  ) : null;
}

interface ContextEnabledLayoutProps {
  location: Location;
  allPathLocales: any[];
}

function ContextEnabledLayout({
  location,
  allPathLocales,
  children,
}: PropsWithChildren<ContextEnabledLayoutProps>) {
  const { locale } = useLocale();

  useEffect(() => {
    const pagePath = `${location.pathname}${location.search}`;
    (window as any).dataLayer.push({
      language: locale,
      event: "Pageview",
      pagePath: pagePath,
    });
  }, [location]);

  const { allDatoCmsSite } = useStaticQuery(staticQuery);
  const {
    globalSeo: { titleSuffix, fallbackSeo, twitterAccount },
  } = allDatoCmsSite.nodes.find((node: any) => node.locale === locale);

  return (
    <>
      <GlobalStyle />
      {fallbackSeo && (
        <SEO
          lang={locale}
          title={fallbackSeo.title}
          titleSuffix={titleSuffix}
          description={fallbackSeo.description}
          author={twitterAccount}
          twitterCard={fallbackSeo.twitterCard}
          imageUrl={fallbackSeo.image && fallbackSeo.image.url}
          allPathLocales={allPathLocales}
        />
      )}
      <Header allPathLocales={allPathLocales} />
      <Main>{children}</Main>
      <Footer />
      <Cart />
      {/* <CustomModal /> */}
      <NewsletterModal />
    </>
  );
}

const staticQuery = graphql`
  {
    allDatoCmsSite {
      nodes {
        locale
        globalSeo {
          titleSuffix
          twitterAccount
          fallbackSeo {
            title
            description
            twitterCard
            image {
              url
            }
          }
        }
      }
    }
  }
`;

const Main = styled.main`
  width: 100%;
`;

export default Layout;
