import React from "react";
import styled from "styled-components";
import { LineItem, useCart } from "hooks/useCart";
import { mergeBundleLineItems } from "utils/cart";
import DefaultLineItem from "./DefaultLineItem";
import ComposableLineItem from "./ComposableLineItem";
import { useLocalizedValue, useLocale } from "hooks/useLocale";
import formatMoney from "utils/common/formatMoney";
import { getAllProductPathLocales } from "utils/common/navigation";
import Link from "components/Link";
import { getFirstMediaImage } from "utils/image";

interface Props {
  items: LineItem[];
  onRemove: (lineItemIds: string | string[]) => void;
  onChangeQuantity: (lineItemId: string, quantity: number) => void;
}

function LineItems({ items, onRemove, onChangeQuantity }: Props) {
  const { locale, defaultLocale, getLocalizedValue } = useLocale();
  const { cart, addToCart, cartUpsell } = useCart();
  const cartLineItems = mergeBundleLineItems(items);

  const renderCartUpsell = () => {
    const title = useLocalizedValue(cartUpsell.datoCmsProduct._allTitleLocales);
    const category = useLocalizedValue(
      cartUpsell.datoCmsProduct.category._allHandleLocales
    );
    const handle = useLocalizedValue(
      cartUpsell.datoCmsProduct._allHandleLocales
    );
    const path = `${
      locale === defaultLocale ? `` : `/${locale}`
    }/${category}/${handle}/`;

    const price = Number(cartUpsell.shopifyProduct.variants[0].price);
    const compareAtPrice = Number(
      cartUpsell.shopifyProduct.variants[0].compareAtPrice
    );

    const isDiscounted =
      !!compareAtPrice && compareAtPrice > 0 && compareAtPrice !== price;

    return cartUpsell &&
      cartUpsell.shopifyProduct.availableForSale &&
      cart &&
      !cart.lineItems.find(
        (item) =>
          item.variant!.id ===
          cartUpsell.shopifyProduct.variants[0].storefrontId
      ) ? (
      <CartUpsellWrapper>
        <CartUpsellContainer>
          <CartUpsellHeading>
            {getLocalizedValue(cartUpsell._allTitleLocales)}
          </CartUpsellHeading>
          <CartUpsellImageContainer>
            <CartUpsellImageLink to={path}>
              <CartUpsellImage
                src={
                  cartUpsell &&
                  getFirstMediaImage(cartUpsell.shopifyProduct.media)
                    ?.originalSrc
                }
              />
            </CartUpsellImageLink>
          </CartUpsellImageContainer>
          <CartUpsellDetails>
            <CartUpsellItemLink to={path}>
              <CartUpsellTitle>{title}</CartUpsellTitle>
            </CartUpsellItemLink>
            <CartUpsellTotalContainer>
              <CartUpsellPrice>
                {isDiscounted && (
                  <CartUpsellOriginalPrice>
                    {formatMoney(compareAtPrice * 100, "€{{amount}}")}
                  </CartUpsellOriginalPrice>
                )}
                <CartUpsellTotal isDiscounted={isDiscounted}>
                  {formatMoney(
                    Number(cartUpsell.shopifyProduct.variants[0].price) * 100,
                    "€{{amount}}"
                  )}
                </CartUpsellTotal>
              </CartUpsellPrice>
            </CartUpsellTotalContainer>
            <CartUpsellAddToCartContainer>
              <CartUpsellAddToCart
                size="slim"
                onClick={() => {
                  const selectedVariant = cartUpsell.shopifyProduct.variants[0];
                  addToCart({
                    variant: {
                      id: selectedVariant.storefrontId,
                      price: selectedVariant.price!,
                      compareAtPrice: selectedVariant.compareAtPrice!,
                      image: selectedVariant.image
                        ? {
                            altText:
                              getLocalizedValue(
                                cartUpsell.datoCmsProduct._allTitleLocales
                              ) || "",
                            originalSrc: selectedVariant.image
                              .originalSrc as string,
                          }
                        : undefined,
                    },
                    quantity: 1,
                    customAttributes: [],
                    allLocaleValues: {
                      titles: cartUpsell.datoCmsProduct._allTitleLocales as any,
                      paths: getAllProductPathLocales(
                        cartUpsell.datoCmsProduct as any,
                        defaultLocale
                      ),
                    },
                  });
                }}
              >
                ADD
              </CartUpsellAddToCart>
            </CartUpsellAddToCartContainer>
          </CartUpsellDetails>
        </CartUpsellContainer>
      </CartUpsellWrapper>
    ) : null;
  };

  return (
    <Container>
      {cartLineItems.map((cartLineItem) => {
        switch (cartLineItem.type) {
          case "composable":
            return (
              <ComposableLineItem
                key={cartLineItem.base.id}
                item={cartLineItem}
                onRemove={() =>
                  onRemove(
                    [cartLineItem.base.id].concat(
                      cartLineItem.instants.map((instant) => instant.id)
                    )
                  )
                }
              />
            );
          case "default":
            return (
              <DefaultLineItem
                key={cartLineItem.item.id}
                item={cartLineItem.item}
                onChangeQuantity={(newQuantity) =>
                  onChangeQuantity(cartLineItem.item.id, newQuantity)
                }
                onRemove={() => onRemove(cartLineItem.item.id)}
              />
            );
          default:
            return assertNever(cartLineItem);
        }
      })}
      {cartUpsell && renderCartUpsell()}
    </Container>
  );
}

function assertNever(x: never) {
  console.error(`Unexpected cart line item`, x);
}

const Container = styled.div`
  overflow: scroll;
`;

const CartUpsellWrapper = styled.div`
  background: ${({ theme }) => theme.colors.background2};
  border-top: 1px solid ${({ theme }) => theme.colors.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.main};
  width: 100%;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 18px 20px 15px 20px;
  box-shadow: 0px 0px 10px 0px rgba(151, 123, 43, 0.2);
  overflow: hidden;
  position: relative;
`;

const CartUpsellTotalContainer = styled.div`
  text-align: right;
  padding-top: 10px;
`;

const CartUpsellHeading = styled.div`
  font-weight: 600;
  letter-spacing: 0.06em;
  font-size: 14px;
  line-height: 1.2em;
  padding-right: 35px;
  width: 100%;
  padding-bottom: 20px;
  color: #977b2b;
`;

const CartUpsellPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 0.08em;
`;

const CartUpsellOriginalPrice = styled.div`
  text-decoration: line-through;
  padding-right: 5px;
  font-size: 14px;
  color: #333;
  text-align: right;
`;

const CartUpsellTotal = styled.div<{ isDiscounted: boolean }>`
  font-weight: 500;
  font-size: 16px;
  color: #d24017;
  ${({ isDiscounted }) => !isDiscounted && `color: #333;`};
  font-weight: 700;
`;

const CartUpsellImageContainer = styled.div`
  width: 120px;
  @media (max-width: 500px) {
    width: 80px;
  }
`;

const CartUpsellImageLink = styled(Link)`
  display: block;
`;

const CartUpsellImage = styled.img`
  height: 100%;
  width: 100%;
  display: block;
  padding-bottom: 10px;
`;

const CartUpsellItemLink = styled(Link)`
  font-weight: 500;
  letter-spacing: 0.04em;
  text-decoration: none;
  color: #333;
  display: block;
  &:hover {
    text-decoration: underline;
  }
`;

const CartUpsellTitle = styled.div`
  font-weight: 600;
  letter-spacing: 0.06em;
  font-size: 14px;
  line-height: 1.3em;
  padding-right: 35px;
  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

const CartUpsellDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 120px);
  min-width: calc(100% - 120px);
  padding: 15px 0 0 15px;
  padding-right: 0;
  box-sizing: border-box;
  position: relative;
  flex: 1;
  @media (max-width: 500px) {
    width: calc(100% - 80px);
    padding: 5px 0 0 10px;
    min-width: calc(100% - 80px);
  }
`;

const CartUpsellContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 10;
  font-size: 14px;
  flex-wrap: wrap;
`;

const CartUpsellAddToCartContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5px;
`;

const CartUpsellAddToCart = styled.button<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isLoading }) =>
    isLoading ? "#806724" : theme.colors.main};
  color: white;
  outline: inherit;

  height: 32px;
  font-size: 14px;
  letter-spacing: 0.16em;
  font-weight: 600;
  border: none;
  text-transform: uppercase;
  font-family: soleil, sans-serif;
  transition: 0.3s all;
  padding: 0 40px;
  min-width: 160px;
  @media (max-width: 500px) {
    padding: 0 20px;
    font-size: 13px;
  }

  &:hover {
    cursor: pointer;
    background-color: #806724;
  }
  &[disabled] {
    background-color: #a3acaf;
    opacity: 0.65;
  }
`;

export default LineItems;
