import styled, { css } from "styled-components";

interface Props {
  size: "small" | "normal";
}

type ExtendableProps = Props & { [_: string]: any };

const InputTextArea = styled.input`
  width: 100%;
  height: ${(props: ExtendableProps) =>
    ({
      small: "40px",
      normal: "100px"
    }[props.size || "normal"])};
  margin: 0;
  padding: 0;
  display: block;
  -webkit-appearance: none;
  border: 1px solid #c6c6c6;
  margin-bottom: ${(props: ExtendableProps) =>
    ({
      small: "15px",
      normal: "30px"
    }[props.size || "normal"])};
  padding: 15px;
  font-family: soleil, sans-serif;
  font-size: ${(props: ExtendableProps) =>
    ({
      small: "12px",
      normal: "13px"
    }[props.size || "normal"])};
  letter-spacing: 0.04em;
  border-radius: 0;
  resize: vertical;
  ${({ hasError, theme }) =>
    hasError &&
    css`
      border-color: ${theme.colors.error};
    `}
`;

export default InputTextArea;
