import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  lang?: string;
  title?: string;
  titleSuffix?: string;
  description?: string;
  author?: string;
  twitterCard?: string;
  imageUrl?: string;
  link?: { [key: string]: string }[];
  meta?: { [key: string]: string }[];
  keywords?: string[];
  allPathLocales?: { locale: string; value: string }[];
}

const SEO: React.SFC<Props> = ({
  lang,
  title,
  titleSuffix,
  description,
  author,
  twitterCard,
  imageUrl,
  link,
  meta,
  keywords,
  allPathLocales,
  children
}) => {
  let props = {};
  let filteredMeta: { [key: string]: string }[] = [
    {
      property: `og:type`,
      content: `website`
    }
  ];
  if (lang) {
    const htmlAttributes = { lang };
    props = { ...props, htmlAttributes };
  }
  let _link: any[] = [...(link || [])];
  if (allPathLocales) {
    const defaultPathLocale = allPathLocales.find(
      pathLocale => pathLocale.locale === "it"
    );
    _link.push(
      ...allPathLocales.map(pathLocale => ({
        rel: "alternate",
        href: `https://ruedesmille.com${pathLocale.value}`,
        hreflang: pathLocale.locale
      }))
    );
    if (defaultPathLocale) {
      _link.push({
        rel: "alternate",
        href: `https://ruedesmille.com${defaultPathLocale.value}`,
        hreflang: "x-default"
      });
    }
  }
  props = { ...props, link: _link };
  if (title) {
    props = { ...props, title };
    filteredMeta = [
      ...filteredMeta,
      {
        property: `og:title`,
        content: title
      },
      {
        name: `twitter:title`,
        content: title
      }
    ];
  }
  if (titleSuffix != undefined) {
    const titleTemplate = `%s${titleSuffix}`;
    props = { ...props, titleTemplate };
  }
  if (description) {
    filteredMeta = [
      ...filteredMeta,
      {
        name: `description`,
        content: description
      },
      {
        property: `og:description`,
        content: description
      },
      {
        name: `twitter:description`,
        content: description
      }
    ];
  }
  if (author) {
    filteredMeta = [
      ...filteredMeta,
      {
        name: `twitter:creator`,
        content: author
      }
    ];
  }
  if (twitterCard) {
    filteredMeta = [
      ...filteredMeta,
      {
        name: `twitter:card`,
        content: twitterCard
      }
    ];
  }
  if (imageUrl) {
    filteredMeta = [
      ...filteredMeta,
      {
        property: `og:image`,
        content: imageUrl
      },
      {
        name: `twitter:image`,
        content: imageUrl
      }
    ];
  }

  return (
    <Helmet
      {...props}
      meta={filteredMeta
        .concat(
          keywords && keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta || [])}
    >
      {children}
    </Helmet>
  );
};

SEO.defaultProps = {
  meta: [],
  keywords: []
};

export default SEO;
