import React, { useEffect, useRef, useState, ReactElement } from "react";
import { findDOMNode } from "react-dom";
import styled, { CSSObject } from "styled-components";

interface Props {
  className?: string;
  placeholder: string | ReactElement;
  value?: any;
  options: any[];
  hasError?: boolean;
  disabled?: boolean;
  useSystemSelect?: boolean;
  display: (value: any) => any;
  compare: (value: any, option: any) => boolean;
  valueChange: (value: any) => void;
  size?: "small" | "normal";
  systemSelectStyleOverride?: CSSObject;
}

/**
 * Select component
 */
const Select: React.SFC<Props> = props => {
  // Initializers
  const initSystemSelectValue = (
    compare?: (value: any, option: any) => boolean
  ) => {
    const value = props.value || "";
    const options = props.options || [];
    const unwrappedCompare = compare ? compare : (a: any, b: any) => a === b;
    const foundElement = options.findIndex(elem =>
      unwrappedCompare(value, elem)
    );
    return String(foundElement);
  };

  const initValue = (compare?: (value: any, option: any) => boolean) => {
    const value = props.value || "";
    const options = props.options || [];
    const unwrappedCompare = compare ? compare : (a: any, b: any) => a === b;
    const foundElement = options.find(elem => unwrappedCompare(value, elem));
    return foundElement || undefined;
  };

  const initSelectedIndex = (
    compare?: (value: any, option: any) => boolean
  ) => {
    const value = props.value || "";
    const options = props.options || [];
    const unwrappedCompare = compare ? compare : (a: any, b: any) => a === b;
    const foundIndex = options.findIndex(elem => unwrappedCompare(value, elem));
    return foundIndex || 0;
  };

  // State variables
  const [value, setValue] = useState(
    props.useSystemSelect
      ? initSystemSelectValue(props.compare)
      : initValue(props.compare)
  );
  const [isOpen, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    initSelectedIndex(props.compare)
  );

  // Refs
  const containerRef = useRef(null);
  const optionsPanelRef = useRef(null);
  const selectedElementRef = useRef(null);
  const selectedIndexRef = useRef(selectedIndex);

  // Effects
  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    setValue(
      props.useSystemSelect
        ? initSystemSelectValue(props.compare)
        : initValue(props.compare)
    );
  }, [props.value]);

  useEffect(() => {
    const index = initSelectedIndex(props.compare);
    setSelectedIndex(index);
  }, [isOpen]);

  useEffect(() => {
    selectedIndexRef.current = selectedIndex;
  }, [selectedIndex]);

  useEffect(() => {
    if (optionsPanelRef && selectedElementRef) {
      const panel: any = optionsPanelRef.current;
      const element: any = findDOMNode(selectedElementRef.current);
      if (panel && element) {
        if (
          element.offsetTop - panel.offsetTop >
          panel.scrollTop + panel.offsetHeight - element.offsetHeight
        ) {
          panel.scrollTop =
            element.offsetTop -
            panel.offsetTop -
            panel.offsetHeight +
            element.offsetHeight;
        } else if (element.offsetTop - panel.offsetTop < panel.scrollTop) {
          panel.scrollTop = element.offsetTop - panel.offsetTop;
        }
      }
    }
  }, [selectedIndex, isOpen]);

  /**
   * Toggle opening
   */
  const toggleOpen = () => setOpen(!isOpen);

  const handleSystemSelectChange = (index: string) => {
    const option = props.options[Number(index)];
    setValue(index);
    props.valueChange(option);
  };

  /**
   * Handle mouse click on list element
   */
  const handleClick = (option: any) => {
    setValue(option);
    setOpen(false);
    props.valueChange(option);
  };

  /**
   * Handle mouse over list element
   */
  const handleMouseOver = (index: number) => {
    setSelectedIndex(index);
  };

  /**
   * Handle the possible keydown events
   */
  const handleKeyDown = (e: any) => {
    const key = e.key;
    e.preventDefault();
    if (key === "ArrowUp") {
      setSelectedIndex(prev => (prev > 0 ? prev - 1 : 0));
    } else if (key === "ArrowDown") {
      setSelectedIndex(prev =>
        prev < props.options.length - 1 ? prev + 1 : props.options.length - 1
      );
    } else if (key === "Enter") {
      setValue(props.options[selectedIndexRef.current]);
      setOpen(false);
      props.valueChange(props.options[selectedIndexRef.current]);
    }
  };

  /**
   * Close on click outside
   */
  const handleClickOutside = ({ target }: { target: any }) => {
    if (!(containerRef as any).current.contains(target)) {
      setOpen(false);
    }
  };

  return props.useSystemSelect ? (
    <SelectContainer
      className={props.className}
      systemSelectStyleOverride={props.systemSelectStyleOverride}
      size={props.size}
      theme={{ disabled: props.disabled }}
      value={value}
      hasError={props.hasError}
      disabled={props.disabled}
      onChange={e => handleSystemSelectChange(e.target.value)}
    >
      {props.placeholder && (
        <option style={{ display: "none" }}>{props.placeholder}</option>
      )}
      {props.options &&
        props.options.map((option, index) => (
          <Option key={index} value={index}>
            {props.display(option)}
          </Option>
        ))}
    </SelectContainer>
  ) : (
    <CustomSelectContainer className={props.className} ref={containerRef}>
      <Header
        theme={{ disabled: props.disabled }}
        onClick={props.disabled ? undefined : toggleOpen}
        onKeyDown={handleKeyDown}
      >
        <Value>{value ? props.display(value) : props.placeholder}</Value>
      </Header>
      {isOpen ? (
        <OptionsPanel ref={optionsPanelRef}>
          {props.options &&
            props.options.map((option, index) => (
              <CustomOption
                ref={index === selectedIndex ? selectedElementRef : undefined}
                theme={{ selected: index === selectedIndex }}
                key={index}
                onClick={() => handleClick(option)}
                onMouseOver={() => handleMouseOver(index)}
              >
                {props.display(option)}
              </CustomOption>
            ))}
        </OptionsPanel>
      ) : null}
    </CustomSelectContainer>
  );
};

// Native select style
const SelectContainer = styled.select<{
  hasError: boolean;
  systemSelectStyleOverride: CSSObject;
  size: "small" | "normal";
}>`
  width: 100%;
  font-family: soleil, sans-serif;
  font-size: ${props =>
    ({
      small: "12px",
      normal: "13px"
    }[props.size || "normal"])};
  display: inline-block;
  height: ${props =>
    ({
      small: "40px",
      normal: "50px"
    }[props.size || "normal"])};
  border: ${props =>
    props.hasError ? "1px solid #ee0000;" : "1px solid #c6c6c6;"}
  border-radius: 0;
  padding-right: 38px;
  padding-left: 20px;
  margin-bottom: 15px;
  background-color: ${props =>
    ({
      small: "#fff",
      normal: "rgba(0, 0, 0, 0.02)"
    }[props.size || "normal"])};
  background-image: linear-gradient(45deg, transparent 50%, #333 50%),
    linear-gradient(135deg, #333 50%, transparent 50%);
  background-position: ${props =>
    ({
      small:
        "calc(100% - 20px) calc(1em + 4px),calc(100% - 15px) calc(1em + 4px), calc(100% - 2.5em) 0.5em",
      normal:
        "calc(100% - 20px) calc(1em + 8px),calc(100% - 15px) calc(1em + 8px), calc(100% - 2.5em) 0.5em"
    }[props.size || "normal"])};
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-progress-appearance: none;
  ${({ systemSelectStyleOverride }) => systemSelectStyleOverride}
`;

const Option = styled.option``;

// Custom select style
const CustomSelectContainer = styled.div`
  margin-bottom: 15px;
`;

const Header = styled.div`
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  display: block;
  -webkit-appearance: none;
  border: 1px solid #c6c6c6;
  padding: 0 15px;
  background-color: ${props => (props.theme.disabled ? "lightgrey" : "white")};
`;

const Value = styled.div`
  height: 100%;
  font-family: soleil, sans-serif;
  font-size: 12px;
  letter-spacing: 0.04em;
  display: flex;
  align-items: center;
`;

const OptionsPanel = styled.div`
  margin-top: 2px;
  border: 1px solid lightgray;
  max-height: 200px;
  overflow: scroll;
  background-color: white;
`;

const CustomOption = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: soleil, sans-serif;
  font-size: 12px;
  letter-spacing: 0.04em;
  ${props => {
    if (props.theme.selected) {
      return `background-color: #fff9e7;`;
    }
  }}
`;

export default Select;
