import { isEqual, partition } from "lodash";
import { Cart, LineItemAllLocaleValues, LineItem } from "./types";
import { CheckoutPayload, ShopifyLineItemInput } from "./operations";
import { GiftAtCheckout } from ".";

export interface AllLocaleByVariantId {
  [id: string]: LineItemAllLocaleValues;
}

export function getAllLocaleValuesByVariantId(
  lineItems: LineItem[]
): AllLocaleByVariantId {
  return lineItems.reduce((byId, lineItem) => {
    byId[lineItem.variant!.id] = lineItem.allLocaleValues;
    return byId;
  }, {} as AllLocaleByVariantId);
}

export function getShopifyLineItemInput(
  lineItem: LineItem
): ShopifyLineItemInput {
  return {
    variantId: lineItem.variant!.id,
    quantity: lineItem.quantity,
    customAttributes: lineItem.customAttributes,
  };
}

interface CartCustomer {
  associatedUserAccessToken?: string;
  associatedAddressId?: string;
}

export function getCartFromCheckoutPayload(
  checkout: CheckoutPayload,
  allLocaleValuesByVariantId: AllLocaleByVariantId,
  customer: CartCustomer,
  locale: string
): Cart {
  const lineItems = checkout.lineItems.edges
    .filter((edge) => edge.node.variant !== null)
    .map<LineItem>((edge) => ({
      id: edge.node.id,
      state: "normal",
      quantity: edge.node.quantity,
      variant: {
        ...edge.node.variant,
        price: edge.node.variant.price.amount,
        compareAtPrice: edge.node.variant.compareAtPrice
          ? edge.node.variant.compareAtPrice.amount
          : null,
      },
      discountAllocations: edge.node.discountAllocations,
      customAttributes: edge.node.customAttributes,
      allLocaleValues: allLocaleValuesByVariantId[edge.node.variant.id],
    }))
    .filter((lineItem) => lineItem.allLocaleValues);

  const totalPrice = lineItems.reduce(
    (amount: number, item: LineItem) =>
      amount + parseFloat(item.variant!.price) * item.quantity,
    0
  );

  return {
    associatedUserAccessToken: customer
      ? customer.associatedUserAccessToken
      : undefined,
    associatedAddressId: customer ? customer.associatedAddressId : undefined,
    checkoutId: checkout.id,
    webUrl: checkout.webUrl,
    totalPrice: `${totalPrice}`,
    lineItems: lineItems,
    locale,
  };
}

export function mergeDuplicateLineItems(lineItems: LineItem[]): LineItem[] {
  const mergedItems: LineItem[] = [];
  let items = lineItems;
  while (items.length > 0) {
    const firstItem = items[0];
    const [matchingItems, remainingItems] = partition(items, (item) =>
      areSameLineItems(firstItem, item)
    );
    const mergedItem = matchingItems.reduce((partiallyMergedItem, item) => {
      partiallyMergedItem.quantity += item.quantity;
      if (item.state === "adding") {
        partiallyMergedItem.state = "changing_quantity";
      }
      return partiallyMergedItem;
    });
    mergedItems.push(mergedItem);
    items = remainingItems;
  }
  return mergedItems;
}

export function areSameLineItems(a: LineItem, b: LineItem): boolean {
  const sameVariantId = a.variant!.id === b.variant!.id;
  const bothHaveSameAttributes = isEqual(
    a.customAttributes,
    b.customAttributes
  );
  return sameVariantId && bothHaveSameAttributes;
}

export function addGiftIfDue(
  lineItems: Array<LineItem>,
  shopifyLineItems: Array<ShopifyLineItemInput>,
  giftAtCheckout?: GiftAtCheckout,
  giftVariantId?: string | null
) {
  if (
    giftAtCheckout &&
    giftAtCheckout.availableForSale &&
    giftVariantId &&
    giftAtCheckout.minimumAmountSpent
  ) {
    const amountSpent = computeTotalPriceForGift(lineItems, giftAtCheckout);
    if (amountSpent >= giftAtCheckout.minimumAmountSpent) {
      return shopifyLineItems.concat({
        variantId: giftVariantId,
        quantity: 1,
      });
    }
  }
  return shopifyLineItems;
}

export const computeTotalPriceForGift = (
  lineItems: Array<LineItem>,
  giftAtCheckout?: GiftAtCheckout
) => {
  if (giftAtCheckout) {
    let totalPrice;
    if (giftAtCheckout.variantsEnablingDiscount.length > 0) {
      totalPrice = lineItems
        .filter((l) =>
          giftAtCheckout.variantsEnablingDiscount.includes(l.variant!.id)
        )
        .filter((lineItem) => lineItem.state !== "removing")
        .reduce(
          (sum, item) => sum + parseFloat(item.variant!.price) * item.quantity,
          0
        );
    } else {
      totalPrice = lineItems
        .filter((lineItem) => lineItem.state !== "removing")
        .reduce(
          (sum, item) => sum + parseFloat(item.variant!.price) * item.quantity,
          0
        );
    }
    return totalPrice;
  }
  return 0;
};
