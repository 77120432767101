import styled from "styled-components";
import BasicLink from "./Link";

interface Props {
  size?: "slim" | "normal" | "large";
  color?: "light" | "main" | "dark";
}

const ButtonLink = styled(BasicLink)<Props>`
  display: inline-flex;
  flex: 0 0
    ${props =>
      ({
        slim: "40px",
        normal: "50px",
        large: "50px"
      }[props.size || "normal"])};
  align-items: center;
  text-align: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 20px 1px 20px;
  box-sizing: border-box;
  line-height: 1.3em;
  background-color: ${props =>
    ({
      light: "#fff",
      main: props.theme.colors.main,
      dark: "#333"
    }[props.color || "main"])};
  color: ${props =>
    ({
      light: "#333",
      main: "#fff",
      dark: "#fff"
    }[props.color || "main"])};
  outline: inherit;
  height: ${props =>
    ({
      slim: "40px",
      normal: "50px",
      large: "50px"
    }[props.size || "normal"])};
  font-size: 14px;
  letter-spacing: 0.16em;
  font-weight: 600;
  width: 100%;
  max-width: ${props =>
    ({
      slim: "320px",
      normal: "320px",
      large: "100%"
    }[props.size || "normal"])};
  border: none;
  text-transform: uppercase;
  font-family: soleil, sans-serif;
  transition: 0.3s all;
  &:hover {
    cursor: pointer;
    background-color: ${props =>
      ({
        light: "rgba(240,240,240,1)",
        main: "#806724",
        dark: "#000"
      }[props.color || "main"])};
  }
  &[disabled] {
    background-color: #a3acaf;
    opacity: 0.65;
  }
`;

export default ButtonLink;
