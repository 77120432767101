import React from "react";
import styled from "styled-components";

interface Props {
  color: "primary" | "dark" | "light";
}

type ExtendableProps = Props & { [_: string]: any };

class Logo extends React.PureComponent<ExtendableProps> {
  render() {
    const { className } = this.props;
    return (
      <svg className={className} viewBox="0 0 200 66">
        <g>
          <path
            d="M31.47,45.8c-0.96,0.48-1.96,0.74-3,0.92c4.02-2.93,7.9-6.02,11.62-9.23c3.77-3.25,8.35-7.73,10.42-13.68
		c1.48-4.26,0.93-8.93-1.56-13.13c-3.1-5.24-8.71-9.17-14.64-10.24c-12-2.17-23.67,3.79-29.71,15.2c-4.75,8.96-4.89,18.4-4.39,26.44
		l0.02,0.41c0.19,3.19,0.63,10.65,3.82,13.22c0.74,0.6,1.6,0.9,2.54,0.9c0.22,0,0.45-0.02,0.68-0.05c0.55-0.08,0.86-0.56,0.77-1.2
		c-0.11-0.72-0.7-1.44-1.48-1.33h0C6.07,54.11,5.66,54,5.27,53.71c-1.67-1.25-2.2-5.48-2.43-7.29c-0.02-0.19-0.05-0.36-0.07-0.5
		c-0.54-4-0.68-8.33-0.43-13.61C2.96,19.7,9.84,8.77,19.88,4.45c5.6-2.41,12.4-2.53,18.18-0.33c6.58,2.5,11.28,9.17,10.95,15.5
		c-0.32,6.08-5.17,11.15-10.37,15.72c-5,4.4-10.79,8.82-17.22,13.16c-0.24,0.15-0.48,0.31-0.72,0.48l-0.28,0.19l0.04,0.07
		c-0.32,0.47-0.21,1.16,0.13,1.62c0.3,0.41,0.71,0.58,1.11,0.5l0.05,0.08l0.33-0.25c0.22-0.14,0.43-0.29,0.64-0.43
		c1.5-0.89,3.17-1.1,4.93-1.32c1.59-0.2,3.24-0.41,4.76-1.17c0.69-0.34,0.76-1.23,0.43-1.88C32.53,45.78,31.98,45.55,31.47,45.8z"
          />
          <path
            d="M77.67,43.43c-3.66-1.22-7.3-0.71-10.82-0.22c-2.54,0.36-5.16,0.72-7.77,0.44c-0.22-0.02-0.45-0.06-0.68-0.11
		c1.33-1.1,2.38-2.45,2.9-3.92c0.18-0.52,0-1.01-0.48-1.3c-0.47-0.27-1.03-0.44-1.64-0.52l-0.01-0.02l-0.01,0.02
		c-1.63-0.2-3.58,0.27-4.77,1.3c-1.14,0.98-1.44,2.3-0.85,3.7c0.1,0.23,0.21,0.44,0.34,0.64c-0.93,0.41-1.87,0.65-2.72,0.65
		c-1.65,0.01-2.79-0.81-3.4-2.42l0-0.1l-0.04,0c-0.26-0.6-1.19-0.94-1.81-0.82c-0.42,0.08-0.7,0.35-0.76,0.73l-0.18-0.01l0.17,0.45
		c0.02,1.56-0.2,3.38-1.69,4.24c-0.61,0.35-0.89,0.17-0.98,0.1c-0.87-0.58-0.99-3.29-0.85-4.27c0.09-0.62-0.4-1.15-1.16-1.25
		c-0.76-0.11-1.38,0.26-1.47,0.89l0,0c-0.3,2.13,0.05,4.04,0.96,5.24c0.66,0.87,1.6,1.39,2.78,1.55c0.21,0.03,0.41,0.04,0.61,0.04
		c0.92,0,1.73-0.31,2.42-0.92c0.72-0.64,1.23-1.55,1.56-2.55c1.07,0.8,2.43,1.22,4.01,1.22c0.01,0,0.02,0,0.03,0
		c1.57-0.01,3.17-0.42,4.64-1.13c1.29,0.55,2.88,0.74,4.41,0.84c2.28,0.14,4.56-0.22,6.75-0.56c3.3-0.52,6.42-1.01,9.6,0.05
		c0.49,0.16,1.02,0.07,1.38-0.25c0.29-0.26,0.43-0.61,0.36-0.94C78.44,44.01,78.28,43.63,77.67,43.43z M56.13,41.2
		c0.25-0.59,0.79-1.07,1.44-1.29c0.24-0.08,0.49-0.12,0.73-0.12c-0.54,0.86-1.34,1.68-2.26,2.37C55.95,41.88,55.98,41.57,56.13,41.2
		z"
          />
          <path
            d="M95.39,43.28c-0.98-0.66-2.17-1.35-3.43-2.07c-0.07-0.04-0.14-0.08-0.21-0.12c0.26-2.16,0.61-4.32,0.95-6.46
		c1.39-8.74,2.83-17.77-1.33-26.15c-0.34-0.67-0.91-0.69-1.29-0.5c-0.61,0.3-1.04,1.18-0.69,1.88c4,8.06,2.6,16.95,1.24,25.55
		c-0.24,1.53-0.49,3.07-0.7,4.62c-0.46-0.28-0.93-0.56-1.39-0.85c-1.55-3.57-3.83-7.57-7.23-9.4c-0.34-0.18-0.72-0.17-1.05,0.04
		c-0.4,0.25-0.67,0.75-0.64,1.21c0.01,0.26,0.04,0.51,0.07,0.76l-0.05,0.04l0.06,0.03c0.55,4,3.55,6.78,6.99,9.1
		c0.42,0.99,0.79,1.96,1.14,2.86l0.11,0.29c0.44,1.15,0.88,2.34,1.29,3.55c-0.11,4.02,0.31,8.02,1.78,11.89l-0.04,0.35l0.15-0.06
		c0.01,0.02,0.01,0.03,0.02,0.05c0.22,0.57,0.6,0.66,0.83,0.66c0.04,0,0.07,0,0.1-0.01c0.59-0.08,1.11-0.78,1.18-1.34
		c0.01-0.09,0.02-0.19,0.03-0.28l0.01,0l-0.01-0.01c0.37-4-0.63-8.16-1.96-12.22c0.03-0.92,0.08-1.85,0.16-2.78
		c0.96,0.57,1.86,1.12,2.68,1.68c0.48,0.33,1.06,0.2,1.42-0.33C95.99,44.63,96.02,43.7,95.39,43.28z"
          />
          <path
            d="M111.26,32.11c-0.9-0.74-1.82-1.5-2.67-2.28v-0.01l0,0c-0.14-0.13-0.28-0.26-0.41-0.39
		c-0.28-0.27-0.63-0.34-0.97-0.2c-0.44,0.18-0.79,0.73-0.79,1.22c0,0.49,0.01,1,0.03,1.54c0.08,2.96,0.16,6.31-1.51,8.45
		c-0.46,0.6-0.97,0.81-1.54,0.65c-0.84-0.23-1.68-1.25-2.07-2.2c1.43-2.12,2.52-4.61,1.97-7.38c-0.08-0.39-0.39-0.88-0.82-1.02
		c-0.29-0.09-0.58-0.02-0.82,0.19c-0.2,0.18-0.39,0.38-0.57,0.61l-0.1-0.06l0.03,0.15c-1.28,1.64-2.21,4.41-2.12,6.93
		c-0.37,0.48-0.76,0.94-1.13,1.39l-0.25,0.3c-0.5,0.61-0.3,1.64,0.22,2.07c0.31,0.25,0.83,0.35,1.29-0.21
		c0.18-0.21,0.36-0.43,0.54-0.65c0.68,1.35,1.86,2.16,3.44,2.34c1.27,0.15,2.35-0.17,3.2-0.93c2.11-1.89,2.4-6.24,2.41-9.44
		c0.45,0.39,0.9,0.78,1.35,1.16c1.09,0.93,2.21,1.89,3.25,2.9c1.09,1.06,2.2,2.22,2.8,3.57c0.12,0.28,0.05,0.39,0.02,0.44
		c-0.2,0.29-0.96,0.62-3.46,0.56l-0.18,0c-0.4,0-0.72,0.19-0.93,0.53c-0.28,0.46-0.3,1.12-0.05,1.57c0.18,0.33,0.49,0.52,0.86,0.52
		c0.09,0,0.18,0,0.27,0c2.63,0,4.57-0.73,5.32-2.01c0.62-1.05,0.46-2.38-0.48-3.95C115.88,35.93,113.53,33.99,111.26,32.11z"
          />
          <path
            d="M145.6,19.27l0.16-0.23l-0.3,0.11c-0.21-0.18-0.44-0.35-0.68-0.51c-0.24-0.16-0.53-0.21-0.83-0.15
		c-0.48,0.09-0.9,0.44-1.11,0.75c-2.87,4.21-3.71,9.23-4.52,14.08c-0.42,2.52-0.85,5.09-1.57,7.55c-1.61-3.36-2.61-6.97-3.58-10.49
		c-1.45-5.23-2.94-10.63-6.42-15.22l-0.04-0.22l-0.09,0.04c-0.02-0.02-0.03-0.04-0.05-0.06c-0.31-0.4-0.88-0.48-1.47-0.22
		c-0.51,0.23-0.97,0.71-0.86,1.29c0.01,0.03,0.01,0.06,0.02,0.09l-0.08,0.03l0.11,0.14c2.16,11.55,2.11,23.48,2.06,35.03
		c-0.02,4.54-0.04,9.23,0.08,13.83c0.01,0.54,0.41,0.9,0.99,0.9c0.01,0,0.02,0,0.03,0c0.75-0.02,1.57-0.6,1.55-1.35v0
		c-0.12-4.59-0.1-9.28-0.08-13.81c0.04-9.37,0.08-18.99-1.06-28.47c1.26,2.95,2.11,6.06,2.94,9.11c1.17,4.31,2.39,8.76,4.73,12.73
		l-0.02,0.06l0.05-0.01c0.06,0.1,0.12,0.21,0.19,0.31c0.23,0.37,0.65,0.56,1.13,0.53c0.54-0.04,1.03-0.36,1.22-0.81
		c0.06-0.13,0.11-0.26,0.16-0.4l0.15-0.02l-0.09-0.14c1.24-3.17,1.82-6.63,2.38-9.98c0.71-4.21,1.43-8.54,3.48-12.28
		c1.99,2.2,2.74,6.11,3.26,8.81l0.09,0.49c0.79,4.07,1.07,7.59,0.84,10.78c-0.04,0.51,0.3,0.88,0.83,0.92
		c0.74,0.06,1.69-0.51,1.75-1.3c0.27-3.74-0.15-7.97-1.27-12.94C148.98,24.99,148,21.41,145.6,19.27z"
          />
          <path
            d="M156.46,38.75c-0.12-1.07-0.24-2.18-0.44-3.27c-0.15-0.78-0.74-1.1-1.25-1c-0.54,0.1-1.03,0.65-0.87,1.48v0
		c0.2,1.06,0.32,2.16,0.44,3.22c0.12,1.09,0.24,2.21,0.45,3.31c0.13,0.69,0.61,1.02,1.08,1.02c0.06,0,0.12-0.01,0.18-0.02
		c0.54-0.1,1.02-0.65,0.87-1.47C156.7,40.93,156.58,39.82,156.46,38.75z"
          />
          <path
            d="M199.56,39.04c-0.35-0.33-0.92-0.42-1.41,0.1c-1.46,1.52-4.6,1.48-6.1,1.46c-3.33-0.04-6.11-1.18-7.62-3.11
		c-0.06-0.08-0.12-0.18-0.17-0.3c1.59-0.99,3.52-2.8,4.3-4.65c0.43-1.02,0.45-1.93,0.08-2.7c-0.68-1.4-2.42-2.03-3.96-1.44
		c-1.61,0.62-2.24,2.58-2.56,4.43c-0.27,0.16-0.47,0.45-0.52,0.83c-0.01,0.06-0.02,0.11-0.02,0.17l-0.06,0l0.03,0.18
		c-0.35,2.34-0.77,4.61-1.77,6.73c-4.06-4.45-4.69-11.65-5.25-18.05c-0.14-1.58-0.27-3.08-0.45-4.48l0-0.32l-0.05,0.01
		c-0.14-0.63-0.6-0.88-1.03-0.86c-0.52,0.03-1.06,0.48-1.1,1.19l-0.03,0l0.02,0.18c-0.04,3.95-0.99,17.72-3.64,19.75
		c-0.25,0.2-0.5,0.25-0.77,0.19c-1.1-0.27-1.82-2.1-2.24-3.19c-0.05-0.13-0.1-0.25-0.14-0.36c-0.84-2.08-1.35-4.28-1.81-6.36
		c-0.71-3.23-1.47-6.98-1.52-10.56c-0.01-0.82-0.58-1.26-1.12-1.26c0,0-0.01,0-0.01,0c-0.53,0.01-1.06,0.44-1.05,1.24v0
		c0.06,4.31,0.97,8.72,1.95,12.85c0.05,0.23,0.11,0.48,0.17,0.75c0.73,3.23,1.96,8.63,5.4,9.48c0.76,0.19,1.47,0.08,2.1-0.32
		c2.01-1.27,2.76-5.16,3.16-7.24c0.04-0.2,0.07-0.39,0.11-0.54c0.23-1.12,0.43-2.24,0.61-3.36c0.8,5.44,2.31,10.75,6.29,14.3
		c0.21,0.19,0.42,0.24,0.59,0.24c0.05,0,0.09,0,0.13-0.01c0.32-0.05,0.63-0.28,0.84-0.63c0.83-1.36,1.4-2.77,1.81-4.21
		c1.12,1.55,2.75,2.66,4.98,3.4c3.07,1.02,9.03,1.5,11.95-1.54C200.2,40.45,200.04,39.49,199.56,39.04z M184.97,33.35
		c-0.35,0.38-0.68,0.71-1.01,0.99c0.07-0.75,0.23-1.53,0.5-2.19c0.24-0.58,0.65-1.28,1.29-1.37c0.12-0.02,0.23-0.02,0.32-0.02
		c0.21,0,0.32,0.04,0.34,0.08C186.58,31.16,185.76,32.47,184.97,33.35z"
          />
          <path
            d="M155.74,27.07c0.13,0.13,0.3,0.19,0.46,0.19c0.17,0,0.34-0.07,0.47-0.2c0.27-0.26,0.27-0.66,0.01-0.92
		c-0.25-0.24-0.49-0.49-0.74-0.73c-0.27-0.26-0.67-0.26-0.93,0.01c-0.27,0.26-0.27,0.66,0,0.92
		C155.25,26.58,155.49,26.82,155.74,27.07z"
          />
        </g>
      </svg>
    );
  }
}

export default styled(Logo)`
  width: 200px;
  height: 54px;
  display: block;
  fill: ${(props: ExtendableProps) =>
    ({
      primary: "#977B2B",
      dark: "#333",
      light: "#fff"
    }[props.size || "primary"])};
`;
