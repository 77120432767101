import { ShopifyMedia, ShopifyMediaImage, ShopifyVideo } from "graphql-typings";

export interface ImageSize {
  width: number;
  height: number;
}

export function resizeImage(
  originalUrl: string,
  size: Partial<ImageSize>
): string {
  let shopifySizeFormat;
  if (size.width && size.height) {
    shopifySizeFormat = `_${size.width}x${size.height}`;
  } else if (size.width) {
    shopifySizeFormat = `_${size.width}x`;
  } else if (size.height) {
    shopifySizeFormat = `_x${size.height}`;
  }
  return shopifySizeFormat
    ? originalUrl.replace(/\.(png|jpg|svg|gif)/, `${shopifySizeFormat}$&`)
    : originalUrl;
}

export function mapMediaToImages(media?: ShopifyMedia[]) {
  const mediaImages = media?.filter(
    (media) => media.mediaContentType === "IMAGE"
  ) as ShopifyMediaImage[];
  return (
    mediaImages?.map((mediaImage) => ({
      id: mediaImage.id,
      shopifyId: mediaImage.shopifyId,
      ...mediaImage.image,
    })) ?? []
  );
}

export function mapMediaToVideos(media?: ShopifyMedia[]) {
  return (media?.filter((media) => media.mediaContentType === "VIDEO") ??
    []) as ShopifyVideo[];
}

export function getFirstMediaImage(media?: ShopifyMedia[]) {
  const mediaImages = mapMediaToImages(media);
  return mediaImages[0] ?? undefined;
}
