import { useLocale } from "hooks/useLocale";
import React, { useState, useEffect, useContext, useMemo } from "react";

interface GraphQLContext {
  request: <Response, Variables>(
    query: string,
    variables?: Variables
  ) => Promise<Response>;
}

const GraphQLContext = React.createContext<GraphQLContext | null>(null);

interface GraphQLProviderProps {
  url?: string;
  headers?: { [name: string]: string };
}

export function GraphQLProvider({
  children,
  url = "/graphql",
  headers,
}: React.PropsWithChildren<GraphQLProviderProps>) {
  const { locale } = useLocale();
  const request = async <Response, Variables>(
    query: string,
    variables?: Variables
  ): Promise<Response> => {
    const body = JSON.stringify({
      query,
      variables: variables ? variables : undefined,
    });
    const response = await fetch(url, {
      method: "POST",
      headers: Object.assign(
        { "Content-Type": "application/json", "Accept-Language": locale },
        headers
      ),
      body,
    });
    const json = await response.json();
    if (response.ok && !json.errors && json.data) {
      return json.data;
    } else {
      console.error(json);
      throw new Error(typeof json === "string" ? json : undefined);
    }
  };
  const value = useMemo(
    () => ({
      request,
    }),
    [url, headers, locale]
  );
  return (
    <GraphQLContext.Provider value={value}>{children}</GraphQLContext.Provider>
  );
}

export interface OperationResult<Data> {
  data?: Data;
  error?: Error;
}

export interface QueryOptions<QueryVariables> {
  variables?: QueryVariables;
  autoExecution: boolean;
}

export function useQuery<QueryData, QueryVariables>(
  query: string,
  options?: QueryOptions<QueryVariables>
): [
  { data: QueryData | undefined; loading: boolean; error?: string },
  (variables?: QueryVariables) => Promise<OperationResult<QueryData>>
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [data, setData] = useState<QueryData | undefined>();
  const value = useContext(GraphQLContext);
  if (!value) {
    throw new Error(`useQuery must be used inside a GraphQLProvider`);
  }
  const execute = async (
    variables?: QueryVariables
  ): Promise<OperationResult<QueryData>> => {
    setLoading(true);
    try {
      const data = await value.request<QueryData, QueryVariables>(
        query,
        variables || (options ? options.variables : undefined)
      );
      setData(data);
      setLoading(false);
      setError(undefined);
      return {
        data,
      };
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return {
        error,
      };
    }
  };

  const internalExec = async () => {
    try {
      await execute(options ? options.variables : undefined);
    } catch (error) {}
  };
  useEffect(() => {
    if (!options || options.autoExecution !== false) {
      internalExec();
    }
  }, [query, options]);
  return [{ data, loading, error }, execute];
}

export interface MutationOptions<MutationVariables> {
  variables?: MutationVariables;
}

export function useMutation<MutationData, MutationVariables>(
  query: string,
  options?: MutationOptions<MutationVariables>
): [
  { data: MutationData | undefined; loading: boolean; error?: string },
  (variables?: MutationVariables) => Promise<OperationResult<MutationData>>
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [data, setData] = useState<MutationData>();
  const value = useContext(GraphQLContext);
  if (!value) {
    throw new Error(`useMutation must be used inside a GraphQLProvider`);
  }
  const execute = async (
    variables?: MutationVariables
  ): Promise<OperationResult<MutationData>> => {
    setLoading(true);
    try {
      const data = await value.request<MutationData, MutationVariables>(
        query,
        variables || (options ? options.variables : undefined)
      );
      setData(data);
      setLoading(false);
      setError(undefined);
      return { data };
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return { error };
    }
  };
  return [{ data, loading, error }, execute];
}
