import React from "react";
import { Client, createClient, fetchExchange, Provider } from "urql";
import { cacheExchange } from "utils/exchange/cacheExchange";
import { switchMapExchange } from "utils/exchange/switchMapExchange";

const urqlClient: Client = createClient({
  url: "/shopify/",
  fetchOptions: {
    headers: {
      "X-Shopify-Storefront-Access-Token": `${
        process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN
      }`
    }
  },
  exchanges: [switchMapExchange, cacheExchange, fetchExchange]
});

const UrqlProvider: React.SFC = ({ children }) => {
  return <Provider value={urqlClient}>{children}</Provider>;
};

export { UrqlProvider };
