import localForage from "localforage";
import { User } from "hooks/useUser/types";

const objectStore = localForage.createInstance({
  name: "rue-des-mille",
  storeName: "user"
});

export const getPersistedUser = (): Promise<User | null> =>
  objectStore.getItem("user");

export const setPersistedUser = (user: User): Promise<User> =>
  objectStore.setItem("user", user);

export const deletePersistedUser = (): Promise<void> => objectStore.clear();
