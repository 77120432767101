import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from "react";
import { setPersistedLocale } from "utils/persistence/locale";

interface LocaleContext {
  locale: string;
  defaultLocale: string;
  setLocale: (locale: string) => void;
}

export interface FieldLocale<T = any> {
  locale: string;
  value: T;
}

const LocaleContext = React.createContext<LocaleContext | null>(null);

interface LocaleProviderProps {
  initialLocale: string;
  defaultLocale: string;
}

export function LocaleProvider({
  children,
  initialLocale,
  defaultLocale
}: React.PropsWithChildren<LocaleProviderProps>) {
  const [locale, setLocale] = useState(initialLocale);

  useEffect(() => {
    setPersistedLocale(value.locale);
  }, [locale]);

  const value = useMemo(
    () => ({
      locale,
      defaultLocale,
      setLocale
    }),
    [locale, defaultLocale]
  );
  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
}

type GetLocaleValueFn = <T = any>(
  allValueLocales: FieldLocale<T>[]
) => T | null;

export function useLocale() {
  const value = useContext(LocaleContext);
  if (!value) {
    throw new Error(`useLocale must be used inside a LocaleProvider`);
  }

  const getLocalizedValue = useCallback<GetLocaleValueFn>(
    allValueLocales => {
      const valueLocale = allValueLocales.find(
        fieldLocale => fieldLocale.locale === value.locale
      );
      return valueLocale ? valueLocale.value : null;
    },
    [value.locale]
  );

  return useMemo(
    () => ({
      ...value,
      getLocalizedValue
    }),
    [value]
  );
}

export function getLocalizedValue<T = any>(
  allValueLocales: FieldLocale<T>[],
  locale: string
) {
  const valueLocale = allValueLocales.find(
    fieldLocale => fieldLocale.locale === locale
  );
  return valueLocale ? valueLocale.value : null;
}

export function useLocalizedValue<T>(
  allValueLocales: FieldLocale<T>[]
): T | null {
  const value = useContext(LocaleContext);
  if (!value) {
    throw new Error(`useLocalizedValue must be used inside a LocaleProvider`);
  }

  return getLocalizedValue(allValueLocales, value.locale);
}
