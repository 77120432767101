import React from "react";
import styled, { css } from "styled-components";

interface Props {
  quantity: number;
  isChangingQuantity: boolean;
  onChangeQuantity: (quantity: number) => void;
  className?: string;
}

/**
 * Add and remove quantities for each cart line items
 */
function QuantityInput({
  quantity,
  onChangeQuantity,
  isChangingQuantity,
  className
}: Props) {
  return (
    <ChangeQuantityContainer className={className}>
      <DecrementQuantity
        disabled={quantity <= 1 || isChangingQuantity}
        isDisabled={quantity <= 1 || isChangingQuantity}
        onClick={() => {
          if (quantity > 1) {
            onChangeQuantity(quantity - 1);
          }
        }}
      >
        -
      </DecrementQuantity>
      <Quantity>{quantity}</Quantity>
      <IncrementQuantity
        disabled={isChangingQuantity}
        isDisabled={isChangingQuantity}
        onClick={() => {
          onChangeQuantity(quantity + 1);
        }}
      >
        +
      </IncrementQuantity>
    </ChangeQuantityContainer>
  );
}

const ChangeQuantityContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Quantity = styled.span`
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 24px;
  box-sizing: border-box;
`;

interface ChangeQuantityProps {
  isDisabled: boolean;
}

const changeQuantityButton = css<ChangeQuantityProps>`
  height: 24px;
  width: 24px;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #dedede;
      &:hover {
        cursor: default;
      }
    `};
`;

const IncrementQuantity = styled.button`
  ${changeQuantityButton};
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
`;

const DecrementQuantity = styled.button`
  ${changeQuantityButton};
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
`;

export default QuantityInput;
