import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import {
	getNewsletterPopup,
	setNewsletterPopup,
	setNewsletterPopupToFalse,
} from "utils/persistence/newsletter";
import Cross from "./icons/Cross";
import NewsletterForm from "./NewsletterForm";
import { useLocale, FieldLocale } from "hooks/useLocale";
import ButtonLink from "./ButtonLink";
import { FormattedMessage } from "react-intl";

const ModalNewsletter = () => {
	const [showNewsletter, setShowNewsletter] = useState(false);
	const { datoCmsNewsletter: popUpQuery } = useStaticQuery(staticQuery);
	const { locale, defaultLocale } = useLocale();

	useEffect(() => {
		if (showNewsletter) {
			document.body.classList.add("no-scroll");
		} else {
			document.body.classList.remove("no-scroll");
		}
		return () => {
			document.body.classList.remove("no-scroll");
		};
	}, [showNewsletter]);

	function isExcludedFromPage() {
		const excludedPageHandles: string[] = popUpQuery.excludedPages.reduce(
			(acc: string[], p: any) => {
				const handleLocale = p._allHandleLocales.find(
					(l: FieldLocale<string>) => l.locale === locale,
				);
				return handleLocale ? [...acc, handleLocale.value] : acc;
			},
			[],
		);

		return Boolean(
			excludedPageHandles.find(
				(h) =>
					ensureTrailingSlash(location.pathname) ===
					ensureTrailingSlash(
						`${locale === defaultLocale ? `` : `/${locale}`}/${h}`,
					),
			),
		);
	}

	function ensureTrailingSlash(url: string) {
		return url.endsWith("/") || url.includes("?") ? url : url + "/";
	}

	function showPopup() {
		setTimeout(() => {
			setShowNewsletter(true);
		}, popUpQuery.popupShowTime * 1000);
	}

	function showPopupAndResetCookie(now: number, expirationDelta: number) {
		showPopup();
		setNewsletterPopup({
			id: popUpQuery.cookiePopup,
			expiration: now + expirationDelta,
			show: true,
		});
	}

	useEffect(() => {
		const now = Math.floor(Date.now() / 1000);
		if (!popUpQuery.popupVisibility) {
			return;
		}
		if (isExcludedFromPage()) {
			return;
		}
		getNewsletterPopup().then((popupCookie) => {
			const expirationDelta = popUpQuery.popupCookieExpiration * 24 * 60 * 60;
			if (!popupCookie) {
				showPopupAndResetCookie(now, expirationDelta);
				return;
			}
			if (now - popupCookie.expiration > 0) {
				showPopupAndResetCookie(now, expirationDelta);
				return;
			}
			if (popUpQuery.cookiePopup !== popupCookie.id) {
				showPopupAndResetCookie(now, expirationDelta);
				return;
			}
			if (popupCookie.show) {
				showPopupAndResetCookie(now, expirationDelta);
				return;
			}
		});
	}, []);

	const handleClose = () => {
		setShowNewsletter(false);
		setNewsletterPopupToFalse();
	};

	const ctaPageHandle: string = popUpQuery.popupCtaLink._allHandleLocales.find(
		(l: FieldLocale<string>) => l.locale === locale,
	)?.value;

	return showNewsletter ? (
		<ModalMask onClick={handleClose}>
			<ModalContainer onClick={(e) => e.stopPropagation()}>
				<CloseIcon onClick={handleClose} />
				<Content>
					{popUpQuery.popupImage && (
						<ImageContainer backgroundImage={popUpQuery.popupImage.url} />
					)}
					<FormContainer>
						<ModalTitle>
							{
								popUpQuery._allPopupTitleLocales.find(
									(l: FieldLocale<string>) => l.locale === locale,
								).value
							}
						</ModalTitle>
						<ModalText>
							{
								popUpQuery._allPopupTextLocales.find(
									(l: FieldLocale<string>) => l.locale === locale,
								).value
							}
						</ModalText>
						<ButtonLink
							to={`${locale === defaultLocale ? "/" : `/${locale}/`}${ctaPageHandle}`}
              onClick={handleClose}
						>
							<FormattedMessage id="label.signup" />
						</ButtonLink>
					</FormContainer>
				</Content>
			</ModalContainer>
		</ModalMask>
	) : null;
};

const staticQuery = graphql`
  {
    datoCmsNewsletter {
      popupVisibility
      popupCookieExpiration
      cookiePopup
      _allPopupTitleLocales {
        locale
        value
      }
      _allPopupTextLocales {
        locale
        value
      }
      popupImage {
        url
      }
      popupShowTime
      excludedPages {
        _allHandleLocales {
          locale
          value
        }
      }
      popupCtaLink {
        _allHandleLocales {
          locale
          value
        }
      }
    }
  }
`;

const arriveModal = keyframes`
  0% {
      opacity: 0;

  }
  100% {
      opacity: 1;

  }
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 1001;
  width: 700px;
  background-color: #fff;
  color: $baseTextColor;
  box-sizing: border-box;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  animation: ${arriveModal} 400ms ease-in-out 0s forwards;
  overflow-x: hidden;
  max-height: 85%;
  top: 5%;
  transform: translate3d(0, 5%, 0);
  overflow-y: auto;
  @media (max-width: 750px) {
    width: 90%;
  }
`;

const ModalMask = styled.div`
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
`;

const CloseIcon = styled(Cross)`
  position: fixed;
  top: 10px;
  right: 10px;
  &:hover {
    cursor: pointer;
    color: #bdbdbd;
  }
`;

const Content = styled.div`
  display: flex;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  padding: 60px 40px;
  @media (max-width: 750px) {
    padding: 30px 30px;
  }
`;

const ImageContainer = styled.div`
  width: 300px;
  flex: 0 0 300px;
  background-image: ${({ backgroundImage }: { backgroundImage: string }) =>
		`url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  @media (max-width: 750px) {
    width: 100%;
    flex: 0 0 150px;
  }
`;

const ModalTitle = styled.div`
  text-align: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
`;

const ModalText = styled.div`
  text-align: center;
  font-size: 13px;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  margin: 0;
  padding-bottom: 15px;
`;

export default ModalNewsletter;
