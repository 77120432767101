import { ShopifyMedia } from "graphql-typings";
import { useMutation, useQuery } from "hooks/useGraphQL";
import { FieldLocale, useLocale } from "hooks/useLocale";
import { User, useUser } from "hooks/useUser";
import localForage from "localforage";
import { partition, uniqueId } from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  addNoteMutation,
  AddNoteResult,
  AddNoteVariables,
  checkoutCustomerAssociateV2,
  CheckoutCustomerAssociateV2Result,
  CheckoutCustomerAssociateV2Variables,
  checkoutCustomerDisassociateV2,
  CheckoutCustomerDisassociateV2Result,
  CheckoutCustomerDisassociateV2Variables,
  CheckoutPayload,
  checkoutQuery,
  checkoutShippingAddressUpdateV2,
  CheckoutShippingAddressUpdateV2Result,
  CheckoutShippingAddressUpdateV2Variables,
  createCartMutation,
  CreateCartResult,
  CreateCartVariables,
  GetCheckoutResult,
  GetCheckoutVariables,
  replaceItemsMutation,
  ReplaceItemsResult,
  ReplaceItemsVariables,
} from "./operations";
import {
  Cart,
  CartWithMetadata,
  ICartContext,
  LineItem,
  LineItemInput,
} from "./types";
import {
  addGiftIfDue,
  computeTotalPriceForGift,
  getAllLocaleValuesByVariantId,
  getCartFromCheckoutPayload,
  getShopifyLineItemInput,
  mergeDuplicateLineItems,
} from "./utils";

export {
  Attribute,
  AttributeInput,
  Cart,
  LineItem,
  LineItemInput,
  ShopifyVariantSelectedOption,
} from "./types";

const CartContext = createContext<ICartContext | null>(null);
let objectStore: LocalForage;

export interface GiftAtCheckout {
  shopifyId: string;
  visibility: boolean;
  availableForSale: boolean;
  variants: Array<{
    storefrontId: string;
    availableForSale: boolean;
    image: {
      id: string;
      originalSrc: string;
    } | null;
    selectedOptions: Array<{ name: string; value: string }>;
  }>;
  minimumAmountSpent: number;
  collectionsForPrice: Array<string>;
  variantsEnablingDiscount: Array<string>;
  imageSrc: string;
  allGiftNotEarnedTextNodeLocale: Array<
    FieldLocale<{
      childMarkdownRemark: { html: string };
    }>
  >;
  allGiftEarnedTextNodeLocale: Array<
    FieldLocale<{
      childMarkdownRemark: { html: string };
    }>
  >;
  hideBar?: boolean;
}

export interface CartUpsell {
  _allTitleLocales: FieldLocale<string>[];
  visibility: boolean;
  datoCmsProduct: {
    shopifyId: string;
    category: {
      shopifyHandle: string;
      _allHandleLocales: FieldLocale<string>[];
    };
    _allHandleLocales: FieldLocale<string>[];
    _allTitleLocales: FieldLocale<string>[];
  };
  shopifyProduct: {
    availableForSale: boolean;
    media: ShopifyMedia[];
    variants: {
      storefrontId: string;
      price: string;
      compareAtPrice: string;
      availableForSale: boolean;
      image: {
        id: string;
        originalSrc: string;
      };
      selectedOptions: {
        name: string;
        value: string;
      }[];
    }[];
  };
}

export function CartProvider({
  children,
  giftAtCheckout,
  splitShippingIds,
  cartUpsell,
}: React.PropsWithChildren<{
  giftAtCheckout?: GiftAtCheckout;
  splitShippingIds: Array<string>;
  cartUpsell?: CartUpsell;
}>) {
  const giftAtCheckoutVariantInitializer = () => {
    if (giftAtCheckout) {
      const availableVariant = giftAtCheckout.variants.find(
        (v) => v.availableForSale
      );
      if (availableVariant) {
        return availableVariant.storefrontId;
      }
    }
    return undefined;
  };

  const { locale } = useLocale();
  const { user } = useUser();
  const [cart, setCart] = useState<Cart>();
  const [selectedGiftVariantId, setSelectedGiftVariantId] = useState(
    giftAtCheckoutVariantInitializer()
  );
  const [totalPriceForGiftAttribution, setTotalPriceForGiftAttribution] =
    useState(0);
  const [loading, setLoading] = useState(false);
  const [sideCartVisible, setSideCartVisible] = useState(false);
  const [, getCheckout] = useQuery<GetCheckoutResult, GetCheckoutVariables>(
    checkoutQuery,
    {
      autoExecution: false,
    }
  );
  const [, createCartMutate] = useMutation<
    CreateCartResult,
    CreateCartVariables
  >(createCartMutation);
  const [, replaceItemsMutate] = useMutation<
    ReplaceItemsResult,
    ReplaceItemsVariables
  >(replaceItemsMutation);
  const [, customerAssociateMutate] = useMutation<
    CheckoutCustomerAssociateV2Result,
    CheckoutCustomerAssociateV2Variables
  >(checkoutCustomerAssociateV2);
  const [, customerDisassociateMutate] = useMutation<
    CheckoutCustomerDisassociateV2Result,
    CheckoutCustomerDisassociateV2Variables
  >(checkoutCustomerDisassociateV2);
  const [, shippingAddressUpdateMutate] = useMutation<
    CheckoutShippingAddressUpdateV2Result,
    CheckoutShippingAddressUpdateV2Variables
  >(checkoutShippingAddressUpdateV2);
  const [, addNoteMutate] = useMutation<AddNoteResult, AddNoteVariables>(
    addNoteMutation
  );

  useEffect(() => {
    if (
      user &&
      user.accessToken &&
      cart &&
      (!cart.associatedUserAccessToken ||
        (user.defaultAddressId && !cart.associatedAddressId))
    ) {
      associateCustomer(cart, user);
    } else if (!user && cart && cart.associatedUserAccessToken) {
      disassociateCustomer(cart);
    }
  }, [user, cart]);

  useEffect(() => {
    if (cart?.locale && locale && cart.locale !== locale) {
      changeCartLanguage();
    }
  }, [locale]);

  const updateGiftFromCheckout = (checkout: CheckoutPayload) => {
    if (giftAtCheckout && giftAtCheckout.availableForSale) {
      const selectedGiftVariant = checkout.lineItems.edges
        .filter((edge) => edge.node.variant !== null)
        .find((e) =>
          giftAtCheckout.variants.find((v) => v.shopifyId === e.node.variant.id)
        );
      if (selectedGiftVariant) {
        setSelectedGiftVariantId(selectedGiftVariant.node.variant.id);
      }
    }
  };

  const associateCustomer = async (cart: Cart, user: User) => {
    await customerAssociateMutate({
      checkoutId: cart.checkoutId,
      customerAccessToken: user.accessToken,
    });
    let updatedCart = {
      ...cart,
      associatedUserAccessToken: user.accessToken,
    };
    if (user.defaultAddressId) {
      const defaultAddress = user.addresses.find(
        (address) => address.id === user.defaultAddressId
      );
      if (defaultAddress) {
        const { id, formatted, ...mailingAddress } = defaultAddress;
        await shippingAddressUpdateMutate({
          checkoutId: cart.checkoutId,
          shippingAddress: mailingAddress,
        });
        updatedCart = {
          ...cart,
          associatedUserAccessToken: user.accessToken,
          associatedAddressId: id,
        };
      }
    }
    setCart(updatedCart);
    await persistCart(updatedCart);
  };

  const disassociateCustomer = async (cart: Cart) => {
    await customerDisassociateMutate({
      checkoutId: cart.checkoutId,
    });
    const updatedCart = {
      ...cart,
      associatedUserAccessToken: undefined,
      associatedAddressId: undefined,
    };
    setCart(updatedCart);
    await persistCart(updatedCart);
  };

  const addToCart = async (input: LineItemInput | LineItemInput[]) => {
    if (!cart) {
      console.error("cart not ready");
      return;
    }
    const inputArray = Array.isArray(input) ? input : [input];
    const inputLineItems = inputArray.map(
      (item): LineItem => ({
        id: uniqueId(),
        state: "adding",
        quantity: item.quantity,
        variant: item.variant,
        allLocaleValues: item.allLocaleValues,
        customAttributes: item.customAttributes || [],
      })
    );
    const updatedLineItems = mergeDuplicateLineItems(
      cart.lineItems.concat(inputLineItems)
    );
    // Create a map of title locales by variant id
    const allLocaleValuesByVariantId =
      getAllLocaleValuesByVariantId(updatedLineItems);

    let updatedCart: Cart = {
      ...cart,
      lineItems: updatedLineItems,
    };
    setCart(updatedCart);
    setTotalPriceForGiftAttribution(
      computeTotalPriceForGift(updatedCart.lineItems, giftAtCheckout)
    );

    // Perform mutation
    const { data } = await replaceItemsMutate({
      checkoutId: cart.checkoutId,
      lineItems: addGiftIfDue(
        updatedLineItems,
        updatedLineItems.map(getShopifyLineItemInput),
        giftAtCheckout,
        selectedGiftVariantId
      ),
    });
    const { checkout } = data!.checkoutLineItemsReplace;
    updatedCart = getCartFromCheckoutPayload(
      checkout,
      allLocaleValuesByVariantId,
      {
        associatedAddressId: cart.associatedAddressId,
        associatedUserAccessToken: cart.associatedUserAccessToken,
      },
      locale
    );
    updateGiftFromCheckout(checkout);
    setCart(updatedCart);
    setTotalPriceForGiftAttribution(
      computeTotalPriceForGift(updatedCart.lineItems, giftAtCheckout)
    );
    await persistCart(updatedCart);
    setSideCartVisible(true);
  };

  const addNote = async (note: string) => {
    if (!cart) {
      console.error("cart not ready");
      return;
    }
    await addNoteMutate({
      checkoutId: cart.checkoutId,
      input: { note: note },
    });
  };

  const removeLineItem = useCallback(
    async (lineItemIds: string | string[]) => {
      if (!cart) {
        throw new Error("Cart is not defined");
      }
      const idsToBeRemoved = Array.isArray(lineItemIds)
        ? lineItemIds
        : [lineItemIds];

      const [removedItems, keepItems] = partition(cart.lineItems, (lineItem) =>
        idsToBeRemoved.includes(lineItem.id)
      );

      const allLocaleValuesByVariantId = getAllLocaleValuesByVariantId(
        cart.lineItems
      );

      const lineItemsCopy = keepItems.concat(
        removedItems.map<LineItem>((lineItem) => ({
          ...lineItem,
          state: "removing",
        }))
      );
      let updatedCart: Cart = {
        ...cart,
        lineItems: lineItemsCopy,
      };
      setCart(updatedCart);
      setTotalPriceForGiftAttribution(
        computeTotalPriceForGift(updatedCart.lineItems, giftAtCheckout)
      );
      // Remove line item
      const { data } = await replaceItemsMutate({
        checkoutId: cart.checkoutId,
        lineItems: addGiftIfDue(
          lineItemsCopy.filter((lineItem) => lineItem.state !== "removing"),
          lineItemsCopy
            .filter((lineItem) => lineItem.state !== "removing")
            .map(getShopifyLineItemInput),
          giftAtCheckout,
          selectedGiftVariantId
        ),
      });
      const { checkout } = data!.checkoutLineItemsReplace;
      updatedCart = getCartFromCheckoutPayload(
        checkout,
        allLocaleValuesByVariantId,
        {
          associatedAddressId: cart.associatedAddressId,
          associatedUserAccessToken: cart.associatedUserAccessToken,
        },
        locale
      );
      updateGiftFromCheckout(checkout);
      setCart(updatedCart);
      setTotalPriceForGiftAttribution(
        computeTotalPriceForGift(updatedCart.lineItems, giftAtCheckout)
      );
      await persistCart(updatedCart);
    },
    [cart]
  );

  const changeQuantity = useCallback(
    async (lineItemId: string, quantity: number) => {
      if (quantity < 1) {
        await removeLineItem(lineItemId);
        return;
      }
      if (!cart) {
        throw new Error("Cart is not defined");
      }
      const updatedItemIndex = cart.lineItems.findIndex(
        (lineItem) => lineItem.id === lineItemId
      );
      if (updatedItemIndex < 0) {
        throw new Error(`No line item with id ${lineItemId} found`);
      }
      const allLocaleValuesByVariantId = getAllLocaleValuesByVariantId(
        cart.lineItems
      );

      // Update line item state
      const lineItemsCopy = cart.lineItems.slice();
      lineItemsCopy[updatedItemIndex] = {
        ...lineItemsCopy[updatedItemIndex],
        quantity,
        state: "changing_quantity",
      };
      let updatedCart: Cart = {
        ...cart,
        lineItems: lineItemsCopy,
      };
      setCart(updatedCart);
      setTotalPriceForGiftAttribution(
        computeTotalPriceForGift(updatedCart.lineItems, giftAtCheckout)
      );
      // Update line item
      const { data } = await replaceItemsMutate({
        checkoutId: cart.checkoutId,
        lineItems: addGiftIfDue(
          lineItemsCopy,
          lineItemsCopy.map(getShopifyLineItemInput),
          giftAtCheckout,
          selectedGiftVariantId
        ),
      });
      const { checkout } = data!.checkoutLineItemsReplace;
      updatedCart = getCartFromCheckoutPayload(
        checkout,
        allLocaleValuesByVariantId,
        {
          associatedAddressId: cart.associatedAddressId,
          associatedUserAccessToken: cart.associatedUserAccessToken,
        },
        locale
      );
      updateGiftFromCheckout(checkout);
      setCart(updatedCart);
      setTotalPriceForGiftAttribution(
        computeTotalPriceForGift(updatedCart.lineItems, giftAtCheckout)
      );
      await persistCart(updatedCart);
    },
    [cart]
  );

  const changeGiftVariant = useCallback(
    async (variantId: string) => {
      if (!cart) {
        throw new Error("Cart is not defined");
      }

      setSelectedGiftVariantId(variantId);

      const allLocaleValuesByVariantId = getAllLocaleValuesByVariantId(
        cart.lineItems
      );

      const lineItemsCopy = cart.lineItems.slice();

      const { data } = await replaceItemsMutate({
        checkoutId: cart.checkoutId,
        lineItems: addGiftIfDue(
          lineItemsCopy,
          lineItemsCopy.map(getShopifyLineItemInput),
          giftAtCheckout,
          variantId
        ),
      });
      const { checkout } = data!.checkoutLineItemsReplace;
      const updatedCart = getCartFromCheckoutPayload(
        checkout,
        allLocaleValuesByVariantId,
        {
          associatedAddressId: cart.associatedAddressId,
          associatedUserAccessToken: cart.associatedUserAccessToken,
        },
        locale
      );
      updateGiftFromCheckout(checkout);
      setCart(updatedCart);
      setTotalPriceForGiftAttribution(
        computeTotalPriceForGift(updatedCart.lineItems, giftAtCheckout)
      );
      await persistCart(updatedCart);
    },
    [cart]
  );

  const createNewCart = useCallback(async (): Promise<Cart> => {
    const { data } = await createCartMutate({ input: {} });
    const { checkout } = data!.checkoutCreate;
    return getCartFromCheckoutPayload(checkout, {}, {}, locale);
  }, []);

  const changeCartLanguage = async () => {
    if (!cart) {
      console.error("cart not ready");
      return;
    }

    const lineItems = cart.lineItems;
    // Create a map of title locales by variant id
    const allLocaleValuesByVariantId = getAllLocaleValuesByVariantId(lineItems);

    let updatedCart: Cart = {
      ...cart,
      lineItems,
    };
    setCart(updatedCart);
    setTotalPriceForGiftAttribution(
      computeTotalPriceForGift(updatedCart.lineItems, giftAtCheckout)
    );

    // Perform mutation
    const { data } = await createCartMutate({
      input: {
        lineItems: addGiftIfDue(
          lineItems,
          lineItems.map(getShopifyLineItemInput),
          giftAtCheckout,
          selectedGiftVariantId
        ),
      },
    });
    const { checkout } = data!.checkoutCreate;
    updatedCart = getCartFromCheckoutPayload(
      checkout,
      allLocaleValuesByVariantId,
      {
        associatedAddressId: cart.associatedAddressId,
        associatedUserAccessToken: cart.associatedUserAccessToken,
      },
      locale
    );
    updateGiftFromCheckout(checkout);
    setCart(updatedCart);
    setTotalPriceForGiftAttribution(
      computeTotalPriceForGift(updatedCart.lineItems, giftAtCheckout)
    );
    await persistCart(updatedCart);
  };

  const resetCart = useCallback(async () => {
    setLoading(true);
    await resetCartStore();
    const cart = await createNewCart();
    setCart(cart);
    await persistCart(cart);
    setLoading(false);
  }, []);
  const showSideCart = useCallback(() => {
    setSideCartVisible(true);
  }, []);
  const hideSideCart = useCallback(() => {
    setSideCartVisible(false);
  }, []);

  useEffect(() => {
    getCart();
  }, []);

  const getCart = async () => {
    setLoading(true);
    const persistedCart = await getPersistedCart();
    if (persistedCart) {
      const { data } = await getCheckout({
        checkoutId: persistedCart.checkoutId,
      });
      const cartAge =
        persistedCart.lastUpdatedAt &&
        new Date().getTime() - persistedCart.lastUpdatedAt.getTime();
      if (
        data &&
        data.checkout &&
        !data.checkout.completedAt &&
        cartAge &&
        cartAge < 432000000
      ) {
        const allLocaleValuesByVariantId = getAllLocaleValuesByVariantId(
          persistedCart.lineItems
        );
        const updatedCart = getCartFromCheckoutPayload(
          data.checkout,
          allLocaleValuesByVariantId,
          {
            associatedAddressId: persistedCart.associatedAddressId,
            associatedUserAccessToken: persistedCart.associatedUserAccessToken,
          },
          locale
        );
        updateGiftFromCheckout(data.checkout);
        setCart(updatedCart);
        setTotalPriceForGiftAttribution(
          computeTotalPriceForGift(updatedCart.lineItems, giftAtCheckout)
        );
        await persistCart(updatedCart);
        setLoading(false);
        return;
      }
    }
    const cart = await createNewCart();
    setCart(cart);
    setTotalPriceForGiftAttribution(
      computeTotalPriceForGift(cart.lineItems, giftAtCheckout)
    );
    await persistCart(cart);
    setLoading(false);
  };

  const value = useMemo(() => {
    return {
      cart,
      loading,
      sideCartVisible,
      addToCart,
      addNote,
      changeQuantity,
      removeLineItem,
      resetCart,
      showSideCart,
      hideSideCart,
      giftAtCheckout,
      selectedGiftVariantId,
      changeGiftVariant,
      totalPriceForGiftAttribution,
      splitShippingIds,
      cartUpsell,
    };
  }, [
    cart,
    loading,
    sideCartVisible,
    giftAtCheckout,
    selectedGiftVariantId,
    totalPriceForGiftAttribution,
    splitShippingIds,
    cartUpsell,
  ]);

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

export function useCart() {
  const value = useContext(CartContext);
  if (!value) {
    throw new Error("CartProvider Context is missing");
  }

  const addingToCart =
    value.cart !== undefined &&
    value.cart.lineItems.some((item) => item.state === "adding");
  return {
    ...value,
    addingToCart,
  };
}

const CART_VERSION = 25;

async function initLocalStorage() {
  objectStore = localForage.createInstance({
    name: "rue-des-mille",
    storeName: "cart",
  });
  const cart: CartWithMetadata = await objectStore.getItem("cart");
  const version = cart && cart.version;
  if (!version || version < CART_VERSION) {
    await objectStore.clear();
  }
}

async function getPersistedCart(): Promise<CartWithMetadata> {
  if (!objectStore) {
    await initLocalStorage();
  }
  return objectStore.getItem("cart");
}

async function persistCart(cart: Cart): Promise<CartWithMetadata> {
  if (!objectStore) {
    await initLocalStorage();
  }
  const enrichedCart = {
    ...cart,
    lastUpdatedAt: new Date(),
    version: CART_VERSION,
  };
  return objectStore.setItem("cart", enrichedCart);
}

async function resetCartStore(): Promise<void> {
  if (!objectStore) {
    await initLocalStorage();
  }
  return objectStore.clear();
}
