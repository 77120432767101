import { MoneyV2 } from "types";
import {
  Attribute,
  AttributeInput,
  ShopifyVariantSelectedOption,
} from "./types";
import { MailingAddressInput } from "hooks/useUser";

export interface ShopifyLineItemInput {
  variantId: string;
  quantity: number;
  customAttributes?: AttributeInput[];
}

export interface ShopifyCartVariant {
  id: string;
  sku: string;
  title: string;
  price: MoneyV2;
  compareAtPrice?: MoneyV2;
  image?: {
    altText?: string;
    originalSrc: string;
  } | null;
  selectedOptions: ShopifyVariantSelectedOption[];
  product: {
    id: string;
    title: string;
    variants: {
      edges: {
        node: { id: string };
      }[];
    };
  };
}

export interface CheckoutPayload {
  id: string;
  completedAt: string;
  totalPrice: MoneyV2;
  webUrl: string;
  lineItems: {
    edges: Array<{
      node: {
        id: string;
        quantity: number;
        customAttributes: Attribute[];
        variant: ShopifyCartVariant;
        discountAllocations?: {
          discountApplication: {
            title?: string;
          };
          allocatedAmount: {
            amount: string;
          };
        }[];
      };
    }>;
  };
}

export interface CreateCartResult {
  checkoutCreate: {
    checkout: CheckoutPayload;
    checkoutUserErrors: Array<{
      code?: string;
      field: string[];
      message: string;
    }>;
  };
}

export interface CreateCartVariables {
  input: {
    email?: string;
    lineItems?: ShopifyLineItemInput[];
    note?: string;
    customAttributes?: AttributeInput[];
  };
}

export const createCartMutation = /* GraphQL */ `
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        completedAt
        totalPrice: totalPriceV2 {
          amount
        }
        webUrl
        lineItems(first: 50) {
          edges {
            node {
              id
              quantity
              customAttributes {
                key
                value
              }
              variant {
                id
                sku
                title
                price: priceV2 {
                  amount
                }
                compareAtPrice: compareAtPriceV2 {
                  amount
                }
                image {
                  altText
                  originalSrc
                }
                selectedOptions {
                  name
                  value
                }
                product {
                  id
                  title
                  variants(first: 2) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
              discountAllocations {
                discountApplication {
                  ... on AutomaticDiscountApplication {
                    title
                  }
                }
                allocatedAmount {
                  amount
                }
              }
            }
          }
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export interface ReplaceItemsResult {
  checkoutLineItemsReplace: {
    checkout: CheckoutPayload;
  };
}

export interface ReplaceItemsVariables {
  checkoutId: string;
  lineItems: ShopifyLineItemInput[];
}

export const replaceItemsMutation = /* GraphQL */ `
  mutation checkoutLineItemsReplace(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        id
        totalPrice: totalPriceV2 {
          amount
        }
        webUrl
        lineItems(first: 50) {
          edges {
            node {
              id
              quantity
              customAttributes {
                key
                value
              }
              variant {
                id
                sku
                title
                price: priceV2 {
                  amount
                }
                compareAtPrice: compareAtPriceV2 {
                  amount
                }
                image {
                  altText
                  originalSrc
                }
                selectedOptions {
                  name
                  value
                }
                product {
                  id
                  title
                  variants(first: 2) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
              discountAllocations {
                discountApplication {
                  ... on AutomaticDiscountApplication {
                    title
                  }
                }
                allocatedAmount {
                  amount
                }
              }
            }
          }
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

export interface AddNoteResult {
  checkoutLineItemsReplace: {
    checkout: {
      id: string;
    };
  };
}

export interface AddNoteVariables {
  checkoutId: string;
  input: {
    note: string;
  };
}

export const addNoteMutation = /* GraphQL */ `
  mutation checkoutAddNoteMutation(
    $checkoutId: ID!
    $input: CheckoutAttributesUpdateV2Input!
  ) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        id
      }
    }
  }
`;

export interface GetCheckoutResult {
  checkout: CheckoutPayload;
}

export interface GetCheckoutVariables {
  checkoutId: string;
}

export const checkoutQuery = /* GraphQL */ `
  query GetCheckout($checkoutId: ID!) {
    checkout: node(id: $checkoutId) {
      ... on Checkout {
        id
        completedAt
        totalPrice: totalPriceV2 {
          amount
        }
        webUrl
        lineItems(first: 50) {
          edges {
            node {
              id
              quantity
              customAttributes {
                key
                value
              }
              variant {
                id
                sku
                title
                price: priceV2 {
                  amount
                }
                compareAtPrice: compareAtPriceV2 {
                  amount
                }
                image {
                  altText
                  originalSrc
                }
                selectedOptions {
                  name
                  value
                }
                product {
                  id
                  title
                  variants(first: 2) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
              discountAllocations {
                discountApplication {
                  ... on AutomaticDiscountApplication {
                    title
                  }
                }
                allocatedAmount {
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface CheckoutCustomerAssociateV2Result {
  checkoutCustomerAssociateV2: { checkout: { webUrl: string } };
}

export interface CheckoutCustomerAssociateV2Variables {
  checkoutId: string;
  customerAccessToken: string;
}

export const checkoutCustomerAssociateV2 = /* GraphQL */ `
  mutation checkoutCustomerAssociateV2(
    $checkoutId: ID!
    $customerAccessToken: String!
  ) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkout {
        webUrl
      }
    }
  }
`;

export interface CheckoutCustomerDisassociateV2Result {
  checkoutCustomerAssociateV2: { checkout: { webUrl: string } };
}

export interface CheckoutCustomerDisassociateV2Variables {
  checkoutId: string;
}

export const checkoutCustomerDisassociateV2 = /* GraphQL */ `
  mutation checkoutCustomerDisassociateV2($checkoutId: ID!) {
    checkoutCustomerDisassociateV2(checkoutId: $checkoutId) {
      checkout {
        webUrl
      }
    }
  }
`;

export interface CheckoutShippingAddressUpdateV2Result {
  checkoutShippingAddressUpdateV2: { checkout: { webUrl: string } };
}

export interface CheckoutShippingAddressUpdateV2Variables {
  checkoutId: string;
  shippingAddress: MailingAddressInput;
}

export const checkoutShippingAddressUpdateV2 = /* GraphQL */ `
  mutation checkoutShippingAddressUpdateV2(
    $checkoutId: ID!
    $shippingAddress: MailingAddressInput!
  ) {
    checkoutShippingAddressUpdateV2(
      checkoutId: $checkoutId
      shippingAddress: $shippingAddress
    ) {
      checkout {
        webUrl
      }
    }
  }
`;
