import { Exchange, Operation } from "urql";
import { fromArray, mergeMap, pipe, tap } from "wonka";

/**
 * SwitchMap exchange
 * Deduplicates queries cancelling the old request and forwarding the new one
 */
export const switchMapExchange: Exchange = ({ forward }) => {
  const inFlight = new Map<number, Operation>();

  return ops$ =>
    pipe(
      ops$,
      mergeMap(ops => {
        let opsToForward = [];
        if (ops.operationName === "query" && inFlight.has(ops.key)) {
          const teardown: Operation = { ...ops, operationName: "teardown" };
          opsToForward.push(teardown);
          inFlight.delete(ops.key);
        }
        inFlight.set(ops.key, ops);
        opsToForward.push(ops);
        return fromArray(opsToForward);
      }),
      forward,
      tap(res => {
        inFlight.delete(res.operation.key);
      })
    );
};
