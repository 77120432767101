import Link from "components/Link";
import Wrapper from "components/Wrapper";
import { graphql, useStaticQuery } from "gatsby";
import { useLocale } from "hooks/useLocale";
import { useTranslation } from "hooks/useTranslation";
import React from "react";
import styled from "styled-components";
import { getLocalizedField } from "utils/common/locale";
import { computePath } from "utils/common/navigation";
import FacebookIcon from "./icons/Facebook";
import InstagramIcon from "./icons/Instagram";
import TikTokIcon from "./icons/TikTok";

function Footer() {
  const { locale, defaultLocale } = useLocale();
  const footerQuery = useStaticQuery(staticQuery);
  const navigation = footerQuery.allDatoCmsFooter.nodes;
  const { formatMessage } = useTranslation();

  const rootLinks = navigation.filter((link: any) => !link.treeParent);

  return (
    <FooterContainer>
      <Wrapper>
        <Content>
          <LinksContainer>
            {rootLinks.map((rootLink: any, index: number) => (
              <LinkGroup key={index}>
                <GroupTitle>
                  {getLocalizedField(rootLink._allLabelLocales, locale)!.value}
                </GroupTitle>
                <GroupLinks>
                  {navigation
                    .filter(
                      (link: any) =>
                        link.treeParent && link.treeParent.id === rootLink.id
                    )
                    .map((link: any, index: number) => (
                      <Link
                        key={index}
                        to={computePath(
                          locale,
                          defaultLocale,
                          link.linkedResource && link.linkedResource.__typename,
                          link.linkedResource &&
                            link.linkedResource._allHandleLocales,
                          link._allUrlLocales
                        )}
                      >
                        {
                          getLocalizedField(link._allLabelLocales, locale)!
                            .value
                        }
                      </Link>
                    ))}
                </GroupLinks>
              </LinkGroup>
            ))}
            <SocialContainer>
              <GroupTitle>{formatMessage({ id: "footer.social" })}</GroupTitle>
              <SocialIconContainer>
                <Link
                  to="https://www.facebook.com/RuedesMilleItalia/"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <FacebookIconFooter />
                </Link>
                <Link
                  to="https://www.instagram.com/ruedesmille/"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <InstagramIconFooter />
                </Link>
                <Link
                  to="https://www.tiktok.com/@ruedesmille_official"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <TikTokIconFooter />
                </Link>
              </SocialIconContainer>
            </SocialContainer>
          </LinksContainer>
        </Content>
        <Copyright>
          Copyright © {new Date().getFullYear()} | Ruedesmille - P.IVA
          00293480117
        </Copyright>
      </Wrapper>
    </FooterContainer>
  );
}

const staticQuery = graphql`
  {
    allDatoCmsFooter(
      filter: { locale: { eq: "it" } }
      sort: { fields: [position] }
    ) {
      nodes {
        id
        _allLabelLocales {
          locale
          value
        }
        linkedResource {
          __typename
          ... on DatoCmsPage {
            _allHandleLocales {
              locale
              value
            }
          }
          ... on DatoCmsCategory {
            _allHandleLocales {
              locale
              value
            }
          }
          ... on DatoCmsCollection {
            _allHandleLocales {
              locale
              value
            }
          }
        }
        _allUrlLocales {
          locale
          value
        }
        treeParent {
          id
        }
      }
    }
  }
`;

const FooterContainer = styled.footer`
  margin-top: 80px;
  padding-top: 80px;
  padding-bottom: 40px;
  border-top: 1px solid #ededed;
`;

const Content = styled.div``;

const GroupTitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const SocialContainer = styled.div`
  flex: 0 0 400px;
  width: 400px;
  padding: 20px;
  order: 1;
  @media (max-width: 960px) {
    width: 100%;
    flex: 0 0 100%;
    padding: 0 20%;
    order: 0;
    padding-bottom: 60px;
    ${GroupTitle} {
      text-align: center;
    }
  }
  @media (max-width: 700px) {
    padding: 20px;
  }
  @media (max-width: 500px) {
    padding: 20px 0;
    ${GroupTitle} {
      text-align: left;
    }
  }
`;

const LinkGroup = styled.div`
  padding: 20px;
  white-space: nowrap;
  order: 1;
  @media (max-width: 500px) {
    padding: 20px 0;
  }
`;

const GroupLinks = styled.div`
  display: flex;
  flex-direction: column;
  a {
    display: inline-block;
    font-size: 13px;
    letter-spacing: 0.08em;
    color: #333;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    padding: 5px 0;
    &:hover {
      cursor: pointer;
      font-weight: 600;
      text-decoration: underline;
    }
  }
`;

const Copyright = styled.div`
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.1em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
`;

const SocialIconContainer = styled.div`
  margin-left: -8px;
  width: calc(100% + 16px);
  padding: 10px 0;
  display: flex;
  @media (max-width: 960px) {
    justify-content: center;
  }
`;
const FacebookIconFooter = styled(FacebookIcon)`
  fill: #706f6f;
  margin: 8px;
  transition: 0.3s all;

  display: inline-block;
  &:hover {
    fill: ${({ theme }) => theme.colors.main};
  }
`;
const InstagramIconFooter = styled(InstagramIcon)`
  fill: #706f6f;
  transition: 0.3s all;
  display: inline-block;
  margin: 8px;
  &:hover {
    fill: ${({ theme }) => theme.colors.main};
  }
`;

const TikTokIconFooter = styled(TikTokIcon)`
  fill: #706f6f;
  transition: 0.3s all;
  display: inline-block;
  margin: 8px;
  &:hover {
    fill: ${({ theme }) => theme.colors.main};
  }
`;

export default Footer;
function useIntl() {
  throw new Error("Function not implemented.");
}
