import { useStaticQuery, graphql } from "gatsby";
import { keyBy } from "lodash";
import { useLocale } from "./useLocale";

export interface ProductOption {
  locale: string;
  shopifyOptionName: string;
  optionName: string;
  selectorType?: "lista" | "colore";
  values: Array<{
    shopifyValue: string;
    value: string;
    color: {
      hex: string;
    } | null;
  }>;
}

interface AllProductOptionsData {
  allDatoCmsProductOption: {
    nodes: ProductOption[];
  };
}

export default function useProductOptions() {
  const { locale } = useLocale();
  const { allDatoCmsProductOption } = useStaticQuery<AllProductOptionsData>(
    graphql`
      query allProductOptions {
        allDatoCmsProductOption {
          nodes {
            locale
            shopifyOptionName
            optionName
            selectorType
            values {
              shopifyValue
              value
              color {
                hex
              }
            }
          }
        }
      }
    `
  );
  const localizedOptions = allDatoCmsProductOption.nodes.filter(
    node => node.locale === locale
  );
  return keyBy(localizedOptions, "shopifyOptionName");
}
