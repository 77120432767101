import React, { useContext, useEffect, useState, useMemo } from "react";
import { useQuery, useMutation } from "hooks/useGraphQL";
import {
  MailingAddressInput,
  loginMutation,
  LoginMutationResult,
  LoginMutationVariables,
  userQuery,
  UserQueryResult,
  UserQueryVariables,
  customerAddressCreateMutation,
  CustomerAddressCreateMutationResult,
  CustomerAddressCreateMutationVariables,
  customerAddressUpdateMutation,
  CustomerAddressUpdateMutationResult,
  CustomerAddressUpdateMutationVariables,
  customerAddressDeleteMutation,
  CustomerAddressDeleteMutationResult,
  CustomerAddressDeleteMutationVariables,
  customerDefaultAddressUpdateMutation,
  CustomerDefaultAddressUpdateMutationResult,
  CustomerDefaultAddressUpdateMutationVariables
} from "./operations";
import {
  getPersistedUser,
  setPersistedUser,
  deletePersistedUser
} from "utils/persistence/user";

import { User, Address } from "./types";

export { User, Address } from "./types";

export { MailingAddressInput } from "./operations";

interface UserContext {
  errors?: any[];
  loading: boolean;
  user?: User;
  addingAddress: boolean;
  updatingAddressesIds: string[];
  deletingAddressesIds: string[];
  login: (email: string, password: string) => Promise<User | undefined>;
  updateUser: (user: User) => Promise<void>;
  deleteUser: () => Promise<void>;
  addAddress: (
    address: MailingAddressInput,
    isDefault?: boolean
  ) => Promise<Address | void>;
  updateAddress: (
    addressId: string,
    address: MailingAddressInput,
    isDefault?: boolean
  ) => Promise<Address | void>;
  deleteAddress: (addressId: string) => Promise<void>;
}

const defaultState = {
  loading: true,
  addingAddress: false,
  updatingAddressesIds: [],
  deletingAddressesIds: [],
  login: () => Promise.resolve(undefined),
  updateUser: (_user: User) => Promise.resolve(),
  deleteUser: () => Promise.resolve(),
  addAddress: () => Promise.resolve(undefined),
  updateAddress: () => Promise.resolve(undefined),
  deleteAddress: () => Promise.resolve(undefined)
};

const UserContext = React.createContext<UserContext>(defaultState);

interface ProviderProps {}

export const UserProvider: React.SFC<ProviderProps> = ({ children }) => {
  const [errors, setErrors] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [addingAddress, setAddingAddress] = useState(false);
  const [updatingAddressesIds, setUpdatingAddressesIds] = useState<string[]>(
    []
  );
  const [deletingAddressesIds, setDeletingAddressesIds] = useState<string[]>(
    []
  );
  const [user, setUser] = useState<User | undefined>();
  const [, loginMutate] = useMutation<
    LoginMutationResult,
    LoginMutationVariables
  >(loginMutation);
  const [, fetchUser] = useQuery<UserQueryResult, UserQueryVariables>(
    userQuery,
    {
      autoExecution: false
    }
  );
  const [, createAddressMutate] = useMutation<
    CustomerAddressCreateMutationResult,
    CustomerAddressCreateMutationVariables
  >(customerAddressCreateMutation);

  const [, updateAddressMutate] = useMutation<
    CustomerAddressUpdateMutationResult,
    CustomerAddressUpdateMutationVariables
  >(customerAddressUpdateMutation);

  const [, updateDefaultAddressMutate] = useMutation<
    CustomerDefaultAddressUpdateMutationResult,
    CustomerDefaultAddressUpdateMutationVariables
  >(customerDefaultAddressUpdateMutation);

  const [, deleteAddressMutate] = useMutation<
    CustomerAddressDeleteMutationResult,
    CustomerAddressDeleteMutationVariables
  >(customerAddressDeleteMutation);

  useEffect(() => {
    fetchPersistedUser();
  }, []);

  const fetchPersistedUser = async () => {
    const cachedUser = await getPersistedUser();
    if (cachedUser) {
      setUser(cachedUser);
    }
    setLoading(false);
  };

  const persistUser = async (user: User) => {
    await setPersistedUser(user);
    setUser(user);
  };

  const deleteUser = async () => {
    await deletePersistedUser();
    setUser(undefined);
  };

  const refetchUser = async (accessToken: string): Promise<User> => {
    const { data } = await fetchUser({
      customerAccessToken: accessToken
    });
    if (data && data.customer) {
      const user: User = {
        email: data.customer.email,
        accessToken: accessToken,
        addresses: data.customer.addresses.edges.map(edge => edge.node),
        defaultAddressId: data.customer.defaultAddress
          ? data.customer.defaultAddress.id
          : undefined
      };
      return user;
    }
    throw new Error("failed to fetch user data");
  };

  const login = async (email: string, password: string) => {
    setLoading(true);
    const { data } = await loginMutate({
      input: {
        email,
        password
      }
    });
    const {
      customerAccessToken,
      customerUserErrors
    } = data!.customerAccessTokenCreate;
    if (customerUserErrors.length > 0) {
      setErrors(customerUserErrors);
      setLoading(false);
      return;
    }
    if (!customerAccessToken) {
      setErrors([new Error("missing access token")]);
      setLoading(false);
      return;
    }
    try {
      const user = await refetchUser(customerAccessToken.accessToken);
      await persistUser(user);
      setLoading(false);
      setErrors(undefined);
      return user;
    } catch (error) {
      setErrors([error]);
      setLoading(false);
    }
  };

  const addAddress = async (
    address: MailingAddressInput,
    isDefault = false
  ): Promise<Address | void> => {
    if (!user) {
      setErrors([new Error("cannot add address without a logged user")]);
      return;
    }
    setLoading(true);
    setAddingAddress(true);
    const { data } = await createAddressMutate({
      customerAccessToken: user.accessToken,
      address
    });
    const { customerAddress, customerUserErrors } = data!.customerAddressCreate;
    if (customerUserErrors.length > 0) {
      setErrors(customerUserErrors);
      setLoading(false);
      setAddingAddress(false);
      return;
    }
    const addresses = user.addresses.concat(customerAddress);
    let defaultAddressId =
      addresses.length === 1 ? addresses[0].id : user.defaultAddressId;
    if (addresses.length > 0 && isDefault) {
      const { data } = await updateDefaultAddressMutate({
        customerAccessToken: user.accessToken,
        addressId: customerAddress.id
      });
      const { customerUserErrors } = data!.customerDefaultAddressUpdate;
      if (customerUserErrors.length > 0) {
        setErrors(customerUserErrors);
        setLoading(false);
        setAddingAddress(false);
        return;
      }
      defaultAddressId = customerAddress.id;
    }
    const updatedUser: User = {
      ...user,
      addresses,
      defaultAddressId
    };
    await persistUser(updatedUser);
    setErrors(undefined);
    setLoading(false);
    setAddingAddress(false);
    return customerAddress;
  };

  const updateAddress = async (
    addressId: string,
    address: MailingAddressInput,
    isDefault = false
  ) => {
    if (!user) {
      setErrors([new Error("cannot update address without a logged user")]);
      return;
    }
    setUpdatingAddressesIds(updatingAddressesIds.concat(addressId));
    setLoading(true);
    const { data } = await updateAddressMutate({
      customerAccessToken: user.accessToken,
      id: addressId,
      address
    });
    const { customerAddress, customerUserErrors } = data!.customerAddressUpdate;
    if (customerUserErrors.length > 0) {
      setErrors(customerUserErrors);
      setLoading(false);
      setUpdatingAddressesIds(
        updatingAddressesIds.filter(id => id !== addressId)
      );
      return;
    }
    const addressIndex = user.addresses.findIndex(
      address => address.id === addressId
    );
    if (addressIndex < 0) {
      setLoading(false);
      setUpdatingAddressesIds(
        updatingAddressesIds.filter(id => id !== addressId)
      );
      return;
    }
    const addresses = [...user.addresses];
    addresses.splice(addressIndex, 1, customerAddress);
    let defaultAddressId =
      addresses.length === 1 ? addresses[0].id : user.defaultAddressId;
    if (addresses.length > 0 && isDefault) {
      const { data } = await updateDefaultAddressMutate({
        customerAccessToken: user.accessToken,
        addressId: customerAddress.id
      });
      const { customerUserErrors } = data!.customerDefaultAddressUpdate;
      if (customerUserErrors.length > 0) {
        setErrors(customerUserErrors);
        setLoading(false);
        setUpdatingAddressesIds(
          updatingAddressesIds.filter(id => id !== addressId)
        );
        return;
      }
      defaultAddressId = customerAddress.id;
    }
    const updatedUser: User = {
      ...user,
      addresses,
      defaultAddressId
    };
    await persistUser(updatedUser);
    setErrors(undefined);
    setLoading(false);
    setUpdatingAddressesIds(
      updatingAddressesIds.filter(id => id !== addressId)
    );
    return customerAddress;
  };

  const deleteAddress = async (addressId: string) => {
    if (!user) {
      setErrors([new Error("cannot add address without a logged user")]);
      return;
    }
    setDeletingAddressesIds(deletingAddressesIds.concat(addressId));
    setLoading(true);
    const { data } = await deleteAddressMutate({
      customerAccessToken: user.accessToken,
      id: addressId
    });
    const { customerUserErrors } = data!.customerAddressDelete;
    if (customerUserErrors.length > 0) {
      setErrors(customerUserErrors);
      setLoading(false);
      setDeletingAddressesIds(
        deletingAddressesIds.filter(id => id !== addressId)
      );
      return;
    }
    if (addressId === user.defaultAddressId) {
      try {
        const refetchedUser = await refetchUser(user.accessToken);
        await persistUser(refetchedUser);
        setErrors(undefined);
      } catch (error) {
        setErrors([error]);
      }
      setLoading(false);
      setDeletingAddressesIds(
        deletingAddressesIds.filter(id => id !== addressId)
      );
      return;
    }
    const updatedUser: User = {
      ...user,
      addresses: user.addresses.filter(address => address.id !== addressId)
    };
    await persistUser(updatedUser);
    setErrors(undefined);
    setLoading(false);
    setDeletingAddressesIds(
      deletingAddressesIds.filter(id => id !== addressId)
    );
  };

  const value = useMemo(
    () => ({
      errors,
      loading,
      addingAddress,
      updatingAddressesIds,
      deletingAddressesIds,
      user,
      login,
      updateUser: persistUser,
      deleteUser,
      addAddress,
      updateAddress,
      deleteAddress
    }),
    [errors, loading, user]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext };

export function useUser() {
  const value = useContext(UserContext);
  if (!value) {
    throw new Error("UserProvider Context is missing");
  }
  return value;
}
