import React from "react";
import PropTypes from "prop-types";

const ShoppingBag = props => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M16,8V6a3.93,3.93,0,0,0-3.87-4h-.26A3.93,3.93,0,0,0,8,6H8V8H8" />
      <path d="M4,8H20a0,0,0,0,1,0,0V18a4,4,0,0,1-4,4H8a4,4,0,0,1-4-4V8A0,0,0,0,1,4,8Z" />
    </svg>
  );
};

ShoppingBag.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ShoppingBag.defaultProps = {
  color: "currentColor",
  size: "24"
};

export default ShoppingBag;
