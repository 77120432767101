import React, { Ref } from "react";
import styled from "styled-components";

interface Props {
  size: "small" | "normal";
  hasError?: boolean;
}

type ExtendableProps = Props & { [_: string]: any };

const InputText = styled(
  React.forwardRef(({ ...props }, ref: Ref<HTMLInputElement>) => (
    <input ref={ref} {...props} />
  ))
)`
  width: 100%;
  height: ${(props: ExtendableProps) =>
    ({
      small: "40px",
      normal: "50px"
    }[props.size || "normal"])};
  margin: 0;
  padding: 0;
  display: block;
  -webkit-appearance: none;
  border: ${(props: ExtendableProps) =>
    props.hasError ? "1px solid #ee0000;" : "1px solid #c6c6c6;"}
  margin-bottom: ${(props: ExtendableProps) =>
    ({
      small: "15px",
      normal: "30px"
    }[props.size || "normal"])};
  padding: 0 15px;
  font-family: soleil, sans-serif;
  font-size: ${(props: ExtendableProps) =>
    ({
      small: "12px",
      normal: "13px"
    }[props.size || "normal"])};
  letter-spacing: 0.04em;
  border-radius: 0;
  ${(props: ExtendableProps) =>
    props.hasError && `border-color: ${props.theme.colors.red}`}
`;

export default InputText;
