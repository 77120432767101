import { Address } from "./types";

export interface MailingAddressInput {
  address1?: string;
  address2?: string;
  city?: string;
  company?: string;
  country?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  province?: string;
  zip?: string;
}

export interface CustomerUserError {
  code: null | string;
  field: null | string[];
  message: string;
}

const addressFragment = /* GraphQL */ `
  id
  formatted(withName: true)
  firstName
  lastName
  company
  address1
  address2
  city
  province
  country
  zip
  phone
`;

export interface LoginMutationResult {
  customerAccessTokenCreate: {
    customerAccessToken: {
      accessToken: string;
      expiresAt: string;
    } | null;
    customerUserErrors: CustomerUserError[];
  };
}

export interface LoginMutationVariables {
  input: {
    email: string;
    password: string;
  };
}

export const loginMutation = /* GraphQL */ `
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export interface UserQueryResult {
  customer: {
    id: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    acceptsMarketing?: boolean;
    defaultAddress: null | {
      id: string;
    };
    addresses: {
      edges: Array<{
        node: Address;
      }>;
    };
  };
}

export interface UserQueryVariables {
  customerAccessToken: string;
}

export const userQuery = /* GraphQL */ `
  query getCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      email
      acceptsMarketing
      defaultAddress {
        id
      }
      addresses(first: 100) {
        edges {
          node {
            ${addressFragment}
          }
        }
      }
    }
  }
`;

export interface CustomerAddressCreateMutationResult {
  customerAddressCreate: {
    customerAddress: Address;
    customerUserErrors: Array<CustomerUserError>;
  };
}

export interface CustomerAddressCreateMutationVariables {
  customerAccessToken: string;
  address: MailingAddressInput;
}

export const customerAddressCreateMutation = /* GraphQL */ `
  mutation customerAddressCreate(
    $customerAccessToken: String!
    $address: MailingAddressInput!
  ) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        ${addressFragment}
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export interface CustomerAddressUpdateMutationResult {
  customerAddressUpdate: {
    customerAddress: Address;
    customerUserErrors: CustomerUserError[];
  };
}

export interface CustomerAddressUpdateMutationVariables {
  customerAccessToken: string;
  id: string;
  address: MailingAddressInput;
}

export const customerAddressUpdateMutation = /* GraphQL */ `
  mutation customerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        ${addressFragment}
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export interface CustomerAddressDeleteMutationResult {
  customerAddressDelete: {
    customerUserErrors: CustomerUserError[];
  };
}

export interface CustomerAddressDeleteMutationVariables {
  customerAccessToken: string;
  id: string;
}

export const customerAddressDeleteMutation = /* GraphQL */ `
  mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
    customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export interface CustomerDefaultAddressUpdateMutationResult {
  customerDefaultAddressUpdate: {
    customerUserErrors: Array<CustomerUserError>;
  };
}

export interface CustomerDefaultAddressUpdateMutationVariables {
  customerAccessToken: string;
  addressId: string;
}

export const customerDefaultAddressUpdateMutation = /* GraphQL */ `
  mutation customerDefaultAddressUpdate(
    $customerAccessToken: String!
    $addressId: ID!
  ) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;
