export const routes = {
  home: "/",
  login: "/login/",
  signup: "/signup/",
  recoverPassword: "/recover-password/",
  account: "/account/",
  orders: "/account/orders/",
  order: "/account/order/",
  cart: "/cart/"
};
