// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-account-tsx": () => import("./../../../src/templates/account.tsx" /* webpackChunkName: "component---src-templates-account-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-cart-index-tsx": () => import("./../../../src/templates/cart/index.tsx" /* webpackChunkName: "component---src-templates-cart-index-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-collection-index-tsx": () => import("./../../../src/templates/collection/index.tsx" /* webpackChunkName: "component---src-templates-collection-index-tsx" */),
  "component---src-templates-collections-tsx": () => import("./../../../src/templates/collections.tsx" /* webpackChunkName: "component---src-templates-collections-tsx" */),
  "component---src-templates-compose-index-tsx": () => import("./../../../src/templates/compose/index.tsx" /* webpackChunkName: "component---src-templates-compose-index-tsx" */),
  "component---src-templates-contacts-tsx": () => import("./../../../src/templates/contacts.tsx" /* webpackChunkName: "component---src-templates-contacts-tsx" */),
  "component---src-templates-customer-activate-tsx": () => import("./../../../src/templates/customer-activate.tsx" /* webpackChunkName: "component---src-templates-customer-activate-tsx" */),
  "component---src-templates-login-tsx": () => import("./../../../src/templates/login.tsx" /* webpackChunkName: "component---src-templates-login-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-index-tsx": () => import("./../../../src/templates/product/index.tsx" /* webpackChunkName: "component---src-templates-product-index-tsx" */),
  "component---src-templates-recover-password-tsx": () => import("./../../../src/templates/recover-password.tsx" /* webpackChunkName: "component---src-templates-recover-password-tsx" */),
  "component---src-templates-reset-password-tsx": () => import("./../../../src/templates/reset-password.tsx" /* webpackChunkName: "component---src-templates-reset-password-tsx" */),
  "component---src-templates-signup-tsx": () => import("./../../../src/templates/signup.tsx" /* webpackChunkName: "component---src-templates-signup-tsx" */),
  "component---src-templates-store-locator-tsx": () => import("./../../../src/templates/store-locator.tsx" /* webpackChunkName: "component---src-templates-store-locator-tsx" */)
}

