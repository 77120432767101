import localForage from "localforage";

const objectStore = localForage.createInstance({
  name: "rue-des-mille",
  storeName: "newsletterPopup"
});

export interface NewsletterPopupCookie {
  expiration: number;
  id: string;
  show: boolean;
}

export const getNewsletterPopup = (): Promise<NewsletterPopupCookie> =>
  objectStore.getItem("newsletterPopup");

export const setNewsletterPopup = ({
  expiration,
  id,
  show
}: NewsletterPopupCookie): Promise<NewsletterPopupCookie> =>
  objectStore.setItem("newsletterPopup", {
    id,
    expiration,
    show
  });

export const setNewsletterPopupToFalse = async (): Promise<void> => {
  const popUpCookie = await getNewsletterPopup();
  const newPopup = { ...popUpCookie, show: false };
  await setNewsletterPopup(newPopup);
};
export const deleteshouldShowNewsletterPopup = (): Promise<void> =>
  objectStore.clear();
