import React, { PropsWithChildren } from "react";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import styled from "styled-components";

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink

type Props = PropsWithChildren<GatsbyLinkProps<any>>;

const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}: Props) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  delete (other as any).isRoot;
  delete (other as any).featured;

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLinkStyled
        to={ensureTrailingSlash(to)}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLinkStyled>
    );
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  );
};

const GatsbyLinkStyled = styled(GatsbyLink)`
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;

export default Link;

function ensureTrailingSlash(url: string) {
  return url.endsWith("/") || url.includes("?") ? url : url + "/";
}
