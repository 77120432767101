const colors = {
  main: "#977B2B",
  brand50: "#F8F4E7",
  brand100: "#F1E8CF",
  brand200: "#E3D09C",
  brand300: "#D5B96C",
  brand400: "#C7A138",
  brand500: "#977B2B",
  brand600: "#776022",
  brand700: "#5B4A1A",
  brand800: "#3C3011",
  brand900: "#201A09",
  error: "#d24017",
  text: "#333333",
  textLight: "#fff",
  background: "#FFFFFF",
  background2: "#FFF9E7",
  border: "#DDDDDD",
  grey: "#F8F8F8",
};

export default colors;
