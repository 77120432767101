import { DatoCmsProduct } from "graphql-typings";
import { FieldLocale, getLocalizedField, getLocalizedValue } from "./locale";

// TODO: try to merge with the following method
export const computeProductPath = (
  locale: string,
  defaultLocale: string,
  category: string,
  handle: string
) => `${locale === defaultLocale ? `` : `/${locale}`}/${category}/${handle}`;

export function getAllProductPathLocales(
  product: DatoCmsProduct,
  defaultLocale: string
): FieldLocale<string>[] {
  if (!product.category) {
    throw new Error(`Product ${product.handle} has no category!`);
  }
  const allCategoryHandleLocales = product.category
    ._allHandleLocales as FieldLocale<string>[];
  return product._allHandleLocales!.map((productHandleLocale) => {
    const categoryHandle = getLocalizedValue(
      allCategoryHandleLocales,
      productHandleLocale!.locale!
    );
    if (!categoryHandle) {
      throw new Error(
        `Cannot find ${productHandleLocale!
          .locale!} category locale for product ${product.handle!}`
      );
    }
    return {
      locale: productHandleLocale!.locale!,
      value: computeProductPath(
        productHandleLocale!.locale!,
        defaultLocale,
        categoryHandle,
        productHandleLocale!.value!
      ),
    };
  });
}

// TODO: try to merge with the previous method
export const computePath = (
  locale: string,
  defaultLocale: string,
  __typename?: string,
  _allHandleLocales?: { locale: string; value: string }[],
  _allUrlLocales?: { locale: string; value: string }[]
) => {
  let localizedLink: FieldLocale<string> | undefined;
  if (_allHandleLocales) {
    localizedLink = getLocalizedField(_allHandleLocales, locale);
  }
  if (!localizedLink && _allUrlLocales) {
    localizedLink = getLocalizedField(_allUrlLocales, locale);
    if (
      localizedLink &&
      localizedLink.value &&
      localizedLink.value.startsWith("http")
    ) {
      return ensureTrailingSlash(`${localizedLink.value}`);
    }
  }
  let prefix;
  if (__typename && __typename === "DatoCmsCollection") {
    prefix = computeCollectionPrefix(locale);
  }

  return ensureTrailingSlash(
    `${locale === defaultLocale ? `` : `/${locale}`}${
      prefix ? `/${prefix}` : ``
    }${localizedLink && localizedLink.value ? `/${localizedLink.value}` : ``}`
  );
};

function ensureTrailingSlash(url: string) {
  return url.endsWith("/") || url.endsWith(".pdf") ? url : url + "/";
}

export const computePathFromLocalizedData = (
  locale: string,
  defaultLocale: string,
  __typename?: string,
  handle?: string,
  categoryHandle?: string,
  url?: string
) => {
  let localizedLink = handle || url;
  let prefix;
  if (__typename && __typename === "DatoCmsCollection") {
    prefix = computeCollectionPrefix(locale);
  } else if (__typename && __typename === "DatoCmsNews") {
    prefix = "news";
  } else if (categoryHandle) {
    prefix = categoryHandle;
  }
  return `${locale === defaultLocale ? `` : `/${locale}`}${
    prefix ? `/${prefix}` : ``
  }${localizedLink ? `/${localizedLink}` : `/not-found`}`;
};

const computeCollectionPrefix = (locale: string) => {
  switch (locale) {
    case "it":
      return "collezioni";
    case "es":
      return "colecciones";
    default:
      return "collections";
  }
};
