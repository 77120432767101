import SearchIcon from "components/icons/Search";
import Link from "components/Link";
import Spinner from "components/Spinner";
import Fuse from "fuse.js";
import useDebounce from "hooks/useDebounce";
import { useLocale } from "hooks/useLocale";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { computePathFromLocalizedData } from "utils/common/navigation";
import { useTranslation } from "hooks/useTranslation";
import { getFirstMediaImage } from "utils/image";

export default function Search({ onClose }: any) {
  const [searchString, setSearchString] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState();
  const [fuse, setFuse] = useState();
  const [elementsToLoad, setElementsToLoad] = useState(10);
  const debouncedSearchString = useDebounce(searchString, 300);
  const { locale, defaultLocale } = useLocale();
  const { formatMessage } = useTranslation();

  const containerRef = useRef(null);

  useEffect(() => {
    setIsSearching(true);
    fetch(`/search-${locale}.json`)
      .then((response) => response.json())
      .then((json) => {
        setProducts(json);
        const options = {
          shouldSort: true,
          tokenize: true,
          threshold: 0.4,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 2,
          keys: ["dato.title", "dato.description"],
        };
        setFuse(new Fuse(json, options));
        setIsSearching(false);
      });
  }, []);

  useEffect(() => {
    setElementsToLoad(10);
    if (debouncedSearchString && debouncedSearchString.length === 0) {
      setSearchResults(undefined);
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    if (
      debouncedSearchString &&
      debouncedSearchString.length > 0 &&
      products &&
      fuse
    ) {
      setIsSearching(true);
      const searchResults = fuse.search(debouncedSearchString);
      setSearchResults(searchResults);
      setIsSearching(false);

      if (searchResults.length > 0) {
        (window as any).dataLayer.push({
          event: "gaEvent",
          eventCategory: "Search",
          eventAction: "WithResults",
          label: debouncedSearchString,
        });
      } else {
        (window as any).dataLayer.push({
          event: "gaEvent",
          eventCategory: "Search",
          eventAction: "ZeroResults",
          label: debouncedSearchString,
        });
      }
    }
  }, [debouncedSearchString, products, fuse, elementsToLoad]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = ({ target }: { target: any }) => {
    if (!(containerRef as any).current.contains(target)) {
      onClose();
    }
  };

  return (
    <Container ref={containerRef}>
      <Header>
        <SearchIconContainer>
          <SearchIcon />
        </SearchIconContainer>
        <Input
          placeholder={formatMessage({ id: "search.placeholder" })}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </Header>
      {searchString && (
        <SearchResultsContainer>
          {isSearching ||
          (!searchResults && debouncedSearchString !== undefined) ? (
            <Spinner />
          ) : (
            <>
              {searchResults && searchResults.length ? (
                <SearchResults>
                  {searchResults
                    .slice(0, elementsToLoad)
                    .map((res: any, index: number) => (
                      <SearchResult
                        to={computePathFromLocalizedData(
                          locale,
                          defaultLocale,
                          undefined,
                          res.dato.handle,
                          res.dato.category.handle
                        )}
                        onClick={onClose}
                        key={index}
                      >
                        <SearchResultImage
                          src={
                            getFirstMediaImage(res.shopify.media)?.originalSrc
                          }
                        />
                        <SearchResultTitle>{res.dato.title}</SearchResultTitle>
                      </SearchResult>
                    ))}
                </SearchResults>
              ) : (
                searchResults &&
                searchResults.length === 0 && (
                  <NoResults>
                    {formatMessage({ id: "search.noResults" })}
                  </NoResults>
                )
              )}
            </>
          )}
          {searchResults &&
            elementsToLoad &&
            elementsToLoad < searchResults.length && (
              <LoadMore onClick={() => setElementsToLoad(elementsToLoad + 10)}>
                {formatMessage({ id: "search.loadMore" })}
              </LoadMore>
            )}
        </SearchResultsContainer>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  width: 500px;
  max-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  height: 70px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 10px;
  align-items: center;
  display: flex;
  flex: 0 0 70px;
  &:hover {
    cursor: pointer;
  }
`;

const SearchIconContainer = styled.div`
  padding: 10px 20px 10px 10px;
`;

const Input = styled.input`
  border: none;
  color: ${({ theme }) => theme.colors.text};
  padding: 10px 0;
  font-family: soleil, sans-serif;
  letter-spacing: 0.04em;
  flex: 1 0 auto;
  font-size: 13px;
`;

const SearchResultsContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 70px);
  overflow: scroll;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
`;

const NoResults = styled.div`
  color: ${({ theme }) => theme.colors.main};
  padding: 30px 0;
  width: 100;
  text-align: center;
`;

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 0 0 auto;
`;

const SearchResult = styled(Link)`
  display: flex;
  padding: 10px 0;
  align-items: center;
`;

const SearchResultImage = styled.img`
  width: 120px;
  height: 120px;
  flex: 0 0 120px;
  display: block;
  position: relative;
  border: 5px solid ${({ theme }) => theme.colors.background2};
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    border: 5px solid ${({ theme }) => theme.colors.main};
  }
`;

const SearchResultTitle = styled.div`
  letter-spacing: 0.08em;
  color: #706f6f;
  font-size: 14px;
  padding-left: 20px;
`;

const LoadMore = styled.div`
  color: ${({ theme }) => theme.colors.main};
  padding: 20px 0 50px 0;
  width: 100;
  text-align: center;
  text-decoration: underline;
`;
