import React from "react";
import styled, { css } from "styled-components";

interface Props {
  id: string;
  hasError: boolean;
  isChecked: boolean;
  className?: string;
  onToggle(): void;
  children: any;
}

export default function InputCheckbox({
  id,
  hasError,
  isChecked,
  className,
  onToggle,
  children,
}: Props) {
  return (
    <Checkbox className={className} hasError={hasError}>
      <input id={id} type="checkbox" checked={isChecked} onChange={onToggle} />
      <label htmlFor={id}>{children}</label>
    </Checkbox>
  );
}

const Checkbox = styled.div`
  padding-bottom: 10px;
  cursor: pointer;
  input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    &:checked + label:before {
      background: #333;
      box-shadow: inset 0 0 0 5px #fff;
    }
  }

  label {
    display: flex;
    cursor: pointer;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 1px solid #c6c6c6;
      box-sizing: border-box;
      display: block;
      ${({ hasError }: { hasError: boolean }) =>
        hasError &&
        css`
          border: 2px solid ${({ theme }) => theme.colors.error};
        `}
    }
    span {
      width: calc(100% - 20px);
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 11px;
      letter-spacing: 0.04em;
      color: #333;
    }
    a {
      color: ${({ theme }) => theme.colors.main};
      text-decoration: none;
      &:hover {
        cursor: pointer;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
`;
