import Select from "components/Select";
import { LineItem, useCart } from "hooks/useCart";
import { useLocale } from "hooks/useLocale";
import useProductOptions from "hooks/useProductOptions";
import { useTranslation } from "hooks/useTranslation";
import React, { useEffect } from "react";
import styled from "styled-components";

interface Props {
  onChange: (productToShip: string) => void;
}

const SelectProductToShip: React.SFC<Props> = ({ onChange }) => {
  const { getLocalizedValue } = useLocale();
  const { formatMessage } = useTranslation();
  const { cart, splitShippingIds } = useCart();
  const productOptions = useProductOptions();

  useEffect(() => onChange(display(options[0])), []);

  const options =
    (cart &&
      cart.lineItems.reduce((acc: any[], item) => {
        return splitShippingIds.includes(item.variant!.id)
          ? [...acc, item]
          : acc;
      }, [])) ||
    [];

  const display = (item: LineItem) => {
    const title = getLocalizedValue(item.allLocaleValues.titles);
    const selectedOptions = item.variant!.selectedOptions
      ? item.variant!.selectedOptions.filter(
          option => option.name !== "Title" && option.value !== "Default Title"
        )
      : [];
    const selectedOptionsString = selectedOptions.reduce((acc, option) => {
      const localizedOption = productOptions[option.name];
      const localizedValue = localizedOption.values.find(
        value => value.shopifyValue === option.value
      );
      return `${acc}${localizedOption.optionName} ${
        localizedValue ? localizedValue.value : option.value
      } `;
    }, "");
    const customAttributes =
      (item.customAttributes &&
        item.customAttributes.length > 0 &&
        item.customAttributes.map((elem: any, index: number) => {
          return elem.value;
        })) ||
      [];
    const customAttributesString = customAttributes.reduce((acc, attribute) => {
      return `${acc}${attribute} `;
    }, "");
    return `${title}${
      selectedOptionsString.length > 0 ? ` - ${selectedOptionsString}` : ""
    }${
      customAttributesString.length > 0 ? ` - ${customAttributesString}` : ""
    }`;
  };

  return options.length > 1 ? (
    <Container>
      <Label>{formatMessage({ id: "cart.productToShip" })}</Label>
      <Select
        size="small"
        useSystemSelect={true}
        options={options}
        placeholder={""}
        display={display}
        valueChange={value => onChange(display(value))}
        compare={(value, option) => value === option}
      />
    </Container>
  ) : null;
};

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`;

const Label = styled.div`
  font-size: 14px;
  padding-bottom: 5px;
`;

export default SelectProductToShip;
