import localForage from "localforage";

const objectStore = localForage.createInstance({
  name: "rue-des-mille",
  storeName: "locale"
});

export const getPersistedLocale = (): Promise<string> =>
  objectStore.getItem("locale");

export const setPersistedLocale = (locale: string): Promise<string> =>
  objectStore.setItem("locale", locale);

export const deletePersistedLocale = (): Promise<void> => objectStore.clear();
