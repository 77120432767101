import "react-carousel/dist/react-carousel.es.css";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *,
  *:after,
  *:before {
      box-sizing: border-box;
  }
  body {
    margin: 0;
    padding: 0;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: soleil, sans-serif;
  }
  .no-scroll {
    overflow: hidden;
  }
`;

export default GlobalStyle;
