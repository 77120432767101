import Link from "components/Link";
import React from "react";
import styled from "styled-components";
import { useLocale } from "hooks/useLocale";
import { computePath } from "utils/common/navigation";
import { GatsbyImage } from "gatsby-plugin-image";

interface MenuLinkProps {
  link: any;
  style?: any;
  featured?: boolean;
}

/**
 * Clickable menu link component
 */
const MenuLink: React.SFC<MenuLinkProps> = ({ link, style }) => {
  const { locale, defaultLocale } = useLocale();
  const path = computePath(
    locale,
    defaultLocale,
    link.linkedResource && link.linkedResource.__typename,
    link.linkedResource && link.linkedResource._allHandleLocales,
    link._allUrlLocales
  );

  const urlLocaleLink = link._allUrlLocales.find(
    (ul: { locale: string; value?: string }) => ul.locale === locale
  );
  const featuredLabel = link._allFeaturedLabelLocales.find(
    (ul: { locale: string; value?: string }) => ul.locale === locale
  );
  const hasLink = link.linkedResource || (urlLocaleLink && urlLocaleLink.value);

  const labelLocale = link._allLabelLocales.find(
    (l: any) => l.locale === locale
  );
  const label = labelLocale && labelLocale.value;

  if (!label) {
    return null;
  }

  if (hasLink) {
    return (
      <NavLink
        to={path}
        style={style}
        featured={link.featured}
        isRoot={!link.treeParent}
      >
        {link.image && link.featured && (
          <ImageContainer>
            <Image
              image={link.image.gatsbyImageData}
              alt={link.image.alt || ""}
            />
          </ImageContainer>
        )}
        <LabelContainer isRoot={!link.treeParent}>
          <Label isRoot={!link.treeParent} featured={link.featured}>
            {label}
          </Label>
          {link.featured && featuredLabel.value && (
            <Featured featured={link.featured} isRoot={!link.treeParent}>
              {featuredLabel && featuredLabel.value}
            </Featured>
          )}
        </LabelContainer>
      </NavLink>
    );
  }
  return (
    <NoLinkContainer
      featured={link.featured}
      isRoot={!link.treeParent}
      style={style}
    >
      {link.image && link.featured && (
        <ImageContainer>
          <Image
            image={link.image.gatsbyImageData}
            alt={link.image.alt || ""}
          />
        </ImageContainer>
      )}
      <LabelContainer isRoot={!link.treeParent}>
        <Label isRoot={!link.treeParent} featured={link.featured}>
          {label}
        </Label>
        {link.featured && featuredLabel.value && (
          <Featured featured={link.featured} isRoot={!link.treeParent}>
            {featuredLabel && featuredLabel.value}
          </Featured>
        )}
      </LabelContainer>
    </NoLinkContainer>
  );
};

const NavLink = styled(Link)<{ featured: boolean; isRoot: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 2px;
  position: relative;
  margin: ${({ isRoot }) => (isRoot ? "0 6px" : "0")};
  min-height: 16px;
`;

const NoLinkContainer = styled.div<{ featured: boolean; isRoot: boolean }>`
  display: flex;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 2px;
  position: relative;
  margin: ${({ isRoot }) => (isRoot ? "0 6px" : "0")};
  min-height: 16px;
`;

const ImageContainer = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  flex: none;
`;

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const LabelContainer = styled.div<{ isRoot: boolean }>`
  display: flex;

  align-items: ${({ isRoot }) => (isRoot ? "center" : "start")}; ;
`;

const Label = styled.div<{ featured: boolean; isRoot: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ isRoot }) => (isRoot ? "0" : "0 6px 0 0")};
  z-index: 200;
  font-size: 12px;
  letter-spacing: 0.03em;
  line-height: 1.2em;

  color: ${({ isRoot, theme, featured }) => {
    if (isRoot && featured) {
      return theme.colors.main;
    } else {
      return "#333";
    }
  }};
  font-weight: ${({ isRoot, featured }) => {
    if (isRoot && featured) {
      return 600;
    } else {
      return 500;
    }
  }};
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  &::after {
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    width: 0;
    background-color: ${({ theme }) => theme.colors.main};
    bottom: -2px;
    transition: 0.5s all;
    left: 0;
  }
  &:hover {
    cursor: pointer;
    color: #000;
    &::after {
      width: 100%;
    }
  }
`;

const Featured = styled.div<{ featured: boolean; isRoot: boolean }>`
  display: ${({ featured }) => (featured ? "block" : "none")};
  position: ${({ isRoot }) => (isRoot ? "relative" : "relative")};
  margin: ${({ isRoot }) => (isRoot ? "-5px 0 0 0" : "-1px 0 0 0")};
  /* right: ${({ isRoot }) => (isRoot ? "auto" : "0px")};
  left: ${({ isRoot }) => (isRoot ? "calc(100% - 12px)" : "auto")}; */
  font-size: ${({ isRoot }) => (isRoot ? "9px" : "10px")};
  background-color: ${({ theme }) => theme.colors.main};
  color: #fff;

  border-radius: ${({ isRoot }) => (isRoot ? "100px" : "100px")};
  padding: ${({ isRoot }) => (isRoot ? "0 3px" : "0px 4px 1px 4px")};
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.06em;
  white-space: nowrap;
`;

export default MenuLink;
