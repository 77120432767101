import {
  OperationResult,
  Exchange,
  Operation,
  formatDocument,
  Client,
  collectTypesFromResponse
} from "urql";
import { share, pipe, filter, map, tap, merge } from "wonka";

type ResultCache = Map<number, OperationResult>;

interface OperationCache {
  [key: string]: Set<number>;
}

/**
 * Cache exchange
 * https://github.com/FormidableLabs/urql/issues/210#issuecomment-475882958
 */
export const cacheExchange: Exchange = ({ forward, client }) => {
  const resultCache = new Map() as ResultCache;
  const operationCache = Object.create(null) as OperationCache;

  const mapTypeNames = (operation: Operation): Operation => ({
    ...operation,
    query: formatDocument(operation.query)
  });

  const handleAfterMutation = afterMutation(
    resultCache,
    operationCache,
    client
  );

  const handleAfterQuery = afterQuery(resultCache, operationCache);

  const isOperationCached = (operation: Operation) => {
    const {
      key,
      operationName,
      context: { requestPolicy }
    } = operation;
    return (
      operationName === "query" &&
      requestPolicy !== "network-only" &&
      (requestPolicy === "cache-only" || resultCache.has(key))
    );
  };

  const shouldSkip = (operation: Operation) =>
    operation.operationName !== "mutation" &&
    operation.operationName !== "query";

  return ops$ => {
    const sharedOps$ = share(ops$);

    const cachedOps$ = pipe(
      sharedOps$,
      filter((op: Operation) => !shouldSkip(op) && isOperationCached(op)),
      map((operation: Operation) => {
        const {
          key,
          context: { requestPolicy }
        } = operation;
        const cachedResult = resultCache.get(key);
        if (requestPolicy === "cache-and-network") {
          reexecuteOperation(client, operation);
        }

        if (cachedResult !== undefined) {
          return cachedResult;
        }

        return {
          operation,
          data: undefined,
          error: undefined
        };
      })
    );

    const forwardedOps$ = pipe(
      merge([
        pipe(
          sharedOps$,
          filter(op => !shouldSkip(op) && !isOperationCached(op)),
          map(mapTypeNames)
        ),
        pipe(
          sharedOps$,
          filter(op => shouldSkip(op))
        )
      ]),
      forward,
      tap(response => {
        if (response.operation.operationName === "mutation") {
          handleAfterMutation(response);
        } else if (response.operation.operationName === "query") {
          handleAfterQuery(response);
        }
      })
    );

    return merge([cachedOps$, forwardedOps$]);
  };
};

// Reexecutes a given operation with the default requestPolicy
const reexecuteOperation = (client: Client, operation: Operation) => {
  return client.reexecuteOperation({
    ...operation,
    context: {
      ...operation.context,
      requestPolicy: "network-only"
    }
  });
};

// Invalidates the cache given a mutation's response
export const afterMutation = (
  resultCache: ResultCache,
  operationCache: OperationCache,
  client: Client
) => (response: OperationResult) => {
  const pendingOperations = new Set<number>();

  collectTypesFromResponse(response.data).forEach(typeName => {
    const operations =
      operationCache[typeName] || (operationCache[typeName] = new Set());
    operations.forEach(key => pendingOperations.add(key));
    operations.clear();
  });

  pendingOperations.forEach((key, index) => {
    const cachedResult = resultCache.get(key) as OperationResult;
    if (cachedResult) {
      const operation = (resultCache.get(key) as OperationResult).operation; // Result is guaranteed
      reexecuteOperation(client, operation);
    }
  });
};

// Mark typenames on typenameInvalidate for early invalidation
const afterQuery = (
  resultCache: ResultCache,
  operationCache: OperationCache
) => (response: OperationResult) => {
  const {
    operation: { key },
    data
  } = response;
  if (data === undefined) {
    return;
  }

  resultCache.set(key, response);

  collectTypesFromResponse(response.data).forEach(typeName => {
    const operations =
      operationCache[typeName] || (operationCache[typeName] = new Set());
    operations.add(key);
  });
};
